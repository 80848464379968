import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import Login from '../views/Login.vue'
import Main from '../views/Main.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Login',
    name: 'login',
    component: Login,
  },
  {
    path: '/Main',
    name: 'main',
    meta:'首页',
    // redirect:'/HomePage',
    component: Main,
  },
  {
    path: '/',
    redirect: '/main',
    meta:'',
    component: Main,
    children: [
      { path: '/', meta: '首页', redirect: '/main',  component: Main },
      { path: '/HomePage', meta: '首页_', name: "HomePage", component: () => import('../views/HomePage.vue') },
      { path: '/GoodManage', meta: '商品管理', name: "GoodManage", component: () => import('../views/GoodManage.vue') },
      { path: '/SettlementManage',name:'SettlementManage', meta: '结算管理', component: () => import('../views/SettlementManage.vue') },
      { path: '/Restocking',name:'Restocking', meta: '补货管理', component: () => import('../views/Restocking.vue') },
      { path: '/RestockingDetail/:id',props: true,name:'RestockingDetail', meta: '补货管理', component: () => import('../views/RestockingDetail.vue') },
      { path: '/RestockingEdit/:id',props: true,name:'RestockingEdit', meta: '补货管理', component: () => import('../views/RestockingEdit.vue') },
      { path: '/RestockingAdd',name:'RestockingAdd', meta: '补货管理', component: () => import('../views/RestockingAdd.vue') },
      { path: '/DoorBusinessManage',name:'DoorBusinessManage', meta: '门店商务管理', component: () => import('../views/DoorBusinessManage.vue') },
      { path: '/DoorBusinessManageDetail/:id',props:true,name:'DoorBusinessManageDetail', meta: '门店商务管理', component: () => import('../views/DoorBusinessManageDetail.vue') },
      { path: '/DoorBusinessManageAdd',name:'DoorBusinessManageAdd', meta: '门店商务管理', component: () => import('../views/DoorBusinessManageAdd.vue') },
      { path: '/DoorBusinessManageEdit/:id',props:true,name:'DoorBusinessManageEdit', meta: '门店商务管理', component: () => import('../views/DoorBusinessManageEdit.vue') },
      { path: '/DoorManage',name:'DoorManage', meta: '门店管理', component: () => import('../views/DoorManage.vue') },
      { path: '/DoorManageDetail/:id',name:'DoorManageDetail', props: true,meta: '门店管理', component: () => import('../views/DoorManageDetail.vue') },
      { path: '/DoorManageEdit/:id',props: true,name:'DoorManageEdit', meta: '门店管理', component: () => import('../views/DoorManageEdit.vue') },
      { path: '/DoorManageAdd',name:'DoorManageAdd', meta: '门店管理', component: () => import('../views/DoorManageAdd.vue') },
      { path: '/ChargeManage',name:'ChargeManage', meta: '充值管理', component: () => import('../views/ChargeManage.vue') },
      { path: '/ChargeManageAdd',name:'ChargeManageAdd', meta: '充值管理', component: () => import('../views/ChargeManageAdd.vue') },
      { path: '/ChargeManageDetail/:id',name:'ChargeManageDetail',props: true, meta: '充值管理', component: () => import('../views/ChargeManageDetail.vue') },
      { path: '/AuditManage',name:'AuditManage', meta: '审核管理', component: () => import('../views/AuditManage.vue') },
      { path: '/AuditManageDetail/:id',props: true,name:'AuditManageDetail', meta: '审核管理', component: () => import('../views/AuditManageDetail.vue') },
      { path: '/AuditManageEdit/:id',props: true,name:'AuditManageEdit', meta: '审核管理', component: () => import('../views/AuditManageEdit.vue') },
      { path: '/SystemSetting', meta: '系统设置', component: () => import('../views/SystemSetting.vue') },
      { path: '/GoodsAdd', meta: '商品管理', name: 'GoodsAdd', component: () => import('../views/GoodsAdd.vue') },
      { path: '/GoodsEdit/:id', meta: '商品管理', props: true, name: 'GoodsEdit', component: () => import('../views/GoodsEdit.vue') },
      { path: '/SystemMineSetting', meta: '我的账户', name: 'SystemMineSetting', component: () => import('../views/SystemMineSetting.vue') },
      { path: '/SystemUserSetting', meta: '账户管理', name: 'SystemUserSetting', component: () => import('../views/SystemUserSetting.vue') },
      { path: '/SystemRoleSetting', meta: '角色管理', name: 'SystemRoleSetting', component: () => import('../views/SystemRoleSetting.vue') },
      { path: '/UpdateLog', meta: '更新日志', name: 'UpdateLog', component: () => import('../views/UpdateLog.vue') },
      { path: '/ChargePlan', meta: '充值方案管理', name: 'ChargePlan', component: () => import('../views/ChargePlan.vue') },
      { path: '/ChargePlanEdit/:id', meta: '充值方案管理', props: true, name: 'ChargePlanEdit', component: () => import('../views/ChargePlanEdit.vue') },
      { path: '/ChargePlanAdd', meta: '充值方案管理', name: 'ChargePlanAdd', component: () => import('../views/ChargePlanAdd.vue') },
      { path: '/ChargePlanDetail/:id',props:true,  meta: '充值方案管理', name: 'ChargePlanDetail', component: () => import('../views/ChargePlanDetail.vue') },
      {
        path: '/gdetail/:id',
        name: 'GoodsDetail',
        component: () => import('../views/GoodDetail.vue'),
        props: true // 将路由参数作为 props 传递
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'hash', //'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem("isLogin");

  if (to.name == 'Login' || to.name == 'login') next();
  else {
    if (!isLogin) {
      next({ name: 'login' })
    } else {
      //移除webgl
      let webglElements = document.getElementsByClassName("cloud-class");
      // 使用循环遍历 HTMLCollection 中的每个元素，并移除它们
      while (webglElements.length > 0) {
        webglElements[0].remove();
      }
      //判断页面权限
      try {
        var pmsInfo = JSON.parse(localStorage.getItem("uinfo")).permissions;
        if (pmsInfo.includes(to.meta) || typeof(to.meta) == 'object'
        || to.meta=='首页'
        || to.meta == '更新日志'
        || to.meta == '我的账户'
      )
          next();
      else if(pmsInfo.includes('首页') && to.meta=='首页_' ){
        next();
      }
        else { 
          localStorage.removeItem('uinfo');
          localStorage.removeItem('isLogin');
          next({ name: 'login' })}
      } catch (error) {
        next();
      }
      //next();
    }
  }

})


export default router
