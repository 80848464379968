import http from './http';

const api = {
     baseUrl:'http://47.121.138.94:5005',
     //baseUrl:'http://www.mzswkj.cn:5005/',
    //baseUrl:'http://47.113.118.179:5005', //线上
    url_login: {
        login(data) {
            const formData = new FormData();
            formData.append('mobile', data.mobile);
            formData.append('password', data.password);
            return http.post('/login', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },
        logout() {
            return http.get('/logout');
        },
        currentUser() {
            return http.get('/current_user');
        },
        resetPwd(data){
            return http.put(`/reset_password`, data)
        }
    },
    goods_manage: {
        get(page = 1) {
            return http.get(`/commodity_admin?page=` + page)
        },
        get2(data) {
            return http.get(`/commodity_admin?${data}`)
        },
        getSearchType(data) {
            return http.get(`/commodity_admin?${data}`)
        },
        batchGoods(goods){
            return http.get(`/commodity_batch?product_numbers_list=` + goods)
        },
        getSearchQuery(data) {
            return http.get(`/commodity_admin_search?${data}`)
        },
        editState(data) {
            return http.put(`/commodity_admin_state`, data)
        },
        getItem(id) {
            return http.get(`/commodity_admin/${id}`)
        },
        delete(id) {
            return http.delete('/commodity_admin', {
                data: { id: id },
                headers: { 'Content-Type': 'application/json' }
            })
        },
        Add(data) {
            return http.post('/commodity_admin', data)
        },
        Edit(data) {
            return http.put('/commodity_admin', data)
        }
    },
    user_role: {
        getRoleSelect() {
            return http.get('/roles')
        },
        getPermissions() {
            return http.get('/permissions')
        },
        getRoles(page = 1) {
            return http.get('/role_admin?page=' + page)
        },
        editState(data) {
            return http.put(`/role_state`, data)
        },
        addRole(data) {
            return http.post('/role_admin', data)
        },
        editRole(data) {
            return http.put('/role_admin', data)
        },
        delRole(id) {
            return http.delete('/role_admin', {
                data: { id: id },
                headers: { 'Content-Type': 'application/json' }
            })
        },
        editRoleState(data) {
            return http.put('/rolse_state', data)
        }
    },
    user_admin: {
        getUsers(str_where) {
            return http.get(`/user_admin?${str_where}`)
        },
        editState(data) {
            return http.put(`/user_state`, data)
        },
        addUser(data) {
            return http.post('/user_admin', data)
        },
        editUser(data) {
            return http.put('/user_admin', data)
        },
        delUser(id) {
            return http.delete('/user_admin', {
                data: { id: id },
                headers: { 'Content-Type': 'application/json' }
            })
        },
        editUserState(data) {
            return http.put('/user_state', data)
        }
    },
    door_manager:{
        get(str_where) {
            return http.get(`/store_admin?${str_where}`)
        },
        add(data){
            return http.post('/store_admin', data)
        },
        isUnion(mobile){
            return http.get(`/find_role?mobile=${mobile}`)
        },
        getItem(no){
            return http.get(`/store_admin/${no}`)
        },
        edit(data){
            return http.put('/store_admin', data)
        },
        delete(data) {
            return http.delete('/store_admin', {
                data: { store_number: data.store_number },
                headers: { 'Content-Type': 'application/json' }
            })
        },
        
    },
    door_bussiness: {
        get(str_where) {
            return http.get(`/store_bussiness_admin?${str_where}`)
        },
        getItem(no){
            return http.get(`/store_bussiness_admin/${no}`)
        },
        batchQuery(no,mobile){
            return http.get(`/store_batch_query?store_number_list=${no}&mobile=${mobile}`)
        },
        add(data){
            return http.post('/store_bussiness_admin', data)
        },
        edit(data){
            return http.put('/store_bussiness_admin', data)
        },
        state(data){
            return http.put('/store_bussiness_state', data)
        },
        relate(data){
            return http.put('/bussiness_relation', data)
        },
        relate_remove(data){
            return http.put('/store_bussiness_move', data)
        }
    },
    charge_manage:{
        get(str_where) {
            return http.get(`/recharge_record?${str_where}`)
        },
        getItem(no) {
            return http.get(`/recharge_record/${no}`)
        },
        getDoorByMobile(mobile){
            return http.get(`/store_mobile_find?mobile=${mobile}`)
        },
        getDoorPlanByStoreNumber(sn){
            return http.get(`/store_plan_find?store_number=${sn}`)
        },
        add(data){
            return http.post('/recharge_record', data)
        },
        uploadImg(data){
            const formData = new FormData();
            // 假设data是一个对象，包含图片和其他数据
            formData.append('image', data.image);
            return http.post(`/recharge_record_upload`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
        },
        delete(data){
            return http.delete('/recharge_record', {
                data: { recharge_number: data.recharge_number },
                headers: { 'Content-Type': 'application/json' }
            })
        }
    },
    charge_plan:{
        get(str_where) {
            return http.get(`/recharge_plan_admin?${str_where}`)
        },
        getItem(no){
            return http.get(`/recharge_plan_admin/${no}`)
        },
        add(data){
            return http.post('/recharge_plan_admin', data)
        },
        edit(data){
            return http.put('/recharge_plan_admin', data)
        },
        state(data){
            return http.put('/recharge_plan_state', data)
        },
        delete(data){
            return http.delete('/recharge_plan_admin', {
                data: { plan_number: data.plan_number },
                headers: { 'Content-Type': 'application/json' }
            })
        }
    },
    settlement:{
        get(str_where) {
            return http.get(`/commision_admin?${str_where}`)
        },
        get2(str_where){
            return http.get(`/service_admin?${str_where}`)
        },
        set(data){
            return http.put('/commision_admin', data)
        },
        set2(data){
            return http.put('/service_admin', data)
        },
        get3(str_where){
           return http.get(`/area_settlement?${str_where}`)
        },
        set3(data){
            return http.put('/area_settlement', data)
        },
        get4(str_where){
            return http.get(`/city_settlement?${str_where}`)
        },
        set4(data){
            return http.put('/city_settlement', data)
        },
        get5(str_where){
            return http.get(`/province_settlement?${str_where}`)
        },
        set5(data){
            return http.put('/province_settlement', data)
        },
        get6(str_where){
            return http.get(`/company_settlement?${str_where}`)
        },
        set6(data){
            return http.put('/company_settlement', data)
        },
        uploadImg(data){
            const formData = new FormData();
            // 假设data是一个对象，包含图片和其他数据
            formData.append('image', data.image);
            formData.append('commission_number',data.commission_number);
            return http.post(`/commision_upload`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
        },
        uploadImg2(data){
            const formData = new FormData();
            // 假设data是一个对象，包含图片和其他数据
            formData.append('image', data.image);
            formData.append('service_number',data.commission_number);
            return http.post(`/service_upload`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
        },
        uploadImg3(data){
            const formData = new FormData();
            // 假设data是一个对象，包含图片和其他数据
            formData.append('image', data.image);
            formData.append('area_number',data.area_number);
            return http.post(`/area_upload`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
        },
        uploadImg4(data){
            const formData = new FormData();
            // 假设data是一个对象，包含图片和其他数据
            formData.append('image', data.image);
            formData.append('city_number',data.city_number);
            return http.post(`/city_upload`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
        },
        uploadImg5(data){
            const formData = new FormData();
            // 假设data是一个对象，包含图片和其他数据
            formData.append('image', data.image);
            formData.append('province_number',data.province_number);
            return http.post(`/province_upload`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
        },
        uploadImg6(data){
            const formData = new FormData();
            // 假设data是一个对象，包含图片和其他数据
            formData.append('image', data.image);
            formData.append('company_number',data.company_number);
            return http.post(`/company_upload`, formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }
            })
        },
    },
    audit:{
        get(str_where) {
            return http.get(`/auditing_admin?${str_where}`)
        },
        getItem(no){
            return http.get(`/auditing_admin/${no}`)
        },
        getItemRecharge(no){
            return http.get(`/recharge_record_admin/${no}`)
        },
        getItemSupply(no){
            return http.get(`/supplement_admin/${no}`)
        },
        audit(data){
            return http.put('/auditing_admin', data) 
        }
    },
    re_stoking:{
        get(str_where) {
            return http.get(`/supplement_admin?${str_where}`)
        },
        getItem(no) {
            return http.get(`/supplement_admin/${no}`)
        },
        add(data){
            return http.post('/supplement_admin', data)
        },
        editSave(data){
            return http.put('/supplement_temp ', data)
        },
        editApply(data){
            return http.put('/supplement_temp_send ', data)
        },
        get_user_store(){
            return http.get(`/store_current_user`)
        },
        get_plans(no){
            return http.get(`/store_plan_relation?store_number=${no}`)
        },
        is_center_role(){
            return http.get(`/warehouse_admin`)
        },
        get_batch_else(lst){
            return http.get(`/commodity_batch_else?product_numbers_list=${lst}`)
        },
        changeState(data){
            return http.put('/supplement_state ', data)
        },
        
    },
    Top10:{
        DoorTop10(data){
            return http.get(`/store_top${data}`)
        },
        GoodTop10(data){
            return http.get(`/commodity_top${data}`)
        },
        ProvinceTop10(data){
            return http.get(`/province_top${data}`)
        },
        ChargeTop10(data){
            return http.get(`/store_recharge_top${data}`)
        },
        SellTop10(data){
            return http.get(`/sell_top${data}`)
        }

    }


}


export default api;