<template>
    <div class="iq-sidebar sidebar-default">
      <div
        class="iq-sidebar-logo d-flex align-items-end justify-content-between"
      >
        <a href="javascript:;"   @click="clickMenu('首页')"  class="header-logo">
          <img
           src="https://static.appoint.icu/Battle/shop/static/picture/logo.jpg"
            class="img-fluid rounded-normal light-logo"
            alt="logo"
          />
          <img
            src="https://static.appoint.icu/Battle/shop/static/picture/logo.jpg"
            class="img-fluid rounded-normal d-none sidebar-light-img"
            alt="logo"
          />
          <span>苗姿商务云</span>
        </a>
        <div class="side-menu-bt-sidebar-1">
          <svg
            class="text-light wrapper-menu"
            width="30"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
      </div>
      <div class="data-scrollbar" data-scroll="1">
        <nav class="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" class="side-menu">
            <li :class="['sidebar-layout',{'active':getActiveClass=='首页'}]">
              <a href="javascript:;"  v-if="checkHasMenu('首页')" @click="clickMenu('首页')" class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                    ></path>
                  </svg>
                </i>
                <span class="ml-2">首页</span>
              </a>
            </li>
            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold"
                >商务处理</span
              >
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='商品管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('商品管理')" @click="clickMenu('商品管理')"  class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    ></path>
                  </svg>
                </i>
                <span class="ml-2">商品管理</span>
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='结算管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('结算管理')" @click="clickMenu('结算管理')" class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                    ></path>
                  </svg> </i
                ><span class="ml-2">结算管理</span>
                <!-- <p class="mb-0 w-10 badge badge-pill badge-primary">16</p> -->
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='补货管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('补货管理')" @click="clickMenu('补货管理')" class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                    ></path>
                  </svg>
                </i>
                <span class="ml-2">补货管理</span>
                <!-- <p class="mb-0 w-10 badge badge-pill badge-primary">7</p> -->
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='门店商务管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('门店商务管理')" @click="clickMenu('门店商务管理')" class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                    ></path>
                  </svg>
                </i>
                <span class="ml-2">门店赋能老师</span>
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='门店管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('门店管理')" @click="clickMenu('门店管理')"  class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                </i>
                <span class="ml-2">门店管理</span>
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='充值管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('充值管理')" @click="clickMenu('充值管理')"  class="svg-icon">
                <i class="">
                  <svg
                    class="icon line"
                    width="18"
                    id="receipt"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      style="
                        fill: none;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2;
                      "
                    ></path>
                    <line
                      x1="8"
                      y1="10"
                      x2="12"
                      y2="10"
                      style="
                        fill: none;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2;
                      "
                    ></line>
                  </svg>
                </i>
                <span class="ml-2">充值管理</span>
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='充值方案管理'}]">
								<a href="javascript:;"  v-if="checkHasMenu('充值方案管理')" @click="clickMenu('充值方案管理')" class="svg-icon">
									<i class="">
										<svg class="icon line" width="18" id="receipt" viewBox="0 0 24 24" stroke="currentColor">
											<path
												d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
												style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2"
											></path>
											<line x1="8" y1="10" x2="12" y2="10" style="fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2"></line>
										</svg>
									</i>
									<span class="ml-2">方案管理</span>
								</a>
							</li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='审核管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('审核管理')" @click="clickMenu('审核管理')"  class="svg-icon">
                <i class="">
                  <svg
                    class="icon line"
                    width="18"
                    id="receipt"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      d="M17,16V3L13,5,10,3,7,5,3,3V17.83A3.13,3.13,0,0,0,5.84,21,3,3,0,0,0,9,18V17a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1v1a3,3,0,0,1-3,3H6"
                      style="
                        fill: none;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2;
                      "
                    ></path>
                    <line
                      x1="8"
                      y1="10"
                      x2="12"
                      y2="10"
                      style="
                        fill: none;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2;
                      "
                    ></line>
                  </svg>
                </i>
                <span class="ml-2">审核管理</span>
                <!-- <p class="mb-0 w-10 badge badge-pill badge-primary">8</p> -->
              </a>
            </li>

            <li class="px-3 pt-3 pb-2">
              <span class="text-uppercase small font-weight-bold">配置</span>
            </li>
            <!-- <li :class="['sidebar-layout',{'active':getActiveClass=='结算管理'}]">
              <a
                href="#app6"
                class="collapsed svg-icon"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i>
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    ></path>
                  </svg>
                </i>
                <span class="ml-2">报错页面</span>
                <svg
                  class="svg-icon iq-arrow-right arrow-active"
                  width="15"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              </a>
              <ul
                id="app6"
                class="submenu collapse"
                data-parent="#iq-sidebar-toggle"
              >
                <li :class="['sidebar-layout',{'active':getActiveClass=='结算管理'}]">
                  <a href="pages-error.html" class="svg-icon">
                    <i class="">
                      <svg
                        width="18"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </i>
                    <span class="ml-2">404</span>
                  </a>
                </li>
                <li :class="['sidebar-layout',{'active':getActiveClass=='结算管理'}]">
                  <a href="pages-error-500.html" class="svg-icon">
                    <i class="">
                      <svg
                        width="18"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                        ></path>
                      </svg>
                    </i>
                    <span class="ml-2">500</span>
                  </a>
                </li>
              </ul>
            </li> -->
            <li :class="['sidebar-layout',{'active':getActiveClass=='我的账户'}]">
              <a href="javascript:;"  @click="clickMenu('我的账户')"  class="svg-icon">
                <i class="">
                  <svg
                    class="svg-icon"
                    id="iq-user-1-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg> </i
                ><span class="ml-2">我的账户</span>
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='账户管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('账户管理')" @click="clickMenu('账户管理')" class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                    ></path>
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    ></path>
                  </svg> </i
                ><span class="ml-2">账户管理</span>
              </a>
            </li>
            <li :class="['sidebar-layout',{'active':getActiveClass=='角色管理'}]">
              <a href="javascript:;"  v-if="checkHasMenu('角色管理')" @click="clickMenu('角色管理')" class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    ></path>
                  </svg> </i
                ><span class="ml-2">角色管理</span>
              </a>
            </li>

            <!-- <li :class="['sidebar-layout',{'active':getActiveClass=='更新日志'}]">
              <a href="javascript:;"   @click="clickMenu('更新日志')" class="svg-icon">
                <i class="">
                  <svg
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                </i>
                <span class="ml-2">更新日志</span>
                <p class="mb-0 px-2 badge badge-pill badge-success">New</p>
              </a>
            </li> -->
          </ul>
        </nav>
        <div class="pt-5 pb-5"></div>
      </div>
    </div>
</template>

<script>
import { TorusGeometry } from 'three';

export default {
  data() {
    return {
      darkMode:false,
      uPermission: [],
      realMenu: [],
      currentPath: "",
      menuData: [
        {
          path: "/Main",
          name: "home",
          label: "首页",
          icon: "icon-home",
          url: "/Main",
        },
        {
          path: "/GoodManage",
          name: "good",
          label: "商品管理",
          icon: "icon-tree",
          url: "/GoodManage",
        },
        {
          path: "/SettlementManage",
          name: "settlement",
          label: "结算管理",
          icon: "icon-mail",
          url: "/SettlementManage",
        },
        {
          path: "/Restocking",
          name: "restocking",
          label: "补货管理",
          icon: "icon-noticefill",
          url: "/Restocking",
        },
        {
          path: "/DoorBusinessManage",
          name: "doorBusiness",
          label: "门店商务管理",
          icon: "icon-book",
          url: "/DoorBusinessManage",
        },
        {
          path: "/DoorManage",
          name: "door",
          label: "门店管理",
          icon: "icon-person",
          url: "/DoorManage",
        },
        {
          path: "/ChargeManage",
          name: "charge",
          label: "充值管理",
          icon: "icon-person",
          url: "/ChargeManage",
        },
        {
          path: "/AuditManage",
          name: "audit",
          label: "审核管理",
          icon: "icon-person",
          url: "/AuditManage",
        },
        {
          path: "/SystemMineSetting",
          name: "SystemMineSetting",
          label: "我的账户",
          icon: "icon-person",
          url: "/SystemMineSetting",
        },
        {
          path: "/SystemUserSetting",
          name: "SystemUserSetting",
          label: "账户管理",
          icon: "icon-person",
          url: "/SystemUserSetting",
        },
        {
          path: "/SystemRoleSetting",
          name: "SystemRoleSetting",
          label: "角色管理",
          icon: "icon-person",
          url: "/SystemRoleSetting",
        },
        {
          path: "/UpdateLog",
          name: "UpdateLog",
          label: "更新日志",
          icon: "icon-person",
          url: "/UpdateLog",
        },
        {
          path: "/ChargePlan",
          name: "ChargePlan",
          label: "充值方案管理",
          icon: "icon-person",
          url: "/ChargePlan",
        },
      ],
    };
  },
  mounted() {
    this.darkMode = this.$store.state.darkMode;
    this.uPermission = this.$store.state.udata.permissions;
    this.currentPath = this.$route.path;
    console.log(1)
  },
  computed: {
    getActiveClass(){
      console.log(this.$route)
      return this.$route.meta;
    },
    getPermissionMenu() {
      var _that = this;
      this.menuData.forEach((s) => {
        if (_that.uPermission.includes(s.label)) {
          _that.realMenu.push(s);
        }
      });
      return _that.realMenu;
    },
  },
  methods: {
    checkHasMenu(name){
      return this.uPermission.includes(name);
    },
    getPermissionMenuItem(name){
      return this.menuData.filter(s=>s.label==name);
    },
    clickMenu(name) {
      console.log(name,this.menuData);
      var item = this.getPermissionMenuItem(name)[0];
      if (
        this.$route.path != item.path &&
        !(this.$route.path === "/home" && item.path === "/")
      ) {
        this.$router.push(item.path);
      }
    },
  },
};
</script>

<style>

</style>