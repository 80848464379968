<!--门店管理-->
<template>
  <div class="content-page">
    <div class="r-header">
      <div>
        <el-select v-model="tabSelect" placeholder="请选择">
          <el-option
            v-for="item in tabSelectList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <el-button type="primary" @click="exportTables">导出</el-button>
      </div>
    </div>

    <Top10DoorRank
      ref="rankDoor"
      v-if="tabSelect == '店铺榜前十'"
    ></Top10DoorRank>
    <Top10ChargeRank
      ref="rankCharge"
      v-if="tabSelect == '充值榜前十'"
    ></Top10ChargeRank>
    <Top10ProvinceRank
      ref="rankProvince"
      v-if="tabSelect == '全国省份前十'"
    ></Top10ProvinceRank>
    <Top10SaleRank
      ref="rankSale"
      v-if="tabSelect == '销售榜前十'"
    ></Top10SaleRank>
    <Top10GoodsRank
      ref="rankGoods"
      v-if="tabSelect == '商品榜前十'"
    ></Top10GoodsRank>
  </div>
</template>

<script>
import Top10ChargeRank from "@/components/Tables/Top10_ChargeRank.vue";
import Top10DoorRank from "@/components/Tables/Top10_DoorRank.vue";
import Top10GoodsRank from "@/components/Tables/Top10_GoodsRank.vue";
import Top10ProvinceRank from "@/components/Tables/Top10_ProvinceRank.vue";
import Top10SaleRank from "@/components/Tables/Top10_SaleRank.vue";
export default {
  components: {
    Top10ChargeRank,
    Top10DoorRank,
    Top10GoodsRank,
    Top10ProvinceRank,
    Top10SaleRank,
  },
  data() {
    return {
      tabSelect: "店铺榜前十",
      tabSelectList: [
        {
          value: "店铺榜前十",
          label: "店铺榜前十",
        },
        {
          value: "销售榜前十",
          label: "销售榜前十",
        },
        {
          value: "全国省份前十",
          label: "全国省份前十",
        },
        {
          value: "充值榜前十",
          label: "充值榜前十",
        },
        {
          value: "商品榜前十",
          label: "商品榜前十",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    exportTables() {
      if ((this.tabSelect == "店铺榜前十")) {
        this.$refs.rankDoor.exportTable();
      } else if ((this.tabSelect == "充值榜前十")) {
        this.$refs.rankCharge.exportTable();
      } else if ((this.tabSelect == "全国省份前十")) {
        this.$refs.rankProvince.exportTable();
      } else if ((this.tabSelect == "销售榜前十")) {
        this.$refs.rankSale.exportTable();
      } else if ((this.tabSelect == "商品榜前十")) {
        this.$refs.rankGoods.exportTable();
      }
    },
  },
};
</script>

<style>
.r-perm {
  width: 66px;
}
.item-permission {
  margin-right: 10px;
}

.r-body .card {
  min-height: 772px !important;
  overflow: auto;
}
.rwidth-160 {
  max-width: 160px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.r-head {
  display: inline-flex;
  flex-direction: column;
  transform: translate(2px, -9px);
}
.r-head i {
  cursor: pointer;
}
.blue {
  color: #3378ff;
}
.fullscreen {
  z-index: -1;
}
.r-header {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  padding-bottom: 20px;
}
.header-title {
  display: flex;
}
.header-title i {
  transform: rotate(132deg);
  font-size: 34px;
  margin-right: 6px;
  cursor: pointer;
}
</style>
