import axios from 'axios';
axios.defaults.withCredentials=true;
const http = axios.create({
  baseURL:'http://47.121.138.94:5005', //'/api',// '/',//'/api', //process.env.VUE_APP_API_BASE_URL || '/api', //'http://47.121.138.94:5005', //
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true // 允许发送跨域 Cookie
});

// 请求拦截器
http.interceptors.request.use(
  config => {
    // 在请求发送之前做些什么，比如添加 token
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
http.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    if(response.data.message=='访问被拒绝'){
      localStorage.clear();
      window.location.reload();
    }
    return response;
  },
  error => {
    console.log('error',error)
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default http;
