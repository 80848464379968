import { render, staticRenderFns } from "./Top10_ProvinceRank.vue?vue&type=template&id=5e7ebf28&scoped=true"
import script from "./Top10_ProvinceRank.vue?vue&type=script&lang=js"
export * from "./Top10_ProvinceRank.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e7ebf28",
  null
  
)

export default component.exports