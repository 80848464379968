<template>
  <div class="col-lg-12 col-md-12 r-body">
    <div class="card" ref="fullTab">
      <div class="card-header d-flex justify-content-between">
        <div class="header-title">
          <i class="el-icon-rank" @click="onFullScreen"></i>
          <h4 class="card-title">充值榜前十</h4>
        </div>
        <div class="card-header-toolbar d-flex align-items-center">
          <el-date-picker
            v-model="sc_charge_time"
            type="date"
            align="right"
            :append-to-body="false"
            placeholder="选择发起日期"
            value-format="timestamp"
            @change="onGetChargeTop()"
          ></el-date-picker>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive">
          <table class="table mb-0">
            <thead class="table-color-heading">
              <tr class="text-secondary">
                <th scope="col">排名</th>
                <th scope="col">店铺名称</th>
                <th scope="col">服务人员</th>
                <th scope="col" class="text-right">金额</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="white-space-no-wrap"
                v-for="(item, i) in chargeArr.slice(0, 10)"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>{{ item.store_name }}</td>
                <td>{{ item.service_name }}</td>
                <td class="text-right">￥{{ item.recharge_amount }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="chargeArr.length > 10"
            class="d-flex justify-content-end align-items-center border-top-table p-3"
          >
            <button
              class="btn btn-secondary btn-sm"
              @click="onShowTop10('充值榜')"
            >
              查看所有排名
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initTable } from "@/services/utils";
import * as XLSX from "xlsx";
import api from "@/services/api";
export default {
  name: "Top10ChargeRank",
  data() {
    return {
      uInfo: this.$store.state.udata,
      is_admin: false,

      sc_door_time: "",
      doorArr: [],

      sc_good_time: "",
      sc_good_province: "",
      sc_good_sales: "",
      sc_good_name: "",
      sc_good_store: "",
      goodArr: [],
      oldGoodArr: [],
      sc_province: "",
      sc_province_time: "",
      provinceArr: [],

      sc_charge_time: "",
      chargeArr: [],

      sc_sell_time: "",
      sellArr: [],

      dialogTableVisible: false,
      dialogType: 1,
      dialogTitle: "",

      excelData: [],

    };
  },
  mounted() {
    this.onGetChargeTop();
  },
  methods: {
    async exportTable(){
      if (!this.excelData || this.excelData.length <= 0) {
        this.$notify.error({
          title: "失败",
          message: "暂时没有需要导出的数据",
        });
        return;
      }
      const header = [
        "排名",
        "店铺名称",
        "服务人员",
        "金额",
      ];
      // 将表头和数据组合在一起
      const worksheetData = [
        header,
        ...this.excelData.map((item,i) => [
          i+1,
          item.store_name,
          item.service_name,
          item.recharge_amount,]),
      ];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "充值榜.xlsx");
    },
    onFullScreen(event){
      const element = this.$refs.fullTab;
      initTable(element);
    },
    async onGetChargeTop() {
      try {
        var _that = this;
        var str_query = "";
        if (this.sc_charge_time) {
          str_query = `?create_time=${this.sc_charge_time / 1000}`;
        }
        str_query = str_query == "" ? "" : str_query;
        const response = await api.Top10.ChargeTop10(str_query);
        if (response.status) {
          _that.chargeArr = response.data.result;
          _that.excelData = response.data.result;
        }
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "失败",
          message: "接口调用失败",
        });
      }
    },
  },
};
</script>

<style scoped></style>
