import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    darkMode:localStorage.getItem("darkMode")=='true' || false,
    isLogin: localStorage.getItem("isLogin") || false,
    udata: JSON.parse(localStorage.getItem("uinfo")) || null,
  },
  getters: {
  },
  mutations: {
    setDarkMode(state, flag) {
      state.darkMode = flag;
      localStorage.setItem("darkMode", flag);
    },
    segLogin(state) {
      state.isLogin = true;
      localStorage.setItem("isLogin", true);
    },
    setLogOut(state) {
      state.isLogin = false;
      localStorage.removeItem('uinfo');
      localStorage.removeItem('isLogin');
    },
    setUinfo(state, p) {
      state.udata = p;
      localStorage.setItem("uinfo", JSON.stringify(p));
    },
  },
  actions: {
    login({ commit }) {
      commit('segLogin');
    },
    logout({ commit }) {
      commit('logout');
    }
  },
  modules: {
  }
})
