<template>
  <div class="wrapper">
    <CommonAside></CommonAside>
    <CommonHeaderVue></CommonHeaderVue>
    <router-view ></router-view>
  </div>
 
</template>


<script>
import CommonAside from "@/components/CommonAside.vue";
import CommonHeaderVue from "../components/CommonHeader.vue";
export default {
  components: {
    CommonAside,
    CommonHeaderVue,
  },
  data(){
    return{
      uData: this.$store.state.udata,
      has_home:false,
    }
  },
  mounted(){
    this.checkPermissions();
  },
  methods: {
    checkPermissions() {
      this.has_home = this.uData.permissions.includes('首页');
      if (this.has_home) {
        if(this.$route.meta == '首页'){
            this.$router.push({ name: "HomePage" });
        }
      }
    }
  },
  watch:{
    '$route'() {
      this.checkPermissions();
    }
  }
};

</script>

<style>

/* .r-el-aside {
  padding: 0;
  margin: 0;
  overflow: hidden;
  margin-top: 20px;
  width: 200px !important;
  background: black;
}
.main-coontainer{
    height:99vh;
}
.r-el-container{
    height: 100%;
} */
</style>