export function initTable (element) {
    if (!document.fullscreenElement) {
      enterFullScreen(element)
    } else {
      exitFullScreen()
    }
  }
  export function enterFullScreen (element) {
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) { /* Firefox */
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) { /* IE/Edge */
      element.msRequestFullscreen()
    }
  }
  
  export function exitFullScreen () {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen()
    }
  }

  export function toChinese(n){
    if (n === 0) return "零";
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) return "";
    var unit = "仟佰拾亿仟佰拾万仟佰拾元角分",
      str = "";
    n += "00";
    var p = n.indexOf(".");
    if (p >= 0) n = n.substring(0, p) + n.substr(p + 1, 2);
    unit = unit.substr(unit.length - n.length);
    for (var i = 0; i < n.length; i++)
      str += "零壹贰叁肆伍陆柒捌玖".charAt(n.charAt(i)) + unit.charAt(i);
    return str
      .replace(/零(仟|佰|拾|角)/g, "零")
      .replace(/(零)+/g, "零")
      .replace(/零(万|亿|元)/g, "$1")
      .replace(/(亿)万/g, "$1$2")
      .replace(/^元零?|零分/g, "")
      .replace(/元$/g, "元整");
  }
  
  export function formatTimestamp(timestamp){
    const date = new Date(timestamp * 1000);
    const yyyy = date.getFullYear();
    const MM = ("0" + (date.getMonth() + 1)).slice(-2);
    const dd = ("0" + date.getDate()).slice(-2);
    const HH = ("0" + date.getHours()).slice(-2);
    const mm = ("0" + date.getMinutes()).slice(-2);
    const ss = ("0" + date.getSeconds()).slice(-2);
    return `${yyyy}-${MM}-${dd} ${HH}:${mm}:${ss}`;
  }
  
  export function roundTo(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  }