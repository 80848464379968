<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="side-menu-bt-sidebar">
          <svg
            class="text-secondary wrapper-menu"
            width="30"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </div>
        <div class="d-flex align-items-center">
          <div class="change-mode">
            <div
              class="custom-control custom-switch custom-switch-icon custom-control-inline"
            >
              <div class="custom-switch-inner">
                <p class="mb-0"></p>
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="dark-mode"
                  data-active="true"
                />
                <label
                  class="custom-control-label"
                  for="dark-mode"
                  data-mode="toggle"
                >
                  <span class="switch-icon-right">
                    <svg
                      id="h-moon"
                      height="20"
                      width="20"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                      ></path>
                    </svg>
                  </span>
                  <span class="switch-icon-left">
                    <svg
                      id="h-sun"
                      height="20"
                      width="20"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                      ></path>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-label="Toggle navigation"
          >
            <svg
              class="text-secondary"
              width="30"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto navbar-list align-items-center">
              <li class="nav-item nav-icon dropdown">
                <!-- <a
                  href="#"
                  class="search-toggle dropdown-toggle"
                  id="notification-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    width="25"
                    height="25"
                    class="h-6 w-6 text-secondary"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    ></path>
                  </svg>
                  <span class="bg-primary"></span>
                </a> -->
                <!-- <div
                  class="iq-sub-dropdown dropdown-menu"
                  aria-labelledby="notification-dropdown"
                >
                  <div class="card shadow-none m-0 border-0">
                    <div class="p-3 card-header-border">
                      <h6 class="text-center">通知</h6>
                    </div>
                    <div
                      class="card-body overflow-auto card-header-border p-0 card-body-list"
                      style="max-height: 500px"
                    >
                      <ul
                        class="dropdown-menu-1 overflow-auto list-style-1 mb-0"
                      >
                        <li class="dropdown-item-1 float-none p-3">
                          <div
                            class="list-item d-flex justify-content-start align-items-start"
                          >
                            <div class="avatar">
                              <div class="avatar-img avatar-danger avatar-20">
                                <span
                                  ><img
                                    class="avatar is-squared rounded-circle"
                                    src="static/picture/2.png"
                                    alt="2.jpg"
                                /></span>
                              </div>
                            </div>
                            <div class="list-style-detail ml-2 mr-2">
                              <h6 class="font-weight-bold">新增门店</h6>
                              <p class="m-0">
                                <small
                                  class="text-secondary notice-text-margintop"
                                  >晋宁联营店 新增了 龙湖门店</small
                                >
                              </p>
                              <p class="m-0">
                                <small class="text-secondary text-flex-center">
                                  <svg
                                    class="text-secondary mr-1"
                                    width="15"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                    ></path>
                                  </svg>
                                  3 小时前
                                </small>
                              </p>
                            </div>
                          </div>
                        </li>
                        <li class="dropdown-item-1 float-none p-3">
                          <div
                            class="list-item d-flex justify-content-start align-items-start"
                          >
                            <div class="avatar">
                              <div class="avatar-img avatar-success avatar-20">
                                <span
                                  ><img
                                    class="avatar is-squared rounded-circle"
                                    src="static/picture/1.png"
                                    alt="2.jpg"
                                /></span>
                              </div>
                            </div>
                            <div class="list-style-detail ml-2 mr-2">
                              <h6 class="font-weight-bold">新增门店</h6>
                              <p class="m-0">
                                <small
                                  class="text-secondary notice-text-margintop"
                                  >晋宁联营店 新增了 沈阳昌隆门店</small
                                >
                              </p>
                              <p class="m-0">
                                <small class="text-secondary text-flex-center">
                                  <svg
                                    class="text-secondary mr-1"
                                    width="15"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                    ></path>
                                  </svg>
                                  7月22日 13:44
                                </small>
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer text-muted p-3">
                      <p class="mb-0 text-primary text-center font-weight-bold">
                        展示所有通知
                      </p>
                    </div>
                  </div>
                </div> -->
              </li>
              <li class="nav-item nav-icon search-content">
                <a
                  href="#"
                  class="search-toggle rounded"
                  id="dropdownSearch"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <svg
                    class="svg-icon text-secondary"
                    id="h-suns"
                    height="25"
                    width="25"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </a>
                <div
                  class="iq-search-bar iq-sub-dropdown dropdown-menu"
                  aria-labelledby="dropdownSearch"
                >
                  <form action="#" class="searchbox p-2">
                    <div class="form-group mb-0 position-relative">
                      <input
                        type="text"
                        class="text search-input font-size-12"
                        placeholder="type here to search..."
                      />
                      <a href="#" class="search-link">
                        <svg
                          class=""
                          width="20"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </form>
                </div>
              </li>
              <li class="nav-item nav-icon dropdown">
                <a
                  href="#"
                  class="nav-item nav-icon dropdown-toggle pr-0 search-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="https://static.appoint.icu/Battle/shop/static/picture/logo.jpg"
                    class="img-fluid avatar-rounded"
                    alt="user"
                  />
                  <span class="mb-0 ml-2 user-name">{{ uname }}</span>
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li class="dropdown-item d-flex svg-icon"
                  @click="goPage('SystemMineSetting')">
                    <svg
                      class="svg-icon mr-0 text-secondary"
                      id="h-01-p"
                      width="20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <a href="javascript:;"> 我的账户</a>
                  </li>
                  <li class="dropdown-item d-flex svg-icon"  v-if="checkHasPermission('账户管理')"
                  @click="goPage('SystemUserSetting')">
                    <svg
                      class="svg-icon mr-0 text-secondary"
                      id="h-02-p"
                      width="20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      ></path>
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      ></path>
                    </svg>
                    <a href="javascript:;"> 账户管理</a>
                   
                  </li>
                  <li class="dropdown-item d-flex svg-icon"
                  v-if="checkHasPermission('角色管理')"
                  @click="goPage('SystemRoleSetting')"
                  >
                    <svg
                      class="svg-icon mr-0 text-secondary"
                      id="h-04-p"
                      width="20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                      ></path>
                    </svg>
                    <a href="javascript:;"> 角色管理</a>
                  </li>

                  <li class="dropdown-item d-flex svg-icon border-top" @click="onLogout">
                    <svg
                      class="svg-icon mr-0 text-secondary"
                      id="h-05-p"
                      width="20"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      ></path>
                    </svg>
                    <a href="javascript:;" >登出</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
export default {
  data() {
    return {
      uicon: "",
      uname: "当前用户",
      uPermission:[],
    };
  },
  mounted() {
    var _that = this;
    this.uname = this.$store.state.udata.username;
    this.uPermission = this.$store.state.udata.permissions;
    //处理customizer.js
    (function (jQuery) {
      "use strict";
      const storageDark = _that.$store.state.darkMode; //localStorage.getItem('darkMode');
      if ($('body').hasClass('dark')) {
        changeMode('true');
      } else {
        changeMode('false');
      }
      if (storageDark !== 'null') {
        changeMode(storageDark);
      }
      jQuery(document).on("change", '.change-mode input[type="checkbox"]', function (e) {
        const dark = $(this).attr('data-active');
        if (dark === 'true') {
          $(this).attr('data-active', 'false');
        } else {
          $(this).attr('data-active', 'true');
        }
        changeMode(dark);
      });

      function changeMode(dark) {
        const body = jQuery('body');
        if (dark === 'true') {
          $('#dark-mode').prop('checked', true).attr('data-active', 'false');
          $('.darkmode-logo').removeClass('d-none');
          $('.light-logo').addClass('d-none');
          body.addClass('dark');
          dark = true;
        } else {
          $('#dark-mode').prop('checked', false).attr('data-active', 'true');
          $('.light-logo').removeClass('d-none');
          $('.darkmode-logo').addClass('d-none');
          body.removeClass('dark');
          dark = false;
        }
        updateLocalStorage(dark);
        const event = new CustomEvent("ChangeColorMode", { detail: { dark: dark } });
        document.dispatchEvent(event);
      }

      function updateLocalStorage(dark) {
        _that.$store.commit('setDarkMode',dark);
      }
    })($);
  },
  methods: {
    checkHasPermission(name){
      return this.uPermission.includes(name);
    },
    goPage(v){
      if(this.$route.name !== v){
        this.$router.push({name:v}).catch(error=>{
          console.log('goPage-error',error)
        })
      }
    },
    async onLogout() {
      try {
        var _that = this;
        const response = await api.url_login.logout();
        if (response.status) {
          if (response.data.status == "success") {
            _that.$store.commit("setLogOut");
            _that.$router.push({ name: "login" });
          } else {
            this.$notify.error({
              title: "失败",
              message: response.data.message,
            });
          }
        }
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "失败",
          message: "登出失败，接口错误",
        });
      }
    },
  },
};
</script>

<style >
</style>
