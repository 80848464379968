const cityArea= [
    {
      "value": "624372",
      "label": "云南省",
      "children": [
        {
          "value": "626181",
          "label": "曲靖市",
          "children": [
            { "value": "626182", "label": "市辖区" },
            { "value": "626343", "label": "沾益区" },
            { "value": "626852", "label": "罗平县" },
            { "value": "626183", "label": "麒麟区" },
            { "value": "626485", "label": "马龙县" },
            { "value": "626731", "label": "师宗县" },
            { "value": "627020", "label": "富源县" },
            { "value": "627596", "label": "宣威市" },
            { "value": "626569", "label": "陆良县" },
            { "value": "627194", "label": "会泽县" }
          ]
        },
        {
          "value": "624373",
          "label": "昆明市",
          "children": [
            { "value": "625585", "label": "嵩明县" },
            { "value": "625111", "label": "晋宁县" },
            { "value": "625486", "label": "石林彝族自治县" },
            { "value": "625669", "label": "禄劝彝族苗族自治县" },
            { "value": "624730", "label": "西山区" },
            { "value": "624374", "label": "市辖区" },
            { "value": "625883", "label": "寻甸回族彝族自治县" },
            { "value": "625034", "label": "呈贡区" },
            { "value": "624486", "label": "盘龙区" },
            { "value": "625255", "label": "富民县" },
            { "value": "625337", "label": "宜良县" },
            { "value": "624375", "label": "五华区" },
            { "value": "624860", "label": "东川区" },
            { "value": "624599", "label": "官渡区" },
            { "value": "626074", "label": "安宁市" }
          ]
        },
        {
          "value": "639368",
          "label": "德宏傣族景颇族自治州",
          "children": [
            { "value": "639719", "label": "陇川县" },
            { "value": "639600", "label": "盈江县" },
            { "value": "639524", "label": "梁河县" },
            { "value": "639416", "label": "芒市" },
            { "value": "639369", "label": "瑞丽市" }
          ]
        },
        {
          "value": "638092",
          "label": "大理白族自治州",
          "children": [
            { "value": "638249", "label": "漾濞彝族自治县" },
            { "value": "638674", "label": "南涧彝族自治县" },
            { "value": "638858", "label": "永平县" },
            { "value": "639241", "label": "鹤庆县" },
            { "value": "638475", "label": "宾川县" },
            { "value": "638941", "label": "云龙县" },
            { "value": "638764", "label": "巍山彝族回族自治县" },
            { "value": "639039", "label": "洱源县" },
            { "value": "638325", "label": "祥云县" },
            { "value": "638093", "label": "大理市" },
            { "value": "639139", "label": "剑川县" },
            { "value": "638576", "label": "弥渡县" }
          ]
        },
        {
          "value": "634006",
          "label": "楚雄彝族自治州",
          "children": [
            { "value": "634592", "label": "大姚县" },
            { "value": "634269", "label": "牟定县" },
            { "value": "634894", "label": "武定县" },
            { "value": "634176", "label": "双柏县" },
            { "value": "635039", "label": "禄丰县" },
            { "value": "634505", "label": "姚安县" },
            { "value": "634805", "label": "元谋县" },
            { "value": "634007", "label": "楚雄市" },
            { "value": "634366", "label": "南华县" },
            { "value": "634734", "label": "永仁县" }
          ]
        },
        {
          "value": "632971",
          "label": "临沧市",
          "children": [
            { "value": "633898", "label": "沧源佤族自治县" },
            { "value": "632972", "label": "市辖区" },
            { "value": "633498", "label": "永德县" },
            { "value": "633287", "label": "云县" },
            { "value": "633793", "label": "耿马傣族佤族自治县" },
            { "value": "633629", "label": "镇康县" },
            { "value": "633711", "label": "双江拉祜族佤族布朗族傣族自治县" },
            { "value": "632973", "label": "临翔区" },
            { "value": "633086", "label": "凤庆县" }
          ]
        },
        {
          "value": "639802",
          "label": "怒江傈僳族自治州",
          "children": [
            { "value": "639956", "label": "贡山独龙族怒族自治县" },
            { "value": "639889", "label": "福贡县" },
            { "value": "639990", "label": "兰坪白族普米族自治县" },
            { "value": "639803", "label": "泸水市" }
          ]
        },
        {
          "value": "640107",
          "label": "迪庆藏族自治州",
          "children": [
            { "value": "640108", "label": "香格里拉市" },
            { "value": "640239", "label": "维西傈僳族自治县" },
            { "value": "640184", "label": "德钦县" }
          ]
        },
        {
          "value": "635219",
          "label": "红河哈尼族彝族自治州",
          "children": [
            { "value": "635347", "label": "开远市" },
            { "value": "635220", "label": "个旧市" },
            { "value": "635548", "label": "弥勒市" },
            { "value": "636329", "label": "红河县" },
            { "value": "636176", "label": "元阳县" },
            { "value": "636434", "label": "金平苗族瑶族傣族自治县" },
            { "value": "636546", "label": "绿春县" },
            { "value": "635698", "label": "屏边苗族自治县" },
            { "value": "636080", "label": "泸西县" },
            { "value": "635786", "label": "建水县" },
            { "value": "636647", "label": "河口瑶族自治县" },
            { "value": "635435", "label": "蒙自市" },
            { "value": "635955", "label": "石屏县" }
          ]
        },
        {
          "value": "631276",
          "label": "丽江市",
          "children": [
            { "value": "631278", "label": "古城区" },
            { "value": "631634", "label": "华坪县" },
            { "value": "631349", "label": "玉龙纳西族自治县" },
            { "value": "631469", "label": "永胜县" },
            { "value": "631704", "label": "宁蒗彝族自治县" },
            { "value": "631277", "label": "市辖区" }
          ]
        },
        {
          "value": "627981",
          "label": "玉溪市",
          "children": [
            { "value": "628099", "label": "江川区" },
            { "value": "628181", "label": "澄江县" },
            { "value": "628397", "label": "易门县" },
            { "value": "628463", "label": "峨山彝族自治县" },
            { "value": "627982", "label": "市辖区" },
            { "value": "628228", "label": "通海县" },
            { "value": "628684", "label": "元江哈尼族彝族傣族自治县" },
            { "value": "628548", "label": "新平彝族傣族自治县" },
            { "value": "628314", "label": "华宁县" },
            { "value": "627983", "label": "红塔区" }
          ]
        },
        {
          "value": "636688",
          "label": "文山壮族苗族自治州",
          "children": [
            { "value": "637426", "label": "广南县" },
            { "value": "637166", "label": "马关县" },
            { "value": "636689", "label": "文山市" },
            { "value": "637052", "label": "麻栗坡县" },
            { "value": "636970", "label": "西畴县" },
            { "value": "637619", "label": "富宁县" },
            { "value": "636850", "label": "砚山县" },
            { "value": "637312", "label": "丘北县" }
          ]
        },
        {
          "value": "637778",
          "label": "西双版纳傣族自治州",
          "children": [
            { "value": "638015", "label": "勐腊县" },
            { "value": "637910", "label": "勐海县" },
            { "value": "637779", "label": "景洪市" }
          ]
        },
        {
          "value": "631811",
          "label": "普洱市",
          "children": [
            { "value": "632742", "label": "澜沧拉祜族自治县" },
            { "value": "631993", "label": "墨江哈尼族自治县" },
            { "value": "632361", "label": "景谷傣族彝族自治县" },
            { "value": "632177", "label": "景东彝族自治县" },
            { "value": "631812", "label": "市辖区" },
            { "value": "632513", "label": "镇沅彝族哈尼族拉祜族自治县" },
            { "value": "632634", "label": "江城哈尼族彝族自治县" },
            { "value": "632693", "label": "孟连傣族拉祜族佤族自治县" },
            { "value": "631813", "label": "思茅区" },
            { "value": "632924", "label": "西盟佤族自治县" },
            { "value": "631894", "label": "宁洱哈尼族彝族自治县" }
          ]
        },
        {
          "value": "628776",
          "label": "保山市",
          "children": [
            { "value": "629403", "label": "昌宁县" },
            { "value": "629541", "label": "腾冲市" },
            { "value": "628777", "label": "市辖区" },
            { "value": "629271", "label": "龙陵县" },
            { "value": "628778", "label": "隆阳区" },
            { "value": "629117", "label": "施甸县" }
          ]
        },
        {
          "value": "629781",
          "label": "昭通市",
          "children": [
            { "value": "630498", "label": "永善县" },
            { "value": "631242", "label": "水富县" },
            { "value": "630656", "label": "绥江县" },
            { "value": "630704", "label": "镇雄县" },
            { "value": "630404", "label": "大关县" },
            { "value": "630989", "label": "彝良县" },
            { "value": "629783", "label": "昭阳区" },
            { "value": "630098", "label": "巧家县" },
            { "value": "629782", "label": "市辖区" },
            { "value": "630299", "label": "盐津县" },
            { "value": "629988", "label": "鲁甸县" },
            { "value": "631144", "label": "威信县" }
          ]
        }
      ]
    },
    {
      "value": "191019",
      "label": "浙江省",
      "children": [
        {
          "value": "205101",
          "label": "湖州市",
          "children": [
            { "value": "205103", "label": "吴兴区" },
            { "value": "205717", "label": "德清县" },
            { "value": "205102", "label": "市辖区" },
            { "value": "206213", "label": "安吉县" },
            { "value": "205910", "label": "长兴县" },
            { "value": "205449", "label": "南浔区" }
          ]
        },
        {
          "value": "203852",
          "label": "嘉兴市",
          "children": [
            { "value": "203853", "label": "市辖区" },
            { "value": "204177", "label": "嘉善县" },
            { "value": "204486", "label": "海宁市" },
            { "value": "204877", "label": "桐乡市" },
            { "value": "204723", "label": "平湖市" },
            { "value": "203854", "label": "南湖区" },
            { "value": "204341", "label": "海盐县" },
            { "value": "204008", "label": "秀洲区" }
          ]
        },
        {
          "value": "206438",
          "label": "绍兴市",
          "children": [
            { "value": "207247", "label": "上虞区" },
            { "value": "208733", "label": "嵊州市" },
            { "value": "208171", "label": "诸暨市" },
            { "value": "206875", "label": "柯桥区" },
            { "value": "207715", "label": "新昌县" },
            { "value": "206439", "label": "市辖区" },
            { "value": "206440", "label": "越城区" }
          ]
        },
        {
          "value": "214260",
          "label": "衢州市",
          "children": [
            { "value": "215624", "label": "江山市" },
            { "value": "214262", "label": "柯城区" },
            { "value": "215051", "label": "开化县" },
            { "value": "215332", "label": "龙游县" },
            { "value": "214536", "label": "衢江区" },
            { "value": "214843", "label": "常山县" },
            { "value": "214261", "label": "市辖区" }
          ]
        },
        {
          "value": "194395",
          "label": "宁波市",
          "children": [
            { "value": "194397", "label": "海曙区" },
            { "value": "194484", "label": "江东区" },
            { "value": "194747", "label": "北仑区" },
            { "value": "195703", "label": "象山县" },
            { "value": "197031", "label": "慈溪市" },
            { "value": "194396", "label": "市辖区" },
            { "value": "195023", "label": "镇海区" },
            { "value": "196257", "label": "宁海县" },
            { "value": "195132", "label": "鄞州区" },
            { "value": "197440", "label": "奉化市" },
            { "value": "196686", "label": "余姚市" },
            { "value": "194568", "label": "江北区" }
          ]
        },
        {
          "value": "209250",
          "label": "金华市",
          "children": [
            { "value": "209251", "label": "市辖区" },
            { "value": "210474", "label": "武义县" },
            { "value": "211492", "label": "磐安县" },
            { "value": "211883", "label": "兰溪市" },
            { "value": "213485", "label": "永康市" },
            { "value": "211047", "label": "浦江县" },
            { "value": "212256", "label": "义乌市" },
            { "value": "209252", "label": "婺城区" },
            { "value": "209949", "label": "金东区" },
            { "value": "213076", "label": "东阳市" }
          ]
        },
        {
          "value": "215955",
          "label": "舟山市",
          "children": [
            { "value": "216394", "label": "嵊泗县" },
            { "value": "215957", "label": "定海区" },
            { "value": "216139", "label": "普陀区" },
            { "value": "215956", "label": "市辖区" },
            { "value": "216290", "label": "岱山县" }
          ]
        },
        {
          "value": "221610",
          "label": "丽水市",
          "children": [
            { "value": "223324", "label": "云和县" },
            { "value": "221903", "label": "青田县" },
            { "value": "222658", "label": "遂昌县" },
            { "value": "223885", "label": "景宁畲族自治县" },
            { "value": "221611", "label": "市辖区" },
            { "value": "223512", "label": "庆元县" },
            { "value": "221612", "label": "莲都区" },
            { "value": "224172", "label": "龙泉市" },
            { "value": "222380", "label": "缙云县" },
            { "value": "222890", "label": "松阳县" }
          ]
        },
        {
          "value": "197850",
          "label": "温州市",
          "children": [
            { "value": "197851", "label": "市辖区" },
            { "value": "198508", "label": "洞头区" },
            { "value": "202864", "label": "乐清市" },
            { "value": "201882", "label": "瑞安市" },
            { "value": "198619", "label": "永嘉县" },
            { "value": "201556", "label": "泰顺县" },
            { "value": "197852", "label": "鹿城区" },
            { "value": "198091", "label": "龙湾区" },
            { "value": "199581", "label": "平阳县" },
            { "value": "198210", "label": "瓯海区" },
            { "value": "200239", "label": "苍南县" },
            { "value": "201140", "label": "文成县" }
          ]
        },
        {
          "value": "191020",
          "label": "杭州市",
          "children": [
            { "value": "193085", "label": "桐庐县" },
            { "value": "193304", "label": "淳安县" },
            { "value": "191022", "label": "上城区" },
            { "value": "191693", "label": "滨江区" },
            { "value": "191166", "label": "江干区" },
            { "value": "192388", "label": "余杭区" },
            { "value": "191021", "label": "市辖区" },
            { "value": "191756", "label": "萧山区" },
            { "value": "191479", "label": "西湖区" },
            { "value": "193766", "label": "建德市" },
            { "value": "191083", "label": "下城区" },
            { "value": "192753", "label": "富阳区" },
            { "value": "194060", "label": "临安市" },
            { "value": "191360", "label": "拱墅区" }
          ]
        },
        {
          "value": "216449",
          "label": "台州市",
          "children": [
            { "value": "217998", "label": "三门县" },
            { "value": "219162", "label": "仙居县" },
            { "value": "217666", "label": "玉环县" },
            { "value": "220555", "label": "临海市" },
            { "value": "218534", "label": "天台县" },
            { "value": "216788", "label": "黄岩区" },
            { "value": "216450", "label": "市辖区" },
            { "value": "219609", "label": "温岭市" },
            { "value": "216451", "label": "椒江区" },
            { "value": "217324", "label": "路桥区" }
          ]
        }
      ]
    },
    {
      "value": "511462",
      "label": "广西壮族自治区",
      "children": [
        {
          "value": "513434",
          "label": "柳州市",
          "children": [
            { "value": "513435", "label": "市辖区" },
            { "value": "513661", "label": "柳北区" },
            { "value": "514219", "label": "融安县" },
            { "value": "513569", "label": "柳南区" },
            { "value": "513772", "label": "柳江区" },
            { "value": "513477", "label": "鱼峰区" },
            { "value": "514380", "label": "融水苗族自治县" },
            { "value": "513436", "label": "城中区" },
            { "value": "514607", "label": "三江侗族自治县" },
            { "value": "514091", "label": "鹿寨县" },
            { "value": "513938", "label": "柳城县" }
          ]
        },
        {
          "value": "524814",
          "label": "贺州市",
          "children": [
            { "value": "525035", "label": "平桂区" },
            { "value": "525169", "label": "昭平县" },
            { "value": "525341", "label": "钟山县" },
            { "value": "524815", "label": "市辖区" },
            { "value": "524816", "label": "八步区" },
            { "value": "525470", "label": "富川瑶族自治县" }
          ]
        },
        {
          "value": "525638",
          "label": "河池市",
          "children": [
            { "value": "525640", "label": "金城江区" },
            { "value": "527041", "label": "大化瑶族自治县" },
            { "value": "525639", "label": "市辖区" },
            { "value": "526772", "label": "都安瑶族自治县" },
            { "value": "527216", "label": "宜州市" },
            { "value": "526338", "label": "罗城仫佬族自治县" },
            { "value": "526493", "label": "环江毛南族自治县" },
            { "value": "526654", "label": "巴马瑶族自治县" },
            { "value": "526174", "label": "东兰县" },
            { "value": "525798", "label": "南丹县" },
            { "value": "525961", "label": "天峨县" },
            { "value": "526066", "label": "凤山县" }
          ]
        },
        {
          "value": "518407",
          "label": "防城港市",
          "children": [
            { "value": "518409", "label": "港口区" },
            { "value": "518408", "label": "市辖区" },
            { "value": "518641", "label": "上思县" },
            { "value": "518455", "label": "防城区" },
            { "value": "518762", "label": "东兴市" }
          ]
        },
        {
          "value": "522786",
          "label": "百色市",
          "children": [
            { "value": "523801", "label": "凌云县" },
            { "value": "523086", "label": "田东县" },
            { "value": "524502", "label": "靖西市" },
            { "value": "523265", "label": "平果县" },
            { "value": "523920", "label": "乐业县" },
            { "value": "523661", "label": "那坡县" },
            { "value": "523461", "label": "德保县" },
            { "value": "524200", "label": "西林县" },
            { "value": "524306", "label": "隆林各族自治县" },
            { "value": "522918", "label": "田阳县" },
            { "value": "524017", "label": "田林县" },
            { "value": "522787", "label": "市辖区" },
            { "value": "522788", "label": "右江区" }
          ]
        },
        {
          "value": "517933",
          "label": "北海市",
          "children": [
            { "value": "517935", "label": "海城区" },
            { "value": "518067", "label": "铁山港区" },
            { "value": "518115", "label": "合浦县" },
            { "value": "517934", "label": "市辖区" },
            { "value": "518010", "label": "银海区" }
          ]
        },
        {
          "value": "511463",
          "label": "南宁市",
          "children": [
            { "value": "512124", "label": "武鸣区" },
            { "value": "511465", "label": "兴宁区" },
            { "value": "513091", "label": "横县" },
            { "value": "511545", "label": "青秀区" },
            { "value": "511464", "label": "市辖区" },
            { "value": "512530", "label": "马山县" },
            { "value": "512838", "label": "宾阳县" },
            { "value": "511662", "label": "江南区" },
            { "value": "512044", "label": "邕宁区" },
            { "value": "511960", "label": "良庆区" },
            { "value": "512381", "label": "隆安县" },
            { "value": "512695", "label": "上林县" },
            { "value": "511790", "label": "西乡塘区" }
          ]
        },
        {
          "value": "521166",
          "label": "玉林市",
          "children": [
            { "value": "521832", "label": "博白县" },
            { "value": "521167", "label": "市辖区" },
            { "value": "521652", "label": "陆川县" },
            { "value": "521168", "label": "玉州区" },
            { "value": "521409", "label": "容县" },
            { "value": "522220", "label": "兴业县" },
            { "value": "521286", "label": "福绵区" },
            { "value": "522448", "label": "北流市" }
          ]
        },
        {
          "value": "528339",
          "label": "崇左市",
          "children": [
            { "value": "528647", "label": "宁明县" },
            { "value": "528340", "label": "市辖区" },
            { "value": "529297", "label": "凭祥市" },
            { "value": "528845", "label": "龙州县" },
            { "value": "528986", "label": "大新县" },
            { "value": "529159", "label": "天等县" },
            { "value": "528341", "label": "江州区" },
            { "value": "528494", "label": "扶绥县" }
          ]
        },
        {
          "value": "519929",
          "label": "贵港市",
          "children": [
            { "value": "520397", "label": "平南县" },
            { "value": "520240", "label": "覃塘区" },
            { "value": "520063", "label": "港南区" },
            { "value": "519930", "label": "市辖区" },
            { "value": "520707", "label": "桂平市" },
            { "value": "519931", "label": "港北区" }
          ]
        },
        {
          "value": "527443",
          "label": "来宾市",
          "children": [
            { "value": "527444", "label": "市辖区" },
            { "value": "527762", "label": "忻城县" },
            { "value": "528205", "label": "金秀瑶族自治县" },
            { "value": "527905", "label": "象州县" },
            { "value": "528042", "label": "武宣县" },
            { "value": "527445", "label": "兴宾区" },
            { "value": "528299", "label": "合山市" }
          ]
        },
        {
          "value": "518808",
          "label": "钦州市",
          "children": [
            { "value": "518810", "label": "钦南区" },
            { "value": "519637", "label": "浦北县" },
            { "value": "518809", "label": "市辖区" },
            { "value": "519201", "label": "灵山县" },
            { "value": "519003", "label": "钦北区" }
          ]
        },
        {
          "value": "516858",
          "label": "梧州市",
          "children": [
            { "value": "516860", "label": "万秀区" },
            { "value": "517075", "label": "苍梧县" },
            { "value": "516859", "label": "市辖区" },
            { "value": "517230", "label": "藤县" },
            { "value": "517542", "label": "蒙山县" },
            { "value": "517636", "label": "岑溪市" },
            { "value": "516945", "label": "长洲区" },
            { "value": "516991", "label": "龙圩区" }
          ]
        },
        {
          "value": "514790",
          "label": "桂林市",
          "children": [
            { "value": "515920", "label": "永福县" },
            { "value": "514792", "label": "秀峰区" },
            { "value": "516317", "label": "资源县" },
            { "value": "515481", "label": "全州县" },
            { "value": "514911", "label": "七星区" },
            { "value": "514964", "label": "雁山区" },
            { "value": "515195", "label": "阳朔县" },
            { "value": "516563", "label": "荔浦县" },
            { "value": "515784", "label": "兴安县" },
            { "value": "515012", "label": "临桂区" },
            { "value": "515319", "label": "灵川县" },
            { "value": "514864", "label": "象山区" },
            { "value": "516029", "label": "灌阳县" },
            { "value": "516721", "label": "恭城瑶族自治县" },
            { "value": "516180", "label": "龙胜各族自治县" },
            { "value": "514824", "label": "叠彩区" },
            { "value": "516399", "label": "平乐县" },
            { "value": "514791", "label": "市辖区" }
          ]
        }
      ]
    },
    {
      "value": "1",
      "label": "北京市",
      "children": [
        {
          "value": "2",
          "label": "直辖区",
          "children": [
            { "value": "5800", "label": "怀柔区" },
            { "value": "2689", "label": "房山区" },
            { "value": "6921", "label": "延庆区" },
            { "value": "1122", "label": "丰台区" },
            { "value": "3324", "label": "通州区" },
            { "value": "3927", "label": "顺义区" },
            { "value": "5042", "label": "大兴区" },
            { "value": "6138", "label": "平谷区" },
            { "value": "1692", "label": "海淀区" },
            { "value": "1533", "label": "石景山区" },
            { "value": "4498", "label": "昌平区" },
            { "value": "6466", "label": "密云区" },
            { "value": "203", "label": "西城区" },
            { "value": "3", "label": "东城区" },
            { "value": "2376", "label": "门头沟区" },
            { "value": "480", "label": "朝阳区" }
          ]
        }
      ]
    },
    {
      "value": "640332",
      "label": "西藏自治区",
      "children": [
        {
          "value": "644423",
          "label": "山南市",
          "children": [
            { "value": "644840", "label": "隆子县" },
            { "value": "644425", "label": "乃东区" },
            { "value": "644548", "label": "贡嘎县" },
            { "value": "644755", "label": "加查县" },
            { "value": "644720", "label": "洛扎县" },
            { "value": "644932", "label": "错那县" },
            { "value": "644424", "label": "市辖区" },
            { "value": "644647", "label": "琼结县" },
            { "value": "644672", "label": "曲松县" },
            { "value": "644480", "label": "扎囊县" },
            { "value": "644600", "label": "桑日县" },
            { "value": "644699", "label": "措美县" },
            { "value": "644967", "label": "浪卡子县" }
          ]
        },
        {
          "value": "645076",
          "label": "那曲地区",
          "children": [
            { "value": "645997", "label": "班戈县" },
            { "value": "645703", "label": "安多县" },
            { "value": "645791", "label": "申扎县" },
            { "value": "645231", "label": "嘉黎县" },
            { "value": "645077", "label": "那曲县" },
            { "value": "646261", "label": "尼玛县" },
            { "value": "645364", "label": "比如县" },
            { "value": "645550", "label": "聂荣县" },
            { "value": "646353", "label": "双湖县" },
            { "value": "645862", "label": "索县" },
            { "value": "646094", "label": "巴青县" }
          ]
        },
        {
          "value": "642571",
          "label": "昌都市",
          "children": [
            { "value": "643349", "label": "八宿县" },
            { "value": "642864", "label": "贡觉县" },
            { "value": "643691", "label": "洛隆县" },
            { "value": "642572", "label": "卡若区" },
            { "value": "643769", "label": "边坝县" },
            { "value": "643613", "label": "芒康县" },
            { "value": "643474", "label": "左贡县" },
            { "value": "643026", "label": "类乌齐县" },
            { "value": "642755", "label": "江达县" },
            { "value": "643197", "label": "察雅县" },
            { "value": "643119", "label": "丁青县" }
          ]
        },
        {
          "value": "640675",
          "label": "日喀则市",
          "children": [
            { "value": "641825", "label": "谢通门县" },
            { "value": "641393", "label": "萨迦县" },
            { "value": "642489", "label": "萨嘎县" },
            { "value": "641622", "label": "昂仁县" },
            { "value": "642356", "label": "亚东县" },
            { "value": "642437", "label": "聂拉木县" },
            { "value": "640865", "label": "南木林县" },
            { "value": "642063", "label": "仁布县" },
            { "value": "640676", "label": "桑珠孜区" },
            { "value": "642536", "label": "岗巴县" },
            { "value": "641940", "label": "白朗县" },
            { "value": "641512", "label": "拉孜县" },
            { "value": "642203", "label": "定结县" },
            { "value": "641029", "label": "江孜县" },
            { "value": "642146", "label": "康马县" },
            { "value": "642389", "label": "吉隆县" },
            { "value": "642284", "label": "仲巴县" },
            { "value": "641204", "label": "定日县" }
          ]
        },
        {
          "value": "640333",
          "label": "拉萨市",
          "children": [
            { "value": "640599", "label": "达孜县" },
            { "value": "640441", "label": "林周县" },
            { "value": "640626", "label": "墨竹工卡县" },
            { "value": "640534", "label": "尼木县" },
            { "value": "640575", "label": "曲水县" },
            { "value": "640399", "label": "堆龙德庆区" },
            { "value": "640335", "label": "城关区" },
            { "value": "640497", "label": "当雄县" },
            { "value": "640334", "label": "市辖区" }
          ]
        },
        {
          "value": "646392",
          "label": "阿里地区",
          "children": [
            { "value": "646431", "label": "噶尔县" },
            { "value": "646393", "label": "普兰县" },
            { "value": "646495", "label": "改则县" },
            { "value": "646407", "label": "札达县" },
            { "value": "646551", "label": "措勤县" },
            { "value": "646470", "label": "革吉县" },
            { "value": "646451", "label": "日土县" }
          ]
        },
        {
          "value": "643863",
          "label": "林芝市",
          "children": [
            { "value": "643864", "label": "巴宜区" },
            { "value": "643943", "label": "工布江达县" },
            { "value": "644364", "label": "朗县" },
            { "value": "644260", "label": "察隅县" },
            { "value": "644164", "label": "波密县" },
            { "value": "644033", "label": "米林县" },
            { "value": "644109", "label": "墨脱县" }
          ]
        }
      ]
    },
    {
      "value": "529344",
      "label": "海南省",
      "children": [
        {
          "value": "530468",
          "label": "直辖县",
          "children": [
            { "value": "531811", "label": "屯昌县" },
            { "value": "532906", "label": "陵水黎族自治县" },
            { "value": "532672", "label": "乐东黎族自治县" },
            { "value": "530809", "label": "文昌市" },
            { "value": "532200", "label": "临高县" },
            { "value": "530552", "label": "琼海市" },
            { "value": "532558", "label": "昌江黎族自治县" },
            { "value": "533161", "label": "琼中黎族苗族自治县" },
            { "value": "532409", "label": "白沙黎族自治县" },
            { "value": "531647", "label": "定安县" },
            { "value": "530469", "label": "五指山市" },
            { "value": "531971", "label": "澄迈县" },
            { "value": "531148", "label": "万宁市" },
            { "value": "533053", "label": "保亭黎族苗族自治县" },
            { "value": "531426", "label": "东方市" }
          ]
        },
        {
          "value": "529345",
          "label": "海口市",
          "children": [
            { "value": "529720", "label": "美兰区" },
            { "value": "529347", "label": "秀英区" },
            { "value": "529346", "label": "市辖区" },
            { "value": "529451", "label": "龙华区" },
            { "value": "529589", "label": "琼山区" }
          ]
        },
        {
          "value": "530060",
          "label": "三沙市",
          "children": [
            { "value": "530064", "label": "南沙群岛" },
            { "value": "530067", "label": "中沙群岛的岛礁及其海域" },
            { "value": "530061", "label": "西沙群岛" }
          ]
        },
        {
          "value": "530070",
          "label": "儋州市",
          "children": [
            { "value": "530275", "label": "三都镇" },
            { "value": "530410", "label": "国营西联农场" },
            { "value": "530188", "label": "兰洋镇" },
            { "value": "530220", "label": "木棠镇" },
            { "value": "530139", "label": "大成镇" },
            { "value": "530466", "label": "华南热作学院" },
            { "value": "530361", "label": "新州镇" },
            { "value": "530126", "label": "南丰镇" },
            { "value": "530071", "label": "那大镇" },
            { "value": "530112", "label": "和庆镇" },
            { "value": "530331", "label": "排浦镇" },
            { "value": "530447", "label": "洋浦经济开发区" },
            { "value": "530431", "label": "国营蓝洋农场" },
            { "value": "530437", "label": "国营八一农场" },
            { "value": "530394", "label": "国营西培农场" },
            { "value": "530207", "label": "光村镇" },
            { "value": "530285", "label": "王五镇" },
            { "value": "530318", "label": "中和镇" },
            { "value": "530246", "label": "海头镇" },
            { "value": "530261", "label": "峨蔓镇" },
            { "value": "530295", "label": "白马井镇" },
            { "value": "530340", "label": "东成镇" },
            { "value": "530162", "label": "雅星镇" }
          ]
        },
        {
          "value": "529878",
          "label": "三亚市",
          "children": [
            { "value": "529910", "label": "海棠区" },
            { "value": "529973", "label": "天涯区" },
            { "value": "530027", "label": "崖州区" },
            { "value": "529934", "label": "吉阳区" },
            { "value": "529879", "label": "市辖区" }
          ]
        }
      ]
    },
    {
      "value": "695259",
      "label": "宁夏回族自治区",
      "children": [
        {
          "value": "695260",
          "label": "银川市",
          "children": [
            { "value": "695575", "label": "永宁县" },
            { "value": "695262", "label": "兴庆区" },
            { "value": "695500", "label": "金凤区" },
            { "value": "695261", "label": "市辖区" },
            { "value": "695675", "label": "贺兰县" },
            { "value": "695767", "label": "灵武市" },
            { "value": "695412", "label": "西夏区" }
          ]
        },
        {
          "value": "695883",
          "label": "石嘴山市",
          "children": [
            { "value": "695884", "label": "市辖区" },
            { "value": "696058", "label": "平罗县" },
            { "value": "695885", "label": "大武口区" },
            { "value": "695959", "label": "惠农区" }
          ]
        },
        {
          "value": "696252",
          "label": "吴忠市",
          "children": [
            { "value": "696633", "label": "同心县" },
            { "value": "696253", "label": "市辖区" },
            { "value": "696505", "label": "盐池县" },
            { "value": "696254", "label": "利通区" },
            { "value": "696427", "label": "红寺堡区" },
            { "value": "696803", "label": "青铜峡市" }
          ]
        },
        {
          "value": "697887",
          "label": "中卫市",
          "children": [
            { "value": "697888", "label": "市辖区" },
            { "value": "697889", "label": "沙坡头区" },
            { "value": "698087", "label": "中宁县" },
            { "value": "698240", "label": "海原县" }
          ]
        },
        {
          "value": "696928",
          "label": "固原市",
          "children": [
            { "value": "696929", "label": "市辖区" },
            { "value": "697714", "label": "彭阳县" },
            { "value": "696930", "label": "原州区" },
            { "value": "697594", "label": "泾源县" },
            { "value": "697457", "label": "隆德县" },
            { "value": "697133", "label": "西吉县" }
          ]
        }
      ]
    },
    {
      "value": "161792",
      "label": "上海市",
      "children": [
        {
          "value": "161793",
          "label": "直辖区",
          "children": [
            { "value": "162794", "label": "普陀区" },
            { "value": "165026", "label": "浦东新区" },
            { "value": "163069", "label": "虹口区" },
            { "value": "167533", "label": "崇明区" },
            { "value": "164682", "label": "嘉定区" },
            { "value": "163621", "label": "闵行区" },
            { "value": "162309", "label": "长宁区" },
            { "value": "166927", "label": "青浦区" },
            { "value": "161988", "label": "徐汇区" },
            { "value": "167228", "label": "奉贤区" },
            { "value": "166585", "label": "松江区" },
            { "value": "164194", "label": "宝山区" },
            { "value": "163290", "label": "杨浦区" },
            { "value": "161794", "label": "黄浦区" },
            { "value": "162505", "label": "静安区" },
            { "value": "166350", "label": "金山区" }
          ]
        }
      ]
    },
    {
      "value": "420824",
      "label": "湖北省",
      "children": [
        {
          "value": "420825",
          "label": "武汉市",
          "children": [
            { "value": "422149", "label": "汉南区" },
            { "value": "420996", "label": "江汉区" },
            { "value": "421559", "label": "青山区" },
            { "value": "423075", "label": "黄陂区" },
            { "value": "421270", "label": "汉阳区" },
            { "value": "421122", "label": "硚口区" },
            { "value": "421983", "label": "东西湖区" },
            { "value": "420827", "label": "江岸区" },
            { "value": "421400", "label": "武昌区" },
            { "value": "423775", "label": "新洲区" },
            { "value": "422631", "label": "江夏区" },
            { "value": "422220", "label": "蔡甸区" },
            { "value": "421676", "label": "洪山区" },
            { "value": "420826", "label": "市辖区" }
          ]
        },
        {
          "value": "424449",
          "label": "黄石市",
          "children": [
            { "value": "424489", "label": "西塞山区" },
            { "value": "425070", "label": "大冶市" },
            { "value": "424451", "label": "黄石港区" },
            { "value": "424590", "label": "阳新县" },
            { "value": "424574", "label": "铁山区" },
            { "value": "424531", "label": "下陆区" },
            { "value": "424450", "label": "市辖区" }
          ]
        },
        {
          "value": "447268",
          "label": "恩施土家族苗族自治州",
          "children": [
            { "value": "447495", "label": "利川市" },
            { "value": "449636", "label": "鹤峰县" },
            { "value": "448856", "label": "宣恩县" },
            { "value": "449150", "label": "咸丰县" },
            { "value": "449431", "label": "来凤县" },
            { "value": "447269", "label": "恩施市" },
            { "value": "448099", "label": "建始县" },
            { "value": "448521", "label": "巴东县" }
          ]
        },
        {
          "value": "429501",
          "label": "襄阳市",
          "children": [
            { "value": "431575", "label": "枣阳市" },
            { "value": "431301", "label": "老河口市" },
            { "value": "429503", "label": "襄城区" },
            { "value": "430723", "label": "谷城县" },
            { "value": "430402", "label": "南漳县" },
            { "value": "429687", "label": "樊城区" },
            { "value": "429502", "label": "市辖区" },
            { "value": "431025", "label": "保康县" },
            { "value": "429907", "label": "襄州区" },
            { "value": "432166", "label": "宜城市" }
          ]
        },
        {
          "value": "445040",
          "label": "咸宁市",
          "children": [
            { "value": "445786", "label": "通山县" },
            { "value": "445363", "label": "通城县" },
            { "value": "445041", "label": "市辖区" },
            { "value": "446007", "label": "赤壁市" },
            { "value": "445249", "label": "嘉鱼县" },
            { "value": "445569", "label": "崇阳县" },
            { "value": "445042", "label": "咸安区" }
          ]
        },
        {
          "value": "432829",
          "label": "荆门市",
          "children": [
            { "value": "433052", "label": "掇刀区" },
            { "value": "432831", "label": "东宝区" },
            { "value": "433182", "label": "京山县" },
            { "value": "432830", "label": "市辖区" },
            { "value": "433944", "label": "钟祥市" },
            { "value": "433634", "label": "沙洋县" }
          ]
        },
        {
          "value": "434548",
          "label": "孝感市",
          "children": [
            { "value": "435563", "label": "大悟县" },
            { "value": "434549", "label": "市辖区" },
            { "value": "435091", "label": "孝昌县" },
            { "value": "435956", "label": "云梦县" },
            { "value": "437119", "label": "汉川市" },
            { "value": "436706", "label": "安陆市" },
            { "value": "436267", "label": "应城市" },
            { "value": "434550", "label": "孝南区" }
          ]
        },
        {
          "value": "425508",
          "label": "十堰市",
          "children": [
            { "value": "425509", "label": "市辖区" },
            { "value": "425709", "label": "郧阳区" },
            { "value": "426440", "label": "竹山县" },
            { "value": "426708", "label": "竹溪县" },
            { "value": "425510", "label": "茅箭区" },
            { "value": "427085", "label": "房县" },
            { "value": "425592", "label": "张湾区" },
            { "value": "426078", "label": "郧西县" },
            { "value": "427420", "label": "丹江口市" }
          ]
        },
        {
          "value": "427710",
          "label": "宜昌市",
          "children": [
            { "value": "429273", "label": "枝江市" },
            { "value": "428424", "label": "秭归县" },
            { "value": "427953", "label": "猇亭区" },
            { "value": "428194", "label": "远安县" },
            { "value": "427894", "label": "点军区" },
            { "value": "427712", "label": "西陵区" },
            { "value": "428630", "label": "长阳土家族自治县" },
            { "value": "427983", "label": "夷陵区" },
            { "value": "428319", "label": "兴山县" },
            { "value": "428924", "label": "宜都市" },
            { "value": "428807", "label": "五峰土家族自治县" },
            { "value": "427828", "label": "伍家岗区" },
            { "value": "429089", "label": "当阳市" },
            { "value": "427711", "label": "市辖区" }
          ]
        },
        {
          "value": "449865",
          "label": "直辖县",
          "children": [
            { "value": "449866", "label": "仙桃市" },
            { "value": "452034", "label": "神农架林区" },
            { "value": "450653", "label": "潜江市" },
            { "value": "451150", "label": "天门市" }
          ]
        },
        {
          "value": "446212",
          "label": "随州市",
          "children": [
            { "value": "446214", "label": "曾都区" },
            { "value": "446847", "label": "广水市" },
            { "value": "446433", "label": "随县" },
            { "value": "446213", "label": "市辖区" }
          ]
        },
        {
          "value": "440639",
          "label": "黄冈市",
          "children": [
            { "value": "441110", "label": "红安县" },
            { "value": "440640", "label": "市辖区" },
            { "value": "443040", "label": "蕲春县" },
            { "value": "444186", "label": "麻城市" },
            { "value": "444684", "label": "武穴市" },
            { "value": "440641", "label": "黄州区" },
            { "value": "442017", "label": "英山县" },
            { "value": "443641", "label": "黄梅县" },
            { "value": "442351", "label": "浠水县" },
            { "value": "441550", "label": "罗田县" },
            { "value": "440797", "label": "团风县" }
          ]
        },
        {
          "value": "437779",
          "label": "荆州市",
          "children": [
            { "value": "439836", "label": "洪湖市" },
            { "value": "438103", "label": "公安县" },
            { "value": "437780", "label": "市辖区" },
            { "value": "439263", "label": "江陵县" },
            { "value": "440344", "label": "松滋市" },
            { "value": "438512", "label": "监利县" },
            { "value": "437909", "label": "荆州区" },
            { "value": "437781", "label": "沙市区" },
            { "value": "439491", "label": "石首市" }
          ]
        },
        {
          "value": "432415",
          "label": "鄂州市",
          "children": [
            { "value": "432416", "label": "市辖区" },
            { "value": "432510", "label": "华容区" },
            { "value": "432633", "label": "鄂城区" },
            { "value": "432417", "label": "梁子湖区" }
          ]
        }
      ]
    },
    {
      "value": "483250",
      "label": "广东省",
      "children": [
        {
          "value": "488954",
          "label": "汕头市",
          "children": [
            { "value": "489338", "label": "潮阳区" },
            { "value": "489624", "label": "潮南区" },
            { "value": "488955", "label": "市辖区" },
            { "value": "489270", "label": "濠江区" },
            { "value": "489082", "label": "金平区" },
            { "value": "489868", "label": "澄海区" },
            { "value": "488956", "label": "龙湖区" },
            { "value": "490064", "label": "南澳县" }
          ]
        },
        {
          "value": "494394",
          "label": "茂名市",
          "children": [
            { "value": "494395", "label": "市辖区" },
            { "value": "495611", "label": "化州市" },
            { "value": "494396", "label": "茂南区" },
            { "value": "494629", "label": "电白区" },
            { "value": "495088", "label": "高州市" },
            { "value": "496016", "label": "信宜市" }
          ]
        },
        {
          "value": "496438",
          "label": "肇庆市",
          "children": [
            { "value": "497926", "label": "四会市" },
            { "value": "497719", "label": "德庆县" },
            { "value": "496505", "label": "鼎湖区" },
            { "value": "496440", "label": "端州区" },
            { "value": "496594", "label": "高要区" },
            { "value": "497158", "label": "怀集县" },
            { "value": "497503", "label": "封开县" },
            { "value": "496439", "label": "市辖区" },
            { "value": "496964", "label": "广宁县" }
          ]
        },
        {
          "value": "486129",
          "label": "韶关市",
          "children": [
            { "value": "487223", "label": "乐昌市" },
            { "value": "486469", "label": "始兴县" },
            { "value": "486223", "label": "浈江区" },
            { "value": "486929", "label": "乳源瑶族自治县" },
            { "value": "487058", "label": "新丰县" },
            { "value": "486746", "label": "翁源县" },
            { "value": "486131", "label": "武江区" },
            { "value": "486609", "label": "仁化县" },
            { "value": "487468", "label": "南雄市" },
            { "value": "486345", "label": "曲江区" },
            { "value": "486130", "label": "市辖区" }
          ]
        },
        {
          "value": "499489",
          "label": "梅州市",
          "children": [
            { "value": "499491", "label": "梅江区" },
            { "value": "499625", "label": "梅县区" },
            { "value": "501230", "label": "蕉岭县" },
            { "value": "500312", "label": "丰顺县" },
            { "value": "500036", "label": "大埔县" },
            { "value": "501351", "label": "兴宁市" },
            { "value": "500611", "label": "五华县" },
            { "value": "499490", "label": "市辖区" },
            { "value": "501074", "label": "平远县" }
          ]
        },
        {
          "value": "505298",
          "label": "清远市",
          "children": [
            { "value": "505963", "label": "连山壮族瑶族自治县" },
            { "value": "505466", "label": "清新区" },
            { "value": "505300", "label": "清城区" },
            { "value": "505299", "label": "市辖区" },
            { "value": "506027", "label": "连南瑶族自治县" },
            { "value": "506106", "label": "英德市" },
            { "value": "505782", "label": "阳山县" },
            { "value": "506430", "label": "连州市" },
            { "value": "505685", "label": "佛冈县" }
          ]
        },
        {
          "value": "507557",
          "label": "潮州市",
          "children": [
            { "value": "507748", "label": "潮安区" },
            { "value": "507558", "label": "市辖区" },
            { "value": "508223", "label": "饶平县" },
            { "value": "507559", "label": "湘桥区" }
          ]
        },
        {
          "value": "492341",
          "label": "湛江市",
          "children": [
            { "value": "492343", "label": "赤坎区" },
            { "value": "492342", "label": "市辖区" },
            { "value": "493663", "label": "雷州市" },
            { "value": "492563", "label": "麻章区" },
            { "value": "493015", "label": "徐闻县" },
            { "value": "492484", "label": "坡头区" },
            { "value": "494182", "label": "吴川市" },
            { "value": "492393", "label": "霞山区" },
            { "value": "493241", "label": "廉江市" },
            { "value": "492716", "label": "遂溪县" }
          ]
        },
        {
          "value": "483251",
          "label": "广州市",
          "children": [
            { "value": "484770", "label": "番禺区" },
            { "value": "483253", "label": "荔湾区" },
            { "value": "484218", "label": "白云区" },
            { "value": "483462", "label": "越秀区" },
            { "value": "483703", "label": "海珠区" },
            { "value": "483979", "label": "天河区" },
            { "value": "485489", "label": "从化区" },
            { "value": "485776", "label": "增城区" },
            { "value": "485059", "label": "花都区" },
            { "value": "483252", "label": "市辖区" },
            { "value": "485312", "label": "南沙区" },
            { "value": "484627", "label": "黄埔区" }
          ]
        },
        {
          "value": "501867",
          "label": "汕尾市",
          "children": [
            { "value": "501869", "label": "城区" },
            { "value": "502303", "label": "陆河县" },
            { "value": "501868", "label": "市辖区" },
            { "value": "502002", "label": "海丰县" },
            { "value": "502439", "label": "陆丰市" }
          ]
        },
        {
          "value": "490919",
          "label": "江门市",
          "children": [
            { "value": "491711", "label": "开平市" },
            { "value": "491070", "label": "江海区" },
            { "value": "492153", "label": "恩平市" },
            { "value": "491136", "label": "新会区" },
            { "value": "490920", "label": "市辖区" },
            { "value": "491380", "label": "台山市" },
            { "value": "492000", "label": "鹤山市" },
            { "value": "490921", "label": "蓬江区" }
          ]
        },
        {
          "value": "506616",
          "label": "东莞市",
          "children": [
            { "value": "506727", "label": "茶山镇" },
            { "value": "506887", "label": "寮步镇" },
            { "value": "506990", "label": "塘厦镇" },
            { "value": "506930", "label": "大朗镇" },
            { "value": "506716", "label": "石龙镇" },
            { "value": "506691", "label": "莞城街道办事处" },
            { "value": "506959", "label": "黄江镇" },
            { "value": "507208", "label": "中堂镇" },
            { "value": "506823", "label": "谢岗镇" },
            { "value": "507052", "label": "长安镇" },
            { "value": "506746", "label": "石排镇" },
            { "value": "506766", "label": "企石镇" },
            { "value": "507099", "label": "厚街镇" },
            { "value": "507143", "label": "道滘镇" },
            { "value": "506617", "label": "东城街道办事处" },
            { "value": "506836", "label": "东坑镇" },
            { "value": "506700", "label": "石碣镇" },
            { "value": "507028", "label": "大岭山镇" },
            { "value": "507251", "label": "虎门港管委会" },
            { "value": "507185", "label": "望牛墩镇" },
            { "value": "506918", "label": "樟木头镇" },
            { "value": "507229", "label": "高埗镇" },
            { "value": "506967", "label": "清溪镇" },
            { "value": "507124", "label": "沙田镇" },
            { "value": "506787", "label": "横沥镇" },
            { "value": "507015", "label": "凤岗镇" },
            { "value": "506643", "label": "南城街道办事处" },
            { "value": "506853", "label": "常平镇" },
            { "value": "506662", "label": "万江街道办事处" },
            { "value": "506805", "label": "桥头镇" },
            { "value": "507066", "label": "虎门镇" },
            { "value": "507253", "label": "东莞生态园" },
            { "value": "507249", "label": "松山湖管委会" },
            { "value": "507169", "label": "麻涌镇" },
            { "value": "507158", "label": "洪梅镇" }
          ]
        },
        {
          "value": "508648",
          "label": "揭阳市",
          "children": [
            { "value": "508650", "label": "榕城区" },
            { "value": "509449", "label": "惠来县" },
            { "value": "508649", "label": "市辖区" },
            { "value": "509796", "label": "普宁市" },
            { "value": "508863", "label": "揭东区" },
            { "value": "509101", "label": "揭西县" }
          ]
        },
        {
          "value": "502828",
          "label": "河源市",
          "children": [
            { "value": "503797", "label": "和平县" },
            { "value": "503226", "label": "龙川县" },
            { "value": "504060", "label": "东源县" },
            { "value": "502907", "label": "紫金县" },
            { "value": "502829", "label": "市辖区" },
            { "value": "502830", "label": "源城区" },
            { "value": "503608", "label": "连平县" }
          ]
        },
        {
          "value": "510408",
          "label": "云浮市",
          "children": [
            { "value": "510660", "label": "新兴县" },
            { "value": "511102", "label": "罗定市" },
            { "value": "510878", "label": "郁南县" },
            { "value": "510410", "label": "云城区" },
            { "value": "510536", "label": "云安区" },
            { "value": "510409", "label": "市辖区" }
          ]
        },
        {
          "value": "507255",
          "label": "中山市",
          "children": [
            { "value": "507493", "label": "南朗镇" },
            { "value": "507413", "label": "沙溪镇" },
            { "value": "507369", "label": "东凤镇" },
            { "value": "507316", "label": "小榄镇" },
            { "value": "507332", "label": "黄圃镇" },
            { "value": "507349", "label": "民众镇" },
            { "value": "507538", "label": "大涌镇" },
            { "value": "507464", "label": "横栏镇" },
            { "value": "507509", "label": "三乡镇" },
            { "value": "507310", "label": "五桂山街道办事处" },
            { "value": "507276", "label": "东区街道办事处" },
            { "value": "507384", "label": "东升镇" },
            { "value": "507256", "label": "石岐区街道办事处" },
            { "value": "507287", "label": "火炬开发区街道办事处" },
            { "value": "507550", "label": "神湾镇" },
            { "value": "507455", "label": "三角镇" },
            { "value": "507476", "label": "南头镇" },
            { "value": "507526", "label": "板芙镇" },
            { "value": "507305", "label": "南区街道办事处" },
            { "value": "507295", "label": "西区街道办事处" },
            { "value": "507483", "label": "阜沙镇" },
            { "value": "507445", "label": "港口镇" },
            { "value": "507430", "label": "坦洲镇" },
            { "value": "507399", "label": "古镇镇" }
          ]
        },
        {
          "value": "487721",
          "label": "深圳市",
          "children": [
            { "value": "487723", "label": "罗湖区" },
            { "value": "488090", "label": "宝安区" },
            { "value": "487977", "label": "南山区" },
            { "value": "488375", "label": "龙岗区" },
            { "value": "487849", "label": "福田区" },
            { "value": "488558", "label": "盐田区" },
            { "value": "487722", "label": "市辖区" }
          ]
        },
        {
          "value": "490106",
          "label": "佛山市",
          "children": [
            { "value": "490107", "label": "市辖区" },
            { "value": "490257", "label": "南海区" },
            { "value": "490834", "label": "高明区" },
            { "value": "490746", "label": "三水区" },
            { "value": "490108", "label": "禅城区" },
            { "value": "490531", "label": "顺德区" }
          ]
        },
        {
          "value": "498104",
          "label": "惠州市",
          "children": [
            { "value": "498105", "label": "市辖区" },
            { "value": "498978", "label": "惠东县" },
            { "value": "498578", "label": "博罗县" },
            { "value": "498106", "label": "惠城区" },
            { "value": "498397", "label": "惠阳区" },
            { "value": "499292", "label": "龙门县" }
          ]
        },
        {
          "value": "504366",
          "label": "阳江市",
          "children": [
            { "value": "504749", "label": "阳西县" },
            { "value": "504549", "label": "阳东区" },
            { "value": "504911", "label": "阳春市" },
            { "value": "504367", "label": "市辖区" },
            { "value": "504368", "label": "江城区" }
          ]
        },
        {
          "value": "488589",
          "label": "珠海市",
          "children": [
            { "value": "488591", "label": "香洲区" },
            { "value": "488905", "label": "金湾区" },
            { "value": "488768", "label": "斗门区" },
            { "value": "488590", "label": "市辖区" }
          ]
        }
      ]
    },
    {
      "value": "714390",
      "label": "澳门特别行政区",
      "children": [
        {
          "value": "714397",
          "label": "氹仔岛",
          "children": [{ "value": "714398", "label": "嘉模堂区" }]
        },
        {
          "value": "714399",
          "label": "路环岛",
          "children": [{ "value": "714400", "label": "圣方济各堂区" }]
        },
        {
          "value": "714391",
          "label": "澳门半岛",
          "children": [
            { "value": "714392", "label": "花地玛堂区" },
            { "value": "714393", "label": "圣安多尼堂区" },
            { "value": "714396", "label": "风顺堂区" },
            { "value": "714394", "label": "大堂区" },
            { "value": "714395", "label": "望德堂区" }
          ]
        }
      ]
    },
    {
      "value": "67747",
      "label": "山西省",
      "children": [
        {
          "value": "87613",
          "label": "忻州市",
          "children": [
            { "value": "88754", "label": "代县" },
            { "value": "87615", "label": "忻府区" },
            { "value": "89570", "label": "宁武县" },
            { "value": "89144", "label": "繁峙县" },
            { "value": "90715", "label": "五寨县" },
            { "value": "92172", "label": "原平市" },
            { "value": "90066", "label": "静乐县" },
            { "value": "90462", "label": "神池县" },
            { "value": "91912", "label": "偏关县" },
            { "value": "90978", "label": "岢岚县" },
            { "value": "87614", "label": "市辖区" },
            { "value": "91548", "label": "保德县" },
            { "value": "88061", "label": "定襄县" },
            { "value": "88227", "label": "五台县" },
            { "value": "91193", "label": "河曲县" }
          ]
        },
        {
          "value": "96064",
          "label": "吕梁市",
          "children": [
            { "value": "98562", "label": "中阳县" },
            { "value": "98201", "label": "岚县" },
            { "value": "98383", "label": "方山县" },
            { "value": "96711", "label": "兴县" },
            { "value": "96065", "label": "市辖区" },
            { "value": "96066", "label": "离石区" },
            { "value": "98774", "label": "孝义市" },
            { "value": "99219", "label": "汾阳市" },
            { "value": "96548", "label": "交城县" },
            { "value": "98056", "label": "石楼县" },
            { "value": "97113", "label": "临县" },
            { "value": "97779", "label": "柳林县" },
            { "value": "96332", "label": "文水县" },
            { "value": "98670", "label": "交口县" }
          ]
        },
        {
          "value": "92738",
          "label": "临汾市",
          "children": [
            { "value": "95472", "label": "蒲县" },
            { "value": "93972", "label": "洪洞县" },
            { "value": "95181", "label": "大宁县" },
            { "value": "93375", "label": "翼城县" },
            { "value": "95715", "label": "侯马市" },
            { "value": "95381", "label": "永和县" },
            { "value": "94696", "label": "浮山县" },
            { "value": "93208", "label": "曲沃县" },
            { "value": "95828", "label": "霍州市" },
            { "value": "95579", "label": "汾西县" },
            { "value": "94981", "label": "乡宁县" },
            { "value": "92739", "label": "市辖区" },
            { "value": "94580", "label": "安泽县" },
            { "value": "94457", "label": "古县" },
            { "value": "92740", "label": "尧都区" },
            { "value": "94893", "label": "吉县" },
            { "value": "93604", "label": "襄汾县" },
            { "value": "95272", "label": "隰县" }
          ]
        },
        {
          "value": "67748",
          "label": "太原市",
          "children": [
            { "value": "67921", "label": "迎泽区" },
            { "value": "68043", "label": "杏花岭区" },
            { "value": "67750", "label": "小店区" },
            { "value": "69190", "label": "古交市" },
            { "value": "68893", "label": "阳曲县" },
            { "value": "67749", "label": "市辖区" },
            { "value": "68542", "label": "晋源区" },
            { "value": "68671", "label": "清徐县" },
            { "value": "69032", "label": "娄烦县" },
            { "value": "68203", "label": "尖草坪区" },
            { "value": "68368", "label": "万柏林区" }
          ]
        },
        {
          "value": "71850",
          "label": "阳泉市",
          "children": [
            { "value": "71950", "label": "郊区" },
            { "value": "71851", "label": "市辖区" },
            { "value": "71905", "label": "矿区" },
            { "value": "71852", "label": "城区" },
            { "value": "72145", "label": "平定县" },
            { "value": "72497", "label": "盂县" }
          ]
        },
        {
          "value": "81000",
          "label": "晋中市",
          "children": [
            { "value": "82183", "label": "昔阳县" },
            { "value": "81361", "label": "榆社县" },
            { "value": "81001", "label": "市辖区" },
            { "value": "82765", "label": "太谷县" },
            { "value": "83472", "label": "灵石县" },
            { "value": "81002", "label": "榆次区" },
            { "value": "81649", "label": "左权县" },
            { "value": "83810", "label": "介休市" },
            { "value": "81872", "label": "和顺县" },
            { "value": "82986", "label": "祁县" },
            { "value": "82535", "label": "寿阳县" },
            { "value": "83165", "label": "平遥县" }
          ]
        },
        {
          "value": "79163",
          "label": "朔州市",
          "children": [
            { "value": "79164", "label": "市辖区" },
            { "value": "79533", "label": "平鲁区" },
            { "value": "80799", "label": "怀仁县" },
            { "value": "79165", "label": "朔城区" },
            { "value": "80134", "label": "应县" },
            { "value": "80454", "label": "右玉县" },
            { "value": "79843", "label": "山阴县" }
          ]
        },
        {
          "value": "69390",
          "label": "大同市",
          "children": [
            { "value": "69392", "label": "城区" },
            { "value": "69391", "label": "市辖区" },
            { "value": "70336", "label": "天镇县" },
            { "value": "69691", "label": "南郊区" },
            { "value": "69891", "label": "新荣区" },
            { "value": "70778", "label": "灵丘县" },
            { "value": "71649", "label": "大同县" },
            { "value": "70582", "label": "广灵县" },
            { "value": "69546", "label": "矿区" },
            { "value": "70045", "label": "阳高县" },
            { "value": "71402", "label": "左云县" },
            { "value": "71059", "label": "浑源县" }
          ]
        },
        {
          "value": "84092",
          "label": "运城市",
          "children": [
            { "value": "84893", "label": "万荣县" },
            { "value": "85569", "label": "稷山县" },
            { "value": "86448", "label": "夏县" },
            { "value": "85780", "label": "新绛县" },
            { "value": "84093", "label": "市辖区" },
            { "value": "85194", "label": "闻喜县" },
            { "value": "86238", "label": "垣曲县" },
            { "value": "86949", "label": "芮城县" },
            { "value": "87434", "label": "河津市" },
            { "value": "87129", "label": "永济市" },
            { "value": "86023", "label": "绛县" },
            { "value": "84094", "label": "盐湖区" },
            { "value": "84483", "label": "临猗县" },
            { "value": "86706", "label": "平陆县" }
          ]
        },
        {
          "value": "72975",
          "label": "长治市",
          "children": [
            { "value": "75911", "label": "沁县" },
            { "value": "73495", "label": "襄垣县" },
            { "value": "74429", "label": "黎城县" },
            { "value": "73071", "label": "郊区" },
            { "value": "76512", "label": "潞城市" },
            { "value": "73222", "label": "长治县" },
            { "value": "76237", "label": "沁源县" },
            { "value": "72976", "label": "市辖区" },
            { "value": "74694", "label": "壶关县" },
            { "value": "73840", "label": "屯留县" },
            { "value": "75101", "label": "长子县" },
            { "value": "75515", "label": "武乡县" },
            { "value": "72977", "label": "城区" },
            { "value": "74151", "label": "平顺县" }
          ]
        },
        {
          "value": "76726",
          "label": "晋城市",
          "children": [
            { "value": "76874", "label": "沁水县" },
            { "value": "77140", "label": "阳城县" },
            { "value": "78678", "label": "高平市" },
            { "value": "78024", "label": "泽州县" },
            { "value": "77632", "label": "陵川县" },
            { "value": "76727", "label": "市辖区" },
            { "value": "76728", "label": "城区" }
          ]
        }
      ]
    },
    {
      "value": "99537",
      "label": "内蒙古自治区",
      "children": [
        {
          "value": "99538",
          "label": "呼和浩特市",
          "children": [
            { "value": "100790", "label": "武川县" },
            { "value": "100011", "label": "土默特左旗" },
            { "value": "99624", "label": "回民区" },
            { "value": "99807", "label": "赛罕区" },
            { "value": "100502", "label": "和林格尔县" },
            { "value": "99539", "label": "市辖区" },
            { "value": "100358", "label": "托克托县" },
            { "value": "99696", "label": "玉泉区" },
            { "value": "100672", "label": "清水河县" },
            { "value": "99540", "label": "新城区" }
          ]
        },
        {
          "value": "109847",
          "label": "巴彦淖尔市",
          "children": [
            { "value": "110095", "label": "五原县" },
            { "value": "110255", "label": "磴口县" },
            { "value": "109848", "label": "市辖区" },
            { "value": "109849", "label": "临河区" },
            { "value": "110372", "label": "乌拉特前旗" },
            { "value": "110557", "label": "乌拉特中旗" },
            { "value": "110676", "label": "乌拉特后旗" },
            { "value": "110743", "label": "杭锦后旗" }
          ]
        },
        {
          "value": "101772",
          "label": "乌海市",
          "children": [
            { "value": "101850", "label": "乌达区" },
            { "value": "101774", "label": "海勃湾区" },
            { "value": "101824", "label": "海南区" },
            { "value": "101773", "label": "市辖区" }
          ]
        },
        {
          "value": "101884",
          "label": "赤峰市",
          "children": [
            { "value": "101885", "label": "市辖区" },
            { "value": "101986", "label": "元宝山区" },
            { "value": "103225", "label": "克什克腾旗" },
            { "value": "102714", "label": "巴林左旗" },
            { "value": "103835", "label": "宁城县" },
            { "value": "101886", "label": "红山区" },
            { "value": "102434", "label": "阿鲁科尔沁旗" },
            { "value": "102110", "label": "松山区" },
            { "value": "103097", "label": "林西县" },
            { "value": "104189", "label": "敖汉旗" },
            { "value": "103385", "label": "翁牛特旗" },
            { "value": "103647", "label": "喀喇沁旗" },
            { "value": "102913", "label": "巴林右旗" }
          ]
        },
        {
          "value": "110889",
          "label": "乌兰察布市",
          "children": [
            { "value": "111666", "label": "凉城县" },
            { "value": "111237", "label": "商都县" },
            { "value": "112164", "label": "察哈尔右翼后旗" },
            { "value": "112277", "label": "四子王旗" },
            { "value": "111127", "label": "化德县" },
            { "value": "111969", "label": "察哈尔右翼中旗" },
            { "value": "110891", "label": "集宁区" },
            { "value": "111481", "label": "兴和县" },
            { "value": "110998", "label": "卓资县" },
            { "value": "111824", "label": "察哈尔右翼前旗" },
            { "value": "110890", "label": "市辖区" },
            { "value": "112427", "label": "丰镇市" }
          ]
        },
        {
          "value": "100904",
          "label": "包头市",
          "children": [
            { "value": "101359", "label": "土默特右旗" },
            { "value": "101673", "label": "达尔罕茂明安联合旗" },
            { "value": "100905", "label": "市辖区" },
            { "value": "101583", "label": "固阳县" },
            { "value": "101148", "label": "青山区" },
            { "value": "101275", "label": "九原区" },
            { "value": "100906", "label": "东河区" },
            { "value": "101235", "label": "石拐区" },
            { "value": "101268", "label": "白云鄂博矿区" },
            { "value": "101033", "label": "昆都仑区" }
          ]
        },
        {
          "value": "107065",
          "label": "鄂尔多斯市",
          "children": [
            { "value": "107213", "label": "达拉特旗" },
            { "value": "107193", "label": "康巴什区" },
            { "value": "107388", "label": "准格尔旗" },
            { "value": "107982", "label": "伊金霍洛旗" },
            { "value": "107066", "label": "市辖区" },
            { "value": "107067", "label": "东胜区" },
            { "value": "107792", "label": "杭锦旗" },
            { "value": "107593", "label": "鄂托克前旗" },
            { "value": "107892", "label": "乌审旗" },
            { "value": "107684", "label": "鄂托克旗" }
          ]
        },
        {
          "value": "113777",
          "label": "锡林郭勒盟",
          "children": [
            { "value": "114722", "label": "正蓝旗" },
            { "value": "113796", "label": "锡林浩特市" },
            { "value": "114358", "label": "太仆寺旗" },
            { "value": "114152", "label": "东乌珠穆沁旗" },
            { "value": "113999", "label": "苏尼特左旗" },
            { "value": "114557", "label": "镶黄旗" },
            { "value": "114628", "label": "正镶白旗" },
            { "value": "114065", "label": "苏尼特右旗" },
            { "value": "114239", "label": "西乌珠穆沁旗" },
            { "value": "113912", "label": "阿巴嘎旗" },
            { "value": "113778", "label": "二连浩特市" },
            { "value": "114859", "label": "多伦县" }
          ]
        },
        {
          "value": "114940",
          "label": "阿拉善盟",
          "children": [
            { "value": "114941", "label": "阿拉善左旗" },
            { "value": "115132", "label": "阿拉善右旗" },
            { "value": "115189", "label": "额济纳旗" }
          ]
        },
        {
          "value": "108166",
          "label": "呼伦贝尔市",
          "children": [
            { "value": "109030", "label": "鄂温克族自治旗" },
            { "value": "108232", "label": "扎赉诺尔区" },
            { "value": "109113", "label": "陈巴尔虎旗" },
            { "value": "108260", "label": "阿荣旗" },
            { "value": "109813", "label": "根河市" },
            { "value": "109355", "label": "满洲里市" },
            { "value": "109205", "label": "新巴尔虎左旗" },
            { "value": "109738", "label": "额尔古纳市" },
            { "value": "109393", "label": "牙克石市" },
            { "value": "108168", "label": "海拉尔区" },
            { "value": "108483", "label": "莫力达瓦达斡尔族自治旗" },
            { "value": "109490", "label": "扎兰屯市" },
            { "value": "108785", "label": "鄂伦春自治旗" },
            { "value": "108167", "label": "市辖区" },
            { "value": "109281", "label": "新巴尔虎右旗" }
          ]
        },
        {
          "value": "112552",
          "label": "兴安盟",
          "children": [
            { "value": "112553", "label": "乌兰浩特市" },
            { "value": "113554", "label": "突泉县" },
            { "value": "112734", "label": "科尔沁右翼前旗" },
            { "value": "113294", "label": "扎赉特旗" },
            { "value": "112694", "label": "阿尔山市" },
            { "value": "113051", "label": "科尔沁右翼中旗" }
          ]
        },
        {
          "value": "104457",
          "label": "通辽市",
          "children": [
            { "value": "106150", "label": "库伦旗" },
            { "value": "105865", "label": "开鲁县" },
            { "value": "104954", "label": "科尔沁左翼中旗" },
            { "value": "107028", "label": "霍林郭勒市" },
            { "value": "106356", "label": "奈曼旗" },
            { "value": "105531", "label": "科尔沁左翼后旗" },
            { "value": "104459", "label": "科尔沁区" },
            { "value": "104458", "label": "市辖区" },
            { "value": "106746", "label": "扎鲁特旗" }
          ]
        }
      ]
    },
    {
      "value": "115224",
      "label": "辽宁省",
      "children": [
        {
          "value": "127070",
          "label": "辽阳市",
          "children": [
            { "value": "127266", "label": "弓长岭区" },
            { "value": "127071", "label": "市辖区" },
            { "value": "127218", "label": "宏伟区" },
            { "value": "127386", "label": "辽阳县" },
            { "value": "127152", "label": "文圣区" },
            { "value": "127305", "label": "太子河区" },
            { "value": "127612", "label": "灯塔市" },
            { "value": "127072", "label": "白塔区" }
          ]
        },
        {
          "value": "129977",
          "label": "朝阳市",
          "children": [
            { "value": "131425", "label": "凌源市" },
            { "value": "131089", "label": "北票市" },
            { "value": "130534", "label": "建平县" },
            { "value": "130205", "label": "朝阳县" },
            { "value": "130102", "label": "龙城区" },
            { "value": "129978", "label": "市辖区" },
            { "value": "129979", "label": "双塔区" },
            { "value": "130860", "label": "喀喇沁左翼蒙古族自治县" }
          ]
        },
        {
          "value": "122142",
          "label": "本溪市",
          "children": [
            { "value": "122444", "label": "本溪满族自治县" },
            { "value": "122143", "label": "市辖区" },
            { "value": "122310", "label": "明山区" },
            { "value": "122591", "label": "桓仁满族自治县" },
            { "value": "122144", "label": "平山区" },
            { "value": "122400", "label": "南芬区" },
            { "value": "122216", "label": "溪湖区" }
          ]
        },
        {
          "value": "131738",
          "label": "葫芦岛市",
          "children": [
            { "value": "132073", "label": "南票区" },
            { "value": "131976", "label": "龙港区" },
            { "value": "131739", "label": "市辖区" },
            { "value": "131740", "label": "连山区" },
            { "value": "132258", "label": "绥中县" },
            { "value": "132912", "label": "兴城市" },
            { "value": "132590", "label": "建昌县" }
          ]
        },
        {
          "value": "127834",
          "label": "盘锦市",
          "children": [
            { "value": "127836", "label": "双台子区" },
            { "value": "127896", "label": "兴隆台区" },
            { "value": "128037", "label": "大洼区" },
            { "value": "127835", "label": "市辖区" },
            { "value": "128231", "label": "盘山县" }
          ]
        },
        {
          "value": "119789",
          "label": "鞍山市",
          "children": [
            { "value": "120634", "label": "海城市" },
            { "value": "120221", "label": "台安县" },
            { "value": "119790", "label": "市辖区" },
            { "value": "120150", "label": "千山区" },
            { "value": "119915", "label": "铁西区" },
            { "value": "120021", "label": "立山区" },
            { "value": "119791", "label": "铁东区" },
            { "value": "120401", "label": "岫岩满族自治县" }
          ]
        },
        {
          "value": "125249",
          "label": "营口市",
          "children": [
            { "value": "125305", "label": "西市区" },
            { "value": "125250", "label": "市辖区" },
            { "value": "125353", "label": "鲅鱼圈区" },
            { "value": "125849", "label": "大石桥市" },
            { "value": "125454", "label": "老边区" },
            { "value": "125520", "label": "盖州市" },
            { "value": "125251", "label": "站前区" }
          ]
        },
        {
          "value": "123698",
          "label": "锦州市",
          "children": [
            { "value": "123869", "label": "太和区" },
            { "value": "124044", "label": "黑山县" },
            { "value": "124980", "label": "北镇市" },
            { "value": "123778", "label": "凌河区" },
            { "value": "124670", "label": "凌海市" },
            { "value": "123699", "label": "市辖区" },
            { "value": "123700", "label": "古塔区" },
            { "value": "124398", "label": "义县" }
          ]
        },
        {
          "value": "128421",
          "label": "铁岭市",
          "children": [
            { "value": "128423", "label": "银州区" },
            { "value": "128422", "label": "市辖区" },
            { "value": "129043", "label": "昌图县" },
            { "value": "129661", "label": "开原市" },
            { "value": "128591", "label": "铁岭县" },
            { "value": "129592", "label": "调兵山市" },
            { "value": "128839", "label": "西丰县" },
            { "value": "128530", "label": "清河区" }
          ]
        },
        {
          "value": "126156",
          "label": "阜新市",
          "children": [
            { "value": "126157", "label": "市辖区" },
            { "value": "126349", "label": "细河区" },
            { "value": "126845", "label": "彰武县" },
            { "value": "126314", "label": "清河门区" },
            { "value": "126236", "label": "新邱区" },
            { "value": "126264", "label": "太平区" },
            { "value": "126417", "label": "阜新蒙古族自治县" },
            { "value": "126158", "label": "海州区" }
          ]
        },
        {
          "value": "117932",
          "label": "大连市",
          "children": [
            { "value": "117933", "label": "市辖区" },
            { "value": "118884", "label": "普兰店区" },
            { "value": "117934", "label": "中山区" },
            { "value": "118523", "label": "金州区" },
            { "value": "118405", "label": "旅顺口区" },
            { "value": "118150", "label": "甘井子区" },
            { "value": "119126", "label": "瓦房店市" },
            { "value": "119090", "label": "长海县" },
            { "value": "119483", "label": "庄河市" },
            { "value": "118050", "label": "沙河口区" },
            { "value": "117997", "label": "西岗区" }
          ]
        },
        {
          "value": "122730",
          "label": "丹东市",
          "children": [
            { "value": "122731", "label": "市辖区" },
            { "value": "122732", "label": "元宝区" },
            { "value": "123438", "label": "凤城市" },
            { "value": "122886", "label": "振安区" },
            { "value": "122958", "label": "宽甸满族自治县" },
            { "value": "123177", "label": "东港市" },
            { "value": "122785", "label": "振兴区" }
          ]
        },
        {
          "value": "115225",
          "label": "沈阳市",
          "children": [
            { "value": "116614", "label": "于洪区" },
            { "value": "115227", "label": "和平区" },
            { "value": "115752", "label": "铁西区" },
            { "value": "115474", "label": "大东区" },
            { "value": "116159", "label": "浑南区" },
            { "value": "115964", "label": "苏家屯区" },
            { "value": "117268", "label": "法库县" },
            { "value": "117530", "label": "新民市" },
            { "value": "115347", "label": "沈河区" },
            { "value": "116399", "label": "沈北新区" },
            { "value": "115226", "label": "市辖区" },
            { "value": "117075", "label": "康平县" },
            { "value": "115612", "label": "皇姑区" },
            { "value": "116820", "label": "辽中区" }
          ]
        },
        {
          "value": "121081",
          "label": "抚顺市",
          "children": [
            { "value": "121181", "label": "东洲区" },
            { "value": "121082", "label": "市辖区" },
            { "value": "121083", "label": "新抚区" },
            { "value": "121710", "label": "新宾满族自治县" },
            { "value": "121326", "label": "望花区" },
            { "value": "121920", "label": "清原满族自治县" },
            { "value": "121604", "label": "抚顺县" },
            { "value": "121484", "label": "顺城区" }
          ]
        }
      ]
    },
    {
      "value": "145665",
      "label": "黑龙江省",
      "children": [
        {
          "value": "151830",
          "label": "鹤岗市",
          "children": [
            { "value": "151947", "label": "东山区" },
            { "value": "151831", "label": "市辖区" },
            { "value": "151855", "label": "工农区" },
            { "value": "151832", "label": "向阳区" },
            { "value": "152026", "label": "兴山区" },
            { "value": "151912", "label": "兴安区" },
            { "value": "151880", "label": "南山区" },
            { "value": "152047", "label": "萝北县" },
            { "value": "152237", "label": "绥滨县" }
          ]
        },
        {
          "value": "152413",
          "label": "双鸭山市",
          "children": [
            { "value": "152544", "label": "宝山区" },
            { "value": "152590", "label": "集贤县" },
            { "value": "152810", "label": "友谊县" },
            { "value": "152469", "label": "岭东区" },
            { "value": "152415", "label": "尖山区" },
            { "value": "152414", "label": "市辖区" },
            { "value": "152508", "label": "四方台区" },
            { "value": "152910", "label": "宝清县" },
            { "value": "153142", "label": "饶河县" }
          ]
        },
        {
          "value": "155076",
          "label": "佳木斯市",
          "children": [
            { "value": "155078", "label": "向阳区" },
            { "value": "155191", "label": "东风区" },
            { "value": "155254", "label": "郊区" },
            { "value": "155077", "label": "市辖区" },
            { "value": "155435", "label": "桦南县" },
            { "value": "156034", "label": "同江市" },
            { "value": "155838", "label": "汤原县" },
            { "value": "155702", "label": "桦川县" },
            { "value": "156599", "label": "抚远市" },
            { "value": "155166", "label": "前进区" },
            { "value": "156209", "label": "富锦市" }
          ]
        },
        {
          "value": "158623",
          "label": "黑河市",
          "children": [
            { "value": "158625", "label": "爱辉区" },
            { "value": "159359", "label": "北安市" },
            { "value": "159220", "label": "孙吴县" },
            { "value": "159105", "label": "逊克县" },
            { "value": "158624", "label": "市辖区" },
            { "value": "159526", "label": "五大连池市" },
            { "value": "158797", "label": "嫩江县" }
          ]
        },
        {
          "value": "161651",
          "label": "大兴安岭地区",
          "children": [
            { "value": "161754", "label": "漠河县" },
            { "value": "161652", "label": "呼玛县" },
            { "value": "161720", "label": "塔河县" }
          ]
        },
        {
          "value": "153313",
          "label": "大庆市",
          "children": [
            { "value": "153561", "label": "大同区" },
            { "value": "153659", "label": "肇州县" },
            { "value": "153513", "label": "红岗区" },
            { "value": "153423", "label": "让胡路区" },
            { "value": "153314", "label": "市辖区" },
            { "value": "153315", "label": "萨尔图区" },
            { "value": "153791", "label": "肇源县" },
            { "value": "153371", "label": "龙凤区" },
            { "value": "154082", "label": "杜尔伯特蒙古族自治县" },
            { "value": "153974", "label": "林甸县" }
          ]
        },
        {
          "value": "159769",
          "label": "绥化市",
          "children": [
            { "value": "160610", "label": "明水县" },
            { "value": "159771", "label": "北林区" },
            { "value": "160910", "label": "安达市" },
            { "value": "160271", "label": "青冈县" },
            { "value": "161331", "label": "海伦市" },
            { "value": "160478", "label": "庆安县" },
            { "value": "161079", "label": "肇东市" },
            { "value": "159770", "label": "市辖区" },
            { "value": "160744", "label": "绥棱县" },
            { "value": "160128", "label": "兰西县" },
            { "value": "159985", "label": "望奎县" }
          ]
        },
        {
          "value": "157130",
          "label": "牡丹江市",
          "children": [
            { "value": "158233", "label": "穆棱市" },
            { "value": "157170", "label": "阳明区" },
            { "value": "157132", "label": "东安区" },
            { "value": "157678", "label": "海林市" },
            { "value": "157406", "label": "林口县" },
            { "value": "157911", "label": "宁安市" },
            { "value": "157131", "label": "市辖区" },
            { "value": "157321", "label": "西安区" },
            { "value": "157646", "label": "绥芬河市" },
            { "value": "158470", "label": "东宁市" },
            { "value": "157273", "label": "爱民区" }
          ]
        },
        {
          "value": "150915",
          "label": "鸡西市",
          "children": [
            { "value": "150916", "label": "市辖区" },
            { "value": "151084", "label": "梨树区" },
            { "value": "151577", "label": "密山市" },
            { "value": "151347", "label": "虎林市" },
            { "value": "150984", "label": "恒山区" },
            { "value": "151177", "label": "鸡东县" },
            { "value": "151039", "label": "滴道区" },
            { "value": "151154", "label": "麻山区" },
            { "value": "151113", "label": "城子河区" },
            { "value": "150917", "label": "鸡冠区" }
          ]
        },
        {
          "value": "145666",
          "label": "哈尔滨市",
          "children": [
            { "value": "148755", "label": "五常市" },
            { "value": "145864", "label": "南岗区" },
            { "value": "148178", "label": "通河县" },
            { "value": "146266", "label": "平房区" },
            { "value": "146399", "label": "香坊区" },
            { "value": "146313", "label": "松北区" },
            { "value": "147884", "label": "巴彦县" },
            { "value": "146825", "label": "阿城区" },
            { "value": "145667", "label": "市辖区" },
            { "value": "148351", "label": "延寿县" },
            { "value": "145668", "label": "道里区" },
            { "value": "147032", "label": "双城区" },
            { "value": "147676", "label": "宾县" },
            { "value": "148060", "label": "木兰县" },
            { "value": "147391", "label": "依兰县" },
            { "value": "147571", "label": "方正县" },
            { "value": "146587", "label": "呼兰区" },
            { "value": "148502", "label": "尚志市" },
            { "value": "146071", "label": "道外区" }
          ]
        },
        {
          "value": "149079",
          "label": "齐齐哈尔市",
          "children": [
            { "value": "150168", "label": "克山县" },
            { "value": "150471", "label": "拜泉县" },
            { "value": "150337", "label": "克东县" },
            { "value": "149080", "label": "市辖区" },
            { "value": "149266", "label": "富拉尔基区" },
            { "value": "149404", "label": "龙江县" },
            { "value": "149129", "label": "建华区" },
            { "value": "149317", "label": "碾子山区" },
            { "value": "149767", "label": "泰来县" },
            { "value": "149896", "label": "甘南县" },
            { "value": "150680", "label": "讷河市" },
            { "value": "150046", "label": "富裕县" },
            { "value": "149231", "label": "昂昂溪区" },
            { "value": "149173", "label": "铁锋区" },
            { "value": "149338", "label": "梅里斯达斡尔族区" },
            { "value": "149588", "label": "依安县" },
            { "value": "149081", "label": "龙沙区" }
          ]
        },
        {
          "value": "154201",
          "label": "伊春市",
          "children": [
            { "value": "154793", "label": "嘉荫县" },
            { "value": "154729", "label": "红星区" },
            { "value": "154342", "label": "友好区" },
            { "value": "154203", "label": "伊春区" },
            { "value": "154579", "label": "乌马河区" },
            { "value": "154473", "label": "美溪区" },
            { "value": "154404", "label": "翠峦区" },
            { "value": "154547", "label": "五营区" },
            { "value": "154616", "label": "汤旺河区" },
            { "value": "154434", "label": "新青区" },
            { "value": "154759", "label": "上甘岭区" },
            { "value": "154659", "label": "带岭区" },
            { "value": "154511", "label": "金山屯区" },
            { "value": "154899", "label": "铁力市" },
            { "value": "154252", "label": "南岔区" },
            { "value": "154695", "label": "乌伊岭区" },
            { "value": "154202", "label": "市辖区" },
            { "value": "154389", "label": "西林区" }
          ]
        },
        {
          "value": "156713",
          "label": "七台河市",
          "children": [
            { "value": "156956", "label": "勃利县" },
            { "value": "156714", "label": "市辖区" },
            { "value": "156815", "label": "桃山区" },
            { "value": "156715", "label": "新兴区" },
            { "value": "156865", "label": "茄子河区" }
          ]
        }
      ]
    },
    {
      "value": "13267",
      "label": "河北省",
      "children": [
        {
          "value": "24371",
          "label": "秦皇岛市",
          "children": [
            { "value": "24760", "label": "山海关区" },
            { "value": "26216", "label": "卢龙县" },
            { "value": "25332", "label": "青龙满族自治县" },
            { "value": "24373", "label": "海港区" },
            { "value": "24951", "label": "抚宁区" },
            { "value": "24372", "label": "市辖区" },
            { "value": "25758", "label": "昌黎县" },
            { "value": "24891", "label": "北戴河区" }
          ]
        },
        {
          "value": "44570",
          "label": "张家口市",
          "children": [
            { "value": "45133", "label": "下花园区" },
            { "value": "45395", "label": "崇礼区" },
            { "value": "48331", "label": "涿鹿县" },
            { "value": "46619", "label": "尚义县" },
            { "value": "48731", "label": "赤城县" },
            { "value": "47396", "label": "阳原县" },
            { "value": "48013", "label": "怀来县" },
            { "value": "46371", "label": "沽源县" },
            { "value": "44634", "label": "桥西区" },
            { "value": "46018", "label": "康保县" },
            { "value": "45197", "label": "万全区" },
            { "value": "45623", "label": "张北县" },
            { "value": "46812", "label": "蔚县" },
            { "value": "44571", "label": "市辖区" },
            { "value": "44572", "label": "桥东区" },
            { "value": "44704", "label": "宣化区" },
            { "value": "47718", "label": "怀安县" }
          ]
        },
        {
          "value": "58247",
          "label": "廊坊市",
          "children": [
            { "value": "58249", "label": "安次区" },
            { "value": "60784", "label": "大厂回族自治县" },
            { "value": "61311", "label": "三河市" },
            { "value": "58789", "label": "固安县" },
            { "value": "58248", "label": "市辖区" },
            { "value": "59639", "label": "香河县" },
            { "value": "59228", "label": "永清县" },
            { "value": "59963", "label": "大城县" },
            { "value": "60378", "label": "文安县" },
            { "value": "58567", "label": "广阳区" },
            { "value": "60904", "label": "霸州市" }
          ]
        },
        {
          "value": "66813",
          "label": "直辖县",
          "children": [
            { "value": "67371", "label": "辛集市" },
            { "value": "66814", "label": "定州市" }
          ]
        },
        {
          "value": "61804",
          "label": "衡水市",
          "children": [
            { "value": "62086", "label": "冀州区" },
            { "value": "61805", "label": "市辖区" },
            { "value": "63832", "label": "饶阳县" },
            { "value": "62479", "label": "枣强县" },
            { "value": "63584", "label": "武强县" },
            { "value": "64833", "label": "景县" },
            { "value": "63050", "label": "武邑县" },
            { "value": "65702", "label": "阜城县" },
            { "value": "66323", "label": "深州市" },
            { "value": "64281", "label": "故城县" },
            { "value": "61806", "label": "桃城区" },
            { "value": "64040", "label": "安平县" }
          ]
        },
        {
          "value": "49190",
          "label": "承德市",
          "children": [
            { "value": "49425", "label": "鹰手营子矿区" },
            { "value": "49328", "label": "双滦区" },
            { "value": "49866", "label": "兴隆县" },
            { "value": "51657", "label": "围场满族蒙古族自治县" },
            { "value": "50455", "label": "滦平县" },
            { "value": "50690", "label": "隆化县" },
            { "value": "51428", "label": "宽城满族自治县" },
            { "value": "49191", "label": "市辖区" },
            { "value": "51081", "label": "丰宁满族自治县" },
            { "value": "49456", "label": "承德县" },
            { "value": "49192", "label": "双桥区" },
            { "value": "50185", "label": "平泉县" }
          ]
        },
        {
          "value": "38160",
          "label": "保定市",
          "children": [
            { "value": "40258", "label": "唐县" },
            { "value": "42550", "label": "蠡县" },
            { "value": "39736", "label": "阜平县" },
            { "value": "39960", "label": "定兴县" },
            { "value": "44124", "label": "高碑店市" },
            { "value": "43905", "label": "安国市" },
            { "value": "40632", "label": "高阳县" },
            { "value": "43054", "label": "博野县" },
            { "value": "43196", "label": "雄县" },
            { "value": "38327", "label": "莲池区" },
            { "value": "41658", "label": "易县" },
            { "value": "39082", "label": "徐水区" },
            { "value": "38162", "label": "竞秀区" },
            { "value": "40825", "label": "容城县" },
            { "value": "42802", "label": "顺平县" },
            { "value": "39426", "label": "涞水县" },
            { "value": "43441", "label": "涿州市" },
            { "value": "42155", "label": "曲阳县" },
            { "value": "38786", "label": "清苑区" },
            { "value": "41433", "label": "安新县" },
            { "value": "38161", "label": "市辖区" },
            { "value": "40961", "label": "涞源县" },
            { "value": "38578", "label": "满城区" },
            { "value": "41272", "label": "望都县" }
          ]
        },
        {
          "value": "26779",
          "label": "邯郸市",
          "children": [
            { "value": "30924", "label": "馆陶县" },
            { "value": "30747", "label": "广平县" },
            { "value": "28222", "label": "大名县" },
            { "value": "27018", "label": "复兴区" },
            { "value": "27978", "label": "成安县" },
            { "value": "30568", "label": "鸡泽县" },
            { "value": "26877", "label": "丛台区" },
            { "value": "26780", "label": "市辖区" },
            { "value": "29888", "label": "永年县" },
            { "value": "32146", "label": "武安市" },
            { "value": "27372", "label": "邯郸县" },
            { "value": "30335", "label": "邱县" },
            { "value": "31210", "label": "魏县" },
            { "value": "26781", "label": "邯山区" },
            { "value": "31793", "label": "曲周县" },
            { "value": "28894", "label": "涉县" },
            { "value": "29613", "label": "肥乡县" },
            { "value": "27538", "label": "临漳县" },
            { "value": "29226", "label": "磁县" },
            { "value": "27135", "label": "峰峰矿区" }
          ]
        },
        {
          "value": "18128",
          "label": "唐山市",
          "children": [
            { "value": "18754", "label": "开平区" },
            { "value": "18129", "label": "市辖区" },
            { "value": "20152", "label": "滦县" },
            { "value": "21825", "label": "迁西县" },
            { "value": "18130", "label": "路南区" },
            { "value": "19436", "label": "丰润区" },
            { "value": "23067", "label": "遵化市" },
            { "value": "22273", "label": "玉田县" },
            { "value": "20696", "label": "滦南县" },
            { "value": "21321", "label": "乐亭县" },
            { "value": "23787", "label": "迁安市" },
            { "value": "19987", "label": "曹妃甸区" },
            { "value": "18542", "label": "古冶区" },
            { "value": "18297", "label": "路北区" },
            { "value": "18938", "label": "丰南区" }
          ]
        },
        {
          "value": "52018",
          "label": "沧州市",
          "children": [
            { "value": "56037", "label": "泊头市" },
            { "value": "57596", "label": "河间市" },
            { "value": "55369", "label": "献县" },
            { "value": "54547", "label": "南皮县" },
            { "value": "52019", "label": "市辖区" },
            { "value": "53595", "label": "海兴县" },
            { "value": "56764", "label": "任丘市" },
            { "value": "57238", "label": "黄骅市" },
            { "value": "53810", "label": "盐山县" },
            { "value": "54875", "label": "吴桥县" },
            { "value": "55902", "label": "孟村回族自治县" },
            { "value": "52020", "label": "新华区" },
            { "value": "52753", "label": "青县" },
            { "value": "53128", "label": "东光县" },
            { "value": "52214", "label": "沧县" },
            { "value": "52086", "label": "运河区" },
            { "value": "54277", "label": "肃宁县" }
          ]
        },
        {
          "value": "32688",
          "label": "邢台市",
          "children": [
            { "value": "35718", "label": "广宗县" },
            { "value": "32689", "label": "市辖区" },
            { "value": "35940", "label": "平乡县" },
            { "value": "32795", "label": "桥西区" },
            { "value": "34139", "label": "隆尧县" },
            { "value": "37403", "label": "南宫市" },
            { "value": "35240", "label": "巨鹿县" },
            { "value": "34431", "label": "任县" },
            { "value": "32922", "label": "邢台县" },
            { "value": "36202", "label": "威县" },
            { "value": "34011", "label": "柏乡县" },
            { "value": "37086", "label": "临西县" },
            { "value": "34866", "label": "宁晋县" },
            { "value": "32690", "label": "桥东区" },
            { "value": "37883", "label": "沙河市" },
            { "value": "35542", "label": "新河县" },
            { "value": "33688", "label": "内丘县" },
            { "value": "36748", "label": "清河县" },
            { "value": "33459", "label": "临城县" },
            { "value": "34635", "label": "南和县" }
          ]
        },
        {
          "value": "13268",
          "label": "石家庄市",
          "children": [
            { "value": "17710", "label": "晋州市" },
            { "value": "14562", "label": "井陉县" },
            { "value": "14142", "label": "鹿泉区" },
            { "value": "13269", "label": "市辖区" },
            { "value": "17945", "label": "新乐市" },
            { "value": "14902", "label": "正定县" },
            { "value": "13788", "label": "裕华区" },
            { "value": "13895", "label": "藁城区" },
            { "value": "13270", "label": "长安区" },
            { "value": "13728", "label": "井陉矿区" },
            { "value": "17417", "label": "赵县" },
            { "value": "17188", "label": "元氏县" },
            { "value": "13601", "label": "新华区" },
            { "value": "15993", "label": "赞皇县" },
            { "value": "15447", "label": "灵寿县" },
            { "value": "16218", "label": "无极县" },
            { "value": "14377", "label": "栾城区" },
            { "value": "15860", "label": "深泽县" },
            { "value": "15100", "label": "行唐县" },
            { "value": "15747", "label": "高邑县" },
            { "value": "16447", "label": "平山县" },
            { "value": "13444", "label": "桥西区" }
          ]
        }
      ]
    },
    {
      "value": "714368",
      "label": "香港特别行政区",
      "children": [
        {
          "value": "714369",
          "label": "香港岛",
          "children": [
            { "value": "714371", "label": "湾仔区" },
            { "value": "714373", "label": "南区" },
            { "value": "714372", "label": "东区" },
            { "value": "714370", "label": "中西区" }
          ]
        },
        {
          "value": "714374",
          "label": "九龙",
          "children": [
            { "value": "714377", "label": "九龙城区" },
            { "value": "714376", "label": "深水埗区" },
            { "value": "714375", "label": "油尖旺区" },
            { "value": "714378", "label": "黄大仙区" },
            { "value": "714379", "label": "观塘区" }
          ]
        },
        {
          "value": "714380",
          "label": "新界",
          "children": [
            { "value": "714382", "label": "屯门区" },
            { "value": "714383", "label": "元朗区" },
            { "value": "714387", "label": "沙田区" },
            { "value": "714385", "label": "大埔区" },
            { "value": "714384", "label": "北区" },
            { "value": "714388", "label": "葵青区" },
            { "value": "714381", "label": "荃湾区" },
            { "value": "714389", "label": "离岛区" },
            { "value": "714386", "label": "西贡区" }
          ]
        }
      ]
    },
    {
      "value": "452123",
      "label": "湖南省",
      "children": [
        {
          "value": "456303",
          "label": "衡阳市",
          "children": [
            { "value": "457104", "label": "衡南县" },
            { "value": "456502", "label": "蒸湘区" },
            { "value": "456305", "label": "珠晖区" },
            { "value": "456304", "label": "市辖区" },
            { "value": "456587", "label": "衡阳县" },
            { "value": "458820", "label": "常宁市" },
            { "value": "457576", "label": "衡山县" },
            { "value": "457742", "label": "衡东县" },
            { "value": "458018", "label": "祁东县" },
            { "value": "456449", "label": "石鼓区" },
            { "value": "456558", "label": "南岳区" },
            { "value": "456391", "label": "雁峰区" },
            { "value": "458411", "label": "耒阳市" }
          ]
        },
        {
          "value": "468660",
          "label": "益阳市",
          "children": [
            { "value": "468662", "label": "资阳区" },
            { "value": "468661", "label": "市辖区" },
            { "value": "469909", "label": "沅江市" },
            { "value": "469452", "label": "安化县" },
            { "value": "469192", "label": "桃江县" },
            { "value": "468790", "label": "赫山区" },
            { "value": "469015", "label": "南县" }
          ]
        },
        {
          "value": "465105",
          "label": "常德市",
          "children": [
            { "value": "465107", "label": "武陵区" },
            { "value": "465661", "label": "安乡县" },
            { "value": "465106", "label": "市辖区" },
            { "value": "466654", "label": "桃源县" },
            { "value": "465844", "label": "汉寿县" },
            { "value": "466173", "label": "澧县" },
            { "value": "465295", "label": "鼎城区" },
            { "value": "466483", "label": "临澧县" },
            { "value": "467112", "label": "石门县" },
            { "value": "467471", "label": "津市市" }
          ]
        },
        {
          "value": "472709",
          "label": "永州市",
          "children": [
            { "value": "472711", "label": "零陵区" },
            { "value": "474233", "label": "双牌县" },
            { "value": "475819", "label": "江华瑶族自治县" },
            { "value": "474761", "label": "江永县" },
            { "value": "475574", "label": "新田县" },
            { "value": "475326", "label": "蓝山县" },
            { "value": "472710", "label": "市辖区" },
            { "value": "474367", "label": "道县" },
            { "value": "473284", "label": "祁阳县" },
            { "value": "473871", "label": "东安县" },
            { "value": "473062", "label": "冷水滩区" },
            { "value": "474883", "label": "宁远县" }
          ]
        },
        {
          "value": "470123",
          "label": "郴州市",
          "children": [
            { "value": "470894", "label": "宜章县" },
            { "value": "472174", "label": "桂东县" },
            { "value": "470300", "label": "苏仙区" },
            { "value": "470125", "label": "北湖区" },
            { "value": "471908", "label": "汝城县" },
            { "value": "472301", "label": "安仁县" },
            { "value": "471178", "label": "永兴县" },
            { "value": "471685", "label": "临武县" },
            { "value": "472477", "label": "资兴市" },
            { "value": "471473", "label": "嘉禾县" },
            { "value": "470124", "label": "市辖区" },
            { "value": "470500", "label": "桂阳县" }
          ]
        },
        {
          "value": "463141",
          "label": "岳阳市",
          "children": [
            { "value": "464944", "label": "临湘市" },
            { "value": "463961", "label": "湘阴县" },
            { "value": "463142", "label": "市辖区" },
            { "value": "463722", "label": "华容县" },
            { "value": "464747", "label": "汨罗市" },
            { "value": "464180", "label": "平江县" },
            { "value": "463489", "label": "岳阳县" },
            { "value": "463143", "label": "岳阳楼区" },
            { "value": "463397", "label": "君山区" },
            { "value": "463333", "label": "云溪区" }
          ]
        },
        {
          "value": "481319",
          "label": "湘西土家族苗族自治州",
          "children": [
            { "value": "481465", "label": "泸溪县" },
            { "value": "482831", "label": "龙山县" },
            { "value": "481626", "label": "凤凰县" },
            { "value": "481925", "label": "花垣县" },
            { "value": "481320", "label": "吉首市" },
            { "value": "482506", "label": "永顺县" },
            { "value": "482377", "label": "古丈县" },
            { "value": "482179", "label": "保靖县" }
          ]
        },
        {
          "value": "452124",
          "label": "长沙市",
          "children": [
            { "value": "452126", "label": "芙蓉区" },
            { "value": "452486", "label": "开福区" },
            { "value": "452213", "label": "天心区" },
            { "value": "452610", "label": "雨花区" },
            { "value": "453140", "label": "宁乡县" },
            { "value": "452771", "label": "望城区" },
            { "value": "453444", "label": "浏阳市" },
            { "value": "452125", "label": "市辖区" },
            { "value": "452924", "label": "长沙县" },
            { "value": "452314", "label": "岳麓区" }
          ]
        },
        {
          "value": "453799",
          "label": "株洲市",
          "children": [
            { "value": "454113", "label": "株洲县" },
            { "value": "453801", "label": "荷塘区" },
            { "value": "453867", "label": "芦淞区" },
            { "value": "453800", "label": "市辖区" },
            { "value": "454008", "label": "天元区" },
            { "value": "454845", "label": "炎陵县" },
            { "value": "454580", "label": "茶陵县" },
            { "value": "454989", "label": "醴陵市" },
            { "value": "454261", "label": "攸县" },
            { "value": "453957", "label": "石峰区" }
          ]
        },
        {
          "value": "467562",
          "label": "张家界市",
          "children": [
            { "value": "468338", "label": "桑植县" },
            { "value": "467564", "label": "永定区" },
            { "value": "467881", "label": "慈利县" },
            { "value": "467563", "label": "市辖区" },
            { "value": "467841", "label": "武陵源区" }
          ]
        },
        {
          "value": "476148",
          "label": "怀化市",
          "children": [
            { "value": "477895", "label": "麻阳苗族自治县" },
            { "value": "476874", "label": "辰溪县" },
            { "value": "476150", "label": "鹤城区" },
            { "value": "478685", "label": "通道侗族自治县" },
            { "value": "477633", "label": "会同县" },
            { "value": "477191", "label": "溆浦县" },
            { "value": "478135", "label": "新晃侗族自治县" },
            { "value": "478299", "label": "芷江侗族自治县" },
            { "value": "478536", "label": "靖州苗族侗族自治县" },
            { "value": "476449", "label": "沅陵县" },
            { "value": "476288", "label": "中方县" },
            { "value": "478864", "label": "洪江市" },
            { "value": "476149", "label": "市辖区" }
          ]
        },
        {
          "value": "459251",
          "label": "邵阳市",
          "children": [
            { "value": "462257", "label": "新宁县" },
            { "value": "460558", "label": "邵阳县" },
            { "value": "462600", "label": "城步苗族自治县" },
            { "value": "462000", "label": "绥宁县" },
            { "value": "459357", "label": "大祥区" },
            { "value": "459517", "label": "邵东县" },
            { "value": "460126", "label": "新邵县" },
            { "value": "461613", "label": "洞口县" },
            { "value": "459252", "label": "市辖区" },
            { "value": "459470", "label": "北塔区" },
            { "value": "462807", "label": "武冈市" },
            { "value": "459253", "label": "双清区" },
            { "value": "461014", "label": "隆回县" }
          ]
        },
        {
          "value": "479103",
          "label": "娄底市",
          "children": [
            { "value": "479105", "label": "娄星区" },
            { "value": "479285", "label": "双峰县" },
            { "value": "480606", "label": "冷水江市" },
            { "value": "479889", "label": "新化县" },
            { "value": "480767", "label": "涟源市" },
            { "value": "479104", "label": "市辖区" }
          ]
        },
        {
          "value": "455283",
          "label": "湘潭市",
          "children": [
            { "value": "455285", "label": "雨湖区" },
            { "value": "455515", "label": "湘潭县" },
            { "value": "455889", "label": "湘乡市" },
            { "value": "456260", "label": "韶山市" },
            { "value": "455426", "label": "岳塘区" },
            { "value": "455284", "label": "市辖区" }
          ]
        }
      ]
    },
    {
      "value": "690094",
      "label": "青海省",
      "children": [
        {
          "value": "694478",
          "label": "玉树藏族自治州",
          "children": [
            { "value": "694695", "label": "治多县" },
            { "value": "694479", "label": "玉树市" },
            { "value": "694572", "label": "杂多县" },
            { "value": "694622", "label": "称多县" },
            { "value": "694728", "label": "囊谦县" },
            { "value": "694816", "label": "曲麻莱县" }
          ]
        },
        {
          "value": "693040",
          "label": "海北藏族自治州",
          "children": [
            { "value": "693186", "label": "祁连县" },
            { "value": "693286", "label": "刚察县" },
            { "value": "693041", "label": "门源回族自治县" },
            { "value": "693243", "label": "海晏县" }
          ]
        },
        {
          "value": "693672",
          "label": "海南藏族自治州",
          "children": [
            { "value": "693808", "label": "同德县" },
            { "value": "694121", "label": "贵南县" },
            { "value": "693673", "label": "共和县" },
            { "value": "693906", "label": "贵德县" },
            { "value": "694046", "label": "兴海县" }
          ]
        },
        {
          "value": "690095",
          "label": "西宁市",
          "children": [
            { "value": "690096", "label": "市辖区" },
            { "value": "690164", "label": "城中区" },
            { "value": "691114", "label": "湟源县" },
            { "value": "690097", "label": "城东区" },
            { "value": "690357", "label": "大通回族土族自治县" },
            { "value": "690283", "label": "城北区" },
            { "value": "690235", "label": "城西区" },
            { "value": "690689", "label": "湟中县" }
          ]
        },
        {
          "value": "691278",
          "label": "海东市",
          "children": [
            { "value": "692148", "label": "互助土族自治县" },
            { "value": "692479", "label": "化隆回族自治县" },
            { "value": "691796", "label": "民和回族土族自治县" },
            { "value": "692870", "label": "循化撒拉族自治县" },
            { "value": "691667", "label": "平安区" },
            { "value": "691279", "label": "乐都区" }
          ]
        },
        {
          "value": "694220",
          "label": "果洛藏族自治州",
          "children": [
            { "value": "694221", "label": "玛沁县" },
            { "value": "694272", "label": "班玛县" },
            { "value": "694361", "label": "达日县" },
            { "value": "694440", "label": "玛多县" },
            { "value": "694315", "label": "甘德县" },
            { "value": "694407", "label": "久治县" }
          ]
        },
        {
          "value": "693342",
          "label": "黄南藏族自治州",
          "children": [
            { "value": "693437", "label": "尖扎县" },
            { "value": "693343", "label": "同仁县" },
            { "value": "693623", "label": "河南蒙古族自治县" },
            { "value": "693541", "label": "泽库县" }
          ]
        },
        {
          "value": "694846",
          "label": "海西蒙古族藏族自治州",
          "children": [
            { "value": "694932", "label": "德令哈市" },
            { "value": "695009", "label": "乌兰县" },
            { "value": "695184", "label": "天峻县" },
            { "value": "695060", "label": "都兰县" },
            { "value": "694847", "label": "格尔木市" }
          ]
        }
      ]
    },
    {
      "value": "167904",
      "label": "江苏省",
      "children": [
        {
          "value": "169375",
          "label": "无锡市",
          "children": [
            { "value": "169751", "label": "梁溪区" },
            { "value": "170064", "label": "江阴市" },
            { "value": "169377", "label": "锡山区" },
            { "value": "169924", "label": "新吴区" },
            { "value": "169628", "label": "滨湖区" },
            { "value": "169507", "label": "惠山区" },
            { "value": "170390", "label": "宜兴市" },
            { "value": "169376", "label": "市辖区" }
          ]
        },
        {
          "value": "180743",
          "label": "淮安市",
          "children": [
            { "value": "180745", "label": "淮安区" },
            { "value": "180744", "label": "市辖区" },
            { "value": "181547", "label": "洪泽区" },
            { "value": "182092", "label": "盱眙县" },
            { "value": "181681", "label": "涟水县" },
            { "value": "182372", "label": "金湖县" },
            { "value": "181117", "label": "淮阴区" },
            { "value": "181401", "label": "清江浦区" }
          ]
        },
        {
          "value": "176920",
          "label": "南通市",
          "children": [
            { "value": "177119", "label": "通州区" },
            { "value": "176922", "label": "崇川区" },
            { "value": "178669", "label": "海门市" },
            { "value": "177040", "label": "港闸区" },
            { "value": "178291", "label": "如皋市" },
            { "value": "177665", "label": "如东县" },
            { "value": "177415", "label": "海安县" },
            { "value": "177946", "label": "启东市" },
            { "value": "176921", "label": "市辖区" }
          ]
        },
        {
          "value": "174764",
          "label": "苏州市",
          "children": [
            { "value": "174872", "label": "吴中区" },
            { "value": "176120", "label": "张家港市" },
            { "value": "175765", "label": "常熟市" },
            { "value": "174765", "label": "市辖区" },
            { "value": "175247", "label": "姑苏区" },
            { "value": "174766", "label": "虎丘区" },
            { "value": "176424", "label": "昆山市" },
            { "value": "175444", "label": "吴江区" },
            { "value": "176763", "label": "太仓市" },
            { "value": "175088", "label": "相城区" }
          ]
        },
        {
          "value": "173635",
          "label": "常州市",
          "children": [
            { "value": "173773", "label": "钟楼区" },
            { "value": "174347", "label": "金坛区" },
            { "value": "174038", "label": "武进区" },
            { "value": "173636", "label": "市辖区" },
            { "value": "174508", "label": "溧阳市" },
            { "value": "173891", "label": "新北区" },
            { "value": "173637", "label": "天宁区" }
          ]
        },
        {
          "value": "178999",
          "label": "连云港市",
          "children": [
            { "value": "180493", "label": "灌南县" },
            { "value": "179786", "label": "东海县" },
            { "value": "179001", "label": "连云区" },
            { "value": "179081", "label": "海州区" },
            { "value": "179296", "label": "赣榆区" },
            { "value": "179000", "label": "市辖区" },
            { "value": "180136", "label": "灌云县" }
          ]
        },
        {
          "value": "182538",
          "label": "盐城市",
          "children": [
            { "value": "184748", "label": "东台市" },
            { "value": "184485", "label": "建湖县" },
            { "value": "183334", "label": "响水县" },
            { "value": "184188", "label": "射阳县" },
            { "value": "183829", "label": "阜宁县" },
            { "value": "182540", "label": "亭湖区" },
            { "value": "183510", "label": "滨海县" },
            { "value": "182762", "label": "盐都区" },
            { "value": "183048", "label": "大丰区" },
            { "value": "182539", "label": "市辖区" }
          ]
        },
        {
          "value": "187441",
          "label": "泰州市",
          "children": [
            { "value": "187443", "label": "海陵区" },
            { "value": "187442", "label": "市辖区" },
            { "value": "189026", "label": "泰兴市" },
            { "value": "188035", "label": "兴化市" },
            { "value": "187593", "label": "高港区" },
            { "value": "187693", "label": "姜堰区" },
            { "value": "188752", "label": "靖江市" }
          ]
        },
        {
          "value": "189402",
          "label": "宿迁市",
          "children": [
            { "value": "189637", "label": "宿豫区" },
            { "value": "189404", "label": "宿城区" },
            { "value": "190377", "label": "泗阳县" },
            { "value": "189403", "label": "市辖区" },
            { "value": "190642", "label": "泗洪县" },
            { "value": "189850", "label": "沭阳县" }
          ]
        },
        {
          "value": "170724",
          "label": "徐州市",
          "children": [
            { "value": "170800", "label": "云龙区" },
            { "value": "170886", "label": "贾汪区" },
            { "value": "171049", "label": "泉山区" },
            { "value": "172381", "label": "睢宁县" },
            { "value": "170725", "label": "市辖区" },
            { "value": "171182", "label": "铜山区" },
            { "value": "170726", "label": "鼓楼区" },
            { "value": "173105", "label": "邳州市" },
            { "value": "171966", "label": "沛县" },
            { "value": "172804", "label": "新沂市" },
            { "value": "171537", "label": "丰县" }
          ]
        },
        {
          "value": "186657",
          "label": "镇江市",
          "children": [
            { "value": "187235", "label": "句容市" },
            { "value": "186919", "label": "丹阳市" },
            { "value": "186817", "label": "丹徒区" },
            { "value": "187149", "label": "扬中市" },
            { "value": "186659", "label": "京口区" },
            { "value": "186658", "label": "市辖区" },
            { "value": "186734", "label": "润州区" }
          ]
        },
        {
          "value": "185201",
          "label": "扬州市",
          "children": [
            { "value": "185202", "label": "市辖区" },
            { "value": "186414", "label": "高邮市" },
            { "value": "185203", "label": "广陵区" },
            { "value": "186203", "label": "仪征市" },
            { "value": "185360", "label": "邗江区" },
            { "value": "185555", "label": "江都区" },
            { "value": "185905", "label": "宝应县" }
          ]
        },
        {
          "value": "167905",
          "label": "南京市",
          "children": [
            { "value": "167906", "label": "市辖区" },
            { "value": "167907", "label": "玄武区" },
            { "value": "168604", "label": "雨花台区" },
            { "value": "169096", "label": "溧水区" },
            { "value": "168104", "label": "建邺区" },
            { "value": "168925", "label": "六合区" },
            { "value": "167974", "label": "秦淮区" },
            { "value": "168310", "label": "浦口区" },
            { "value": "168174", "label": "鼓楼区" },
            { "value": "168704", "label": "江宁区" },
            { "value": "169214", "label": "高淳区" },
            { "value": "168451", "label": "栖霞区" }
          ]
        }
      ]
    },
    {
      "value": "698440",
      "label": "新疆维吾尔自治区",
      "children": [
        {
          "value": "702104",
          "label": "巴音郭楞蒙古自治州",
          "children": [
            { "value": "702573", "label": "若羌县" },
            { "value": "702357", "label": "轮台县" },
            { "value": "702634", "label": "且末县" },
            { "value": "702447", "label": "尉犁县" },
            { "value": "702727", "label": "焉耆回族自治县" },
            { "value": "702821", "label": "和静县" },
            { "value": "702995", "label": "和硕县" },
            { "value": "702105", "label": "库尔勒市" },
            { "value": "703063", "label": "博湖县" }
          ]
        },
        {
          "value": "711218",
          "label": "塔城地区",
          "children": [
            { "value": "712777", "label": "和布克赛尔蒙古自治县" },
            { "value": "712103", "label": "沙湾县" },
            { "value": "711436", "label": "乌苏市" },
            { "value": "712700", "label": "裕民县" },
            { "value": "711820", "label": "额敏县" },
            { "value": "712601", "label": "托里县" },
            { "value": "711219", "label": "塔城市" }
          ]
        },
        {
          "value": "705145",
          "label": "喀什地区",
          "children": [
            { "value": "707806", "label": "巴楚县" },
            { "value": "708055", "label": "塔什库尔干塔吉克自治县" },
            { "value": "707143", "label": "麦盖提县" },
            { "value": "705961", "label": "泽普县" },
            { "value": "706753", "label": "叶城县" },
            { "value": "705764", "label": "英吉沙县" },
            { "value": "705146", "label": "喀什市" },
            { "value": "705344", "label": "疏附县" },
            { "value": "707342", "label": "岳普湖县" },
            { "value": "707464", "label": "伽师县" },
            { "value": "706133", "label": "莎车县" },
            { "value": "705496", "label": "疏勒县" }
          ]
        },
        {
          "value": "699773",
          "label": "吐鲁番市",
          "children": [
            { "value": "700000", "label": "托克逊县" },
            { "value": "699884", "label": "鄯善县" },
            { "value": "699774", "label": "高昌区" }
          ]
        },
        {
          "value": "709792",
          "label": "伊犁哈萨克自治州",
          "children": [
            { "value": "709978", "label": "奎屯市" },
            { "value": "709793", "label": "伊宁市" },
            { "value": "710275", "label": "察布查尔锡伯自治县" },
            { "value": "711013", "label": "特克斯县" },
            { "value": "710868", "label": "昭苏县" },
            { "value": "710057", "label": "霍尔果斯市" },
            { "value": "711105", "label": "尼勒克县" },
            { "value": "710410", "label": "霍城县" },
            { "value": "710730", "label": "新源县" },
            { "value": "710612", "label": "巩留县" },
            { "value": "710090", "label": "伊宁县" }
          ]
        },
        {
          "value": "699583",
          "label": "克拉玛依市",
          "children": [
            { "value": "699730", "label": "白碱滩区" },
            { "value": "699752", "label": "乌尔禾区" },
            { "value": "699585", "label": "独山子区" },
            { "value": "699584", "label": "市辖区" },
            { "value": "699606", "label": "克拉玛依区" }
          ]
        },
        {
          "value": "704779",
          "label": "克孜勒苏柯尔克孜自治州",
          "children": [
            { "value": "705084", "label": "乌恰县" },
            { "value": "705047", "label": "阿合奇县" },
            { "value": "704780", "label": "阿图什市" },
            { "value": "704893", "label": "阿克陶县" }
          ]
        },
        {
          "value": "700489",
          "label": "昌吉回族自治州",
          "children": [
            { "value": "701474", "label": "木垒哈萨克自治县" },
            { "value": "701375", "label": "吉木萨尔县" },
            { "value": "700805", "label": "呼图壁县" },
            { "value": "700490", "label": "昌吉市" },
            { "value": "701230", "label": "奇台县" },
            { "value": "700956", "label": "玛纳斯县" },
            { "value": "700685", "label": "阜康市" }
          ]
        },
        {
          "value": "698441",
          "label": "乌鲁木齐市",
          "children": [
            { "value": "699313", "label": "达坂城区" },
            { "value": "699363", "label": "米东区" },
            { "value": "699054", "label": "水磨沟区" },
            { "value": "699535", "label": "乌鲁木齐县" },
            { "value": "698443", "label": "天山区" },
            { "value": "698442", "label": "市辖区" },
            { "value": "698884", "label": "新市区" },
            { "value": "699229", "label": "头屯河区" },
            { "value": "698654", "label": "沙依巴克区" }
          ]
        },
        {
          "value": "701559",
          "label": "博尔塔拉蒙古自治州",
          "children": [
            { "value": "701839", "label": "精河县" },
            { "value": "701560", "label": "博乐市" },
            { "value": "701834", "label": "阿拉山口市" },
            { "value": "701978", "label": "温泉县" }
          ]
        },
        {
          "value": "703119",
          "label": "阿克苏地区",
          "children": [
            { "value": "704209", "label": "拜城县" },
            { "value": "704413", "label": "乌什县" },
            { "value": "704059", "label": "新和县" },
            { "value": "703120", "label": "阿克苏市" },
            { "value": "704556", "label": "阿瓦提县" },
            { "value": "703576", "label": "库车县" },
            { "value": "703858", "label": "沙雅县" },
            { "value": "704733", "label": "柯坪县" },
            { "value": "703353", "label": "温宿县" }
          ]
        },
        {
          "value": "700070",
          "label": "哈密市",
          "children": [
            { "value": "700350", "label": "巴里坤哈萨克自治县" },
            { "value": "700071", "label": "伊州区" },
            { "value": "700438", "label": "伊吾县" }
          ]
        },
        {
          "value": "708117",
          "label": "和田地区",
          "children": [
            { "value": "709538", "label": "于田县" },
            { "value": "708940", "label": "皮山县" },
            { "value": "708305", "label": "和田县" },
            { "value": "709155", "label": "洛浦县" },
            { "value": "709393", "label": "策勒县" },
            { "value": "709746", "label": "民丰县" },
            { "value": "708118", "label": "和田市" },
            { "value": "708528", "label": "墨玉县" }
          ]
        },
        {
          "value": "712893",
          "label": "阿勒泰地区",
          "children": [
            { "value": "713568", "label": "青河县" },
            { "value": "713635", "label": "吉木乃县" },
            { "value": "713161", "label": "富蕴县" },
            { "value": "712894", "label": "阿勒泰市" },
            { "value": "713426", "label": "哈巴河县" },
            { "value": "713261", "label": "福海县" },
            { "value": "713072", "label": "布尔津县" }
          ]
        },
        {
          "value": "713697",
          "label": "直辖县",
          "children": [
            { "value": "714363", "label": "铁门关市" },
            { "value": "713842", "label": "阿拉尔市" },
            { "value": "714172", "label": "图木舒克市" },
            { "value": "714310", "label": "五家渠市" },
            { "value": "713698", "label": "石河子市" }
          ]
        }
      ]
    },
    {
      "value": "224649",
      "label": "安徽省",
      "children": [
        {
          "value": "242634",
          "label": "池州市",
          "children": [
            { "value": "243254", "label": "青阳县" },
            { "value": "243159", "label": "石台县" },
            { "value": "242636", "label": "贵池区" },
            { "value": "242888", "label": "东至县" },
            { "value": "242635", "label": "市辖区" }
          ]
        },
        {
          "value": "243399",
          "label": "宣城市",
          "children": [
            { "value": "244232", "label": "宁国市" },
            { "value": "243753", "label": "广德县" },
            { "value": "243401", "label": "宣州区" },
            { "value": "244153", "label": "旌德县" },
            { "value": "244058", "label": "绩溪县" },
            { "value": "243636", "label": "郎溪县" },
            { "value": "243400", "label": "市辖区" },
            { "value": "243902", "label": "泾县" }
          ]
        },
        {
          "value": "226469",
          "label": "芜湖市",
          "children": [
            { "value": "226786", "label": "芜湖县" },
            { "value": "226735", "label": "三山区" },
            { "value": "226550", "label": "弋江区" },
            { "value": "226470", "label": "市辖区" },
            { "value": "226891", "label": "繁昌县" },
            { "value": "226995", "label": "南陵县" },
            { "value": "226612", "label": "鸠江区" },
            { "value": "227183", "label": "无为县" },
            { "value": "226471", "label": "镜湖区" }
          ]
        },
        {
          "value": "231499",
          "label": "安庆市",
          "children": [
            { "value": "232781", "label": "岳西县" },
            { "value": "232996", "label": "桐城市" },
            { "value": "232631", "label": "望江县" },
            { "value": "231710", "label": "怀宁县" },
            { "value": "231500", "label": "市辖区" },
            { "value": "232386", "label": "宿松县" },
            { "value": "231501", "label": "迎江区" },
            { "value": "231974", "label": "潜山县" },
            { "value": "232180", "label": "太湖县" },
            { "value": "231558", "label": "大观区" },
            { "value": "231646", "label": "宜秀区" }
          ]
        },
        {
          "value": "228630",
          "label": "淮南市",
          "children": [
            { "value": "228631", "label": "市辖区" },
            { "value": "229221", "label": "凤台县" },
            { "value": "228853", "label": "谢家集区" },
            { "value": "228632", "label": "大通区" },
            { "value": "228960", "label": "八公山区" },
            { "value": "229029", "label": "潘集区" },
            { "value": "229527", "label": "寿县" },
            { "value": "228710", "label": "田家庵区" }
          ]
        },
        {
          "value": "237607",
          "label": "宿州市",
          "children": [
            { "value": "238533", "label": "灵璧县" },
            { "value": "237609", "label": "埇桥区" },
            { "value": "238207", "label": "萧县" },
            { "value": "238872", "label": "泗县" },
            { "value": "238022", "label": "砀山县" },
            { "value": "237608", "label": "市辖区" }
          ]
        },
        {
          "value": "230981",
          "label": "铜陵市",
          "children": [
            { "value": "231171", "label": "郊区" },
            { "value": "231043", "label": "义安区" },
            { "value": "230983", "label": "铜官区" },
            { "value": "230982", "label": "市辖区" },
            { "value": "231216", "label": "枞阳县" }
          ]
        },
        {
          "value": "227482",
          "label": "蚌埠市",
          "children": [
            { "value": "227677", "label": "淮上区" },
            { "value": "227598", "label": "禹会区" },
            { "value": "228155", "label": "五河县" },
            { "value": "227769", "label": "怀远县" },
            { "value": "227484", "label": "龙子湖区" },
            { "value": "228388", "label": "固镇县" },
            { "value": "227483", "label": "市辖区" },
            { "value": "227551", "label": "蚌山区" }
          ]
        },
        {
          "value": "224650",
          "label": "合肥市",
          "children": [
            { "value": "226249", "label": "巢湖市" },
            { "value": "225705", "label": "肥西县" },
            { "value": "225062", "label": "长丰县" },
            { "value": "224652", "label": "瑶海区" },
            { "value": "224838", "label": "蜀山区" },
            { "value": "224651", "label": "市辖区" },
            { "value": "225998", "label": "庐江县" },
            { "value": "224946", "label": "包河区" },
            { "value": "225352", "label": "肥东县" },
            { "value": "224755", "label": "庐阳区" }
          ]
        },
        {
          "value": "229844",
          "label": "马鞍山市",
          "children": [
            { "value": "230213", "label": "含山县" },
            { "value": "230345", "label": "和县" },
            { "value": "229922", "label": "雨山区" },
            { "value": "229845", "label": "市辖区" },
            { "value": "229992", "label": "博望区" },
            { "value": "230041", "label": "当涂县" },
            { "value": "229846", "label": "花山区" }
          ]
        },
        {
          "value": "235483",
          "label": "阜阳市",
          "children": [
            { "value": "236338", "label": "太和县" },
            { "value": "235909", "label": "临泉县" },
            { "value": "236685", "label": "阜南县" },
            { "value": "235484", "label": "市辖区" },
            { "value": "237046", "label": "颍上县" },
            { "value": "235646", "label": "颍东区" },
            { "value": "235485", "label": "颍州区" },
            { "value": "237428", "label": "界首市" },
            { "value": "235778", "label": "颍泉区" }
          ]
        },
        {
          "value": "241180",
          "label": "亳州市",
          "children": [
            { "value": "241182", "label": "谯城区" },
            { "value": "241927", "label": "蒙城县" },
            { "value": "241181", "label": "市辖区" },
            { "value": "242245", "label": "利辛县" },
            { "value": "241517", "label": "涡阳县" }
          ]
        },
        {
          "value": "239084",
          "label": "六安市",
          "children": [
            { "value": "239834", "label": "霍邱县" },
            { "value": "241016", "label": "霍山县" },
            { "value": "240310", "label": "舒城县" },
            { "value": "239450", "label": "裕安区" },
            { "value": "239085", "label": "市辖区" },
            { "value": "239780", "label": "叶集区" },
            { "value": "239086", "label": "金安区" },
            { "value": "240759", "label": "金寨县" }
          ]
        },
        {
          "value": "233236",
          "label": "黄山市",
          "children": [
            { "value": "233714", "label": "休宁县" },
            { "value": "233973", "label": "祁门县" },
            { "value": "233238", "label": "屯溪区" },
            { "value": "233487", "label": "歙县" },
            { "value": "233237", "label": "市辖区" },
            { "value": "233423", "label": "徽州区" },
            { "value": "233894", "label": "黟县" },
            { "value": "233321", "label": "黄山区" }
          ]
        },
        {
          "value": "234109",
          "label": "滁州市",
          "children": [
            { "value": "234111", "label": "琅琊区" },
            { "value": "234434", "label": "全椒县" },
            { "value": "234110", "label": "市辖区" },
            { "value": "234560", "label": "定远县" },
            { "value": "235305", "label": "明光市" },
            { "value": "235112", "label": "天长市" },
            { "value": "234847", "label": "凤阳县" },
            { "value": "234274", "label": "来安县" },
            { "value": "234174", "label": "南谯区" }
          ]
        },
        {
          "value": "230476",
          "label": "淮北市",
          "children": [
            { "value": "230648", "label": "烈山区" },
            { "value": "230478", "label": "杜集区" },
            { "value": "230477", "label": "市辖区" },
            { "value": "230720", "label": "濉溪县" },
            { "value": "230539", "label": "相山区" }
          ]
        }
      ]
    },
    {
      "value": "671119",
      "label": "甘肃省",
      "children": [
        {
          "value": "687916",
          "label": "临夏回族自治州",
          "children": [
            { "value": "688701", "label": "和政县" },
            { "value": "687917", "label": "临夏市" },
            { "value": "687999", "label": "临夏县" },
            { "value": "688588", "label": "广河县" },
            { "value": "688417", "label": "永靖县" },
            { "value": "689096", "label": "积石山保安族东乡族撒拉族自治县" },
            { "value": "688841", "label": "东乡族自治县" },
            { "value": "688248", "label": "康乐县" }
          ]
        },
        {
          "value": "672385",
          "label": "金昌市",
          "children": [
            { "value": "672387", "label": "金川区" },
            { "value": "672439", "label": "永昌县" },
            { "value": "672386", "label": "市辖区" }
          ]
        },
        {
          "value": "689265",
          "label": "甘南藏族自治州",
          "children": [
            { "value": "690005", "label": "夏河县" },
            { "value": "689966", "label": "碌曲县" },
            { "value": "689845", "label": "迭部县" },
            { "value": "689327", "label": "临潭县" },
            { "value": "689488", "label": "卓尼县" },
            { "value": "689266", "label": "合作市" },
            { "value": "689610", "label": "舟曲县" },
            { "value": "689913", "label": "玛曲县" }
          ]
        },
        {
          "value": "684415",
          "label": "陇南市",
          "children": [
            { "value": "686933", "label": "礼县" },
            { "value": "687542", "label": "徽县" },
            { "value": "684417", "label": "武都区" },
            { "value": "686518", "label": "西和县" },
            { "value": "685162", "label": "成县" },
            { "value": "685439", "label": "文县" },
            { "value": "686138", "label": "康县" },
            { "value": "685772", "label": "宕昌县" },
            { "value": "684416", "label": "市辖区" },
            { "value": "687781", "label": "两当县" }
          ]
        },
        {
          "value": "672577",
          "label": "白银市",
          "children": [
            { "value": "673307", "label": "景泰县" },
            { "value": "672579", "label": "白银区" },
            { "value": "672670", "label": "平川区" },
            { "value": "672773", "label": "靖远县" },
            { "value": "672578", "label": "市辖区" },
            { "value": "672978", "label": "会宁县" }
          ]
        },
        {
          "value": "680815",
          "label": "庆阳市",
          "children": [
            { "value": "680943", "label": "庆城县" },
            { "value": "681132", "label": "环县" },
            { "value": "680816", "label": "市辖区" },
            { "value": "681559", "label": "合水县" },
            { "value": "682081", "label": "镇原县" },
            { "value": "681414", "label": "华池县" },
            { "value": "680817", "label": "西峰区" },
            { "value": "681665", "label": "正宁县" },
            { "value": "681784", "label": "宁县" }
          ]
        },
        {
          "value": "676215",
          "label": "武威市",
          "children": [
            { "value": "676216", "label": "市辖区" },
            { "value": "676217", "label": "凉州区" },
            { "value": "676751", "label": "民勤县" },
            { "value": "677344", "label": "天祝藏族自治县" },
            { "value": "677055", "label": "古浪县" }
          ]
        },
        {
          "value": "673463",
          "label": "天水市",
          "children": [
            { "value": "675568", "label": "武山县" },
            { "value": "675938", "label": "张家川回族自治县" },
            { "value": "673952", "label": "麦积区" },
            { "value": "675138", "label": "甘谷县" },
            { "value": "674684", "label": "秦安县" },
            { "value": "674399", "label": "清水县" },
            { "value": "673465", "label": "秦州区" },
            { "value": "673464", "label": "市辖区" }
          ]
        },
        {
          "value": "678560",
          "label": "平凉市",
          "children": [
            { "value": "679271", "label": "崇信县" },
            { "value": "679368", "label": "华亭县" },
            { "value": "678825", "label": "泾川县" },
            { "value": "679507", "label": "庄浪县" },
            { "value": "679825", "label": "静宁县" },
            { "value": "678561", "label": "市辖区" },
            { "value": "679066", "label": "灵台县" },
            { "value": "678562", "label": "崆峒区" }
          ]
        },
        {
          "value": "680191",
          "label": "酒泉市",
          "children": [
            { "value": "680736", "label": "敦煌市" },
            { "value": "680615", "label": "阿克塞哈萨克族自治县" },
            { "value": "680192", "label": "市辖区" },
            { "value": "680582", "label": "肃北蒙古族自治县" },
            { "value": "680478", "label": "瓜州县" },
            { "value": "680193", "label": "肃州区" },
            { "value": "680634", "label": "玉门市" },
            { "value": "680365", "label": "金塔县" }
          ]
        },
        {
          "value": "677568",
          "label": "张掖市",
          "children": [
            { "value": "678174", "label": "临泽县" },
            { "value": "678427", "label": "山丹县" },
            { "value": "677983", "label": "民乐县" },
            { "value": "678270", "label": "高台县" },
            { "value": "677569", "label": "市辖区" },
            { "value": "677865", "label": "肃南裕固族自治县" },
            { "value": "677570", "label": "甘州区" }
          ]
        },
        {
          "value": "682322",
          "label": "定西市",
          "children": [
            { "value": "683034", "label": "陇西县" },
            { "value": "684024", "label": "岷县" },
            { "value": "683869", "label": "漳县" },
            { "value": "683278", "label": "渭源县" },
            { "value": "683515", "label": "临洮县" },
            { "value": "682323", "label": "市辖区" },
            { "value": "682673", "label": "通渭县" },
            { "value": "682324", "label": "安定区" }
          ]
        },
        {
          "value": "671120",
          "label": "兰州市",
          "children": [
            { "value": "671740", "label": "永登县" },
            { "value": "672034", "label": "榆中县" },
            { "value": "671675", "label": "红古区" },
            { "value": "671606", "label": "安宁区" },
            { "value": "671477", "label": "西固区" },
            { "value": "671324", "label": "七里河区" },
            { "value": "671967", "label": "皋兰县" },
            { "value": "671121", "label": "市辖区" },
            { "value": "671122", "label": "城关区" }
          ]
        },
        {
          "value": "672330",
          "label": "嘉峪关市",
          "children": [{ "value": "672331", "label": "市辖区" }]
        }
      ]
    },
    {
      "value": "533328",
      "label": "重庆市",
      "children": [
        {
          "value": "533329",
          "label": "直辖区",
          "children": [
            { "value": "540131", "label": "开州区" },
            { "value": "537212", "label": "黔江区" },
            { "value": "536131", "label": "大足区" },
            { "value": "535262", "label": "南岸区" },
            { "value": "538778", "label": "南川区" },
            { "value": "539615", "label": "潼南区" },
            { "value": "539057", "label": "璧山区" },
            { "value": "535021", "label": "九龙坡区" },
            { "value": "538493", "label": "永川区" },
            { "value": "536468", "label": "渝北区" },
            { "value": "535428", "label": "北碚区" },
            { "value": "534019", "label": "涪陵区" },
            { "value": "535629", "label": "綦江区" },
            { "value": "534558", "label": "大渡口区" },
            { "value": "536890", "label": "巴南区" },
            { "value": "534799", "label": "沙坪坝区" },
            { "value": "534468", "label": "渝中区" },
            { "value": "539260", "label": "铜梁区" },
            { "value": "537461", "label": "长寿区" },
            { "value": "537745", "label": "江津区" },
            { "value": "534656", "label": "江北区" },
            { "value": "539942", "label": "荣昌区" },
            { "value": "533330", "label": "万州区" },
            { "value": "538050", "label": "合川区" }
          ]
        },
        {
          "value": "540700",
          "label": "直辖县",
          "children": [
            { "value": "541080", "label": "城口县" },
            { "value": "545196", "label": "彭水苗族土家族自治县" },
            { "value": "544878", "label": "酉阳土家族苗族自治县" },
            { "value": "541671", "label": "垫江县" },
            { "value": "544307", "label": "石柱土家族自治县" },
            { "value": "541310", "label": "丰都县" },
            { "value": "543575", "label": "巫山县" },
            { "value": "541999", "label": "武隆县" },
            { "value": "543152", "label": "奉节县" },
            { "value": "540701", "label": "梁平县" },
            { "value": "542236", "label": "忠县" },
            { "value": "543943", "label": "巫溪县" },
            { "value": "544583", "label": "秀山土家族苗族自治县" },
            { "value": "542631", "label": "云阳县" }
          ]
        }
      ]
    },
    {
      "value": "133208",
      "label": "吉林省",
      "children": [
        {
          "value": "138721",
          "label": "辽源市",
          "children": [
            { "value": "138723", "label": "龙山区" },
            { "value": "139072", "label": "东辽县" },
            { "value": "138722", "label": "市辖区" },
            { "value": "138780", "label": "西安区" },
            { "value": "138818", "label": "东丰县" }
          ]
        },
        {
          "value": "142786",
          "label": "白城市",
          "children": [
            { "value": "142787", "label": "市辖区" },
            { "value": "143428", "label": "洮南市" },
            { "value": "143702", "label": "大安市" },
            { "value": "143021", "label": "镇赉县" },
            { "value": "142788", "label": "洮北区" },
            { "value": "143209", "label": "通榆县" }
          ]
        },
        {
          "value": "135392",
          "label": "吉林市",
          "children": [
            { "value": "136629", "label": "舒兰市" },
            { "value": "136889", "label": "磐石市" },
            { "value": "135393", "label": "市辖区" },
            { "value": "135885", "label": "丰满区" },
            { "value": "136136", "label": "蛟河市" },
            { "value": "135986", "label": "永吉县" },
            { "value": "136439", "label": "桦甸市" },
            { "value": "135755", "label": "船营区" },
            { "value": "135394", "label": "昌邑区" },
            { "value": "135568", "label": "龙潭区" }
          ]
        },
        {
          "value": "133209",
          "label": "长春市",
          "children": [
            { "value": "133289", "label": "宽城区" },
            { "value": "133881", "label": "九台区" },
            { "value": "133543", "label": "二道区" },
            { "value": "135061", "label": "德惠市" },
            { "value": "133719", "label": "双阳区" },
            { "value": "133631", "label": "绿园区" },
            { "value": "133211", "label": "南关区" },
            { "value": "134628", "label": "榆树市" },
            { "value": "133210", "label": "市辖区" },
            { "value": "134236", "label": "农安县" },
            { "value": "133453", "label": "朝阳区" }
          ]
        },
        {
          "value": "143990",
          "label": "延边朝鲜族自治州",
          "children": [
            { "value": "144665", "label": "珲春市" },
            { "value": "145096", "label": "汪清县" },
            { "value": "143991", "label": "延吉市" },
            { "value": "144953", "label": "和龙市" },
            { "value": "144226", "label": "敦化市" },
            { "value": "144844", "label": "龙井市" },
            { "value": "145421", "label": "安图县" },
            { "value": "144147", "label": "图们市" }
          ]
        },
        {
          "value": "139340",
          "label": "通化市",
          "children": [
            { "value": "139342", "label": "东昌区" },
            { "value": "139341", "label": "市辖区" },
            { "value": "140432", "label": "集安市" },
            { "value": "140077", "label": "梅河口市" },
            { "value": "139405", "label": "二道江区" },
            { "value": "139654", "label": "辉南县" },
            { "value": "139824", "label": "柳河县" },
            { "value": "139453", "label": "通化县" }
          ]
        },
        {
          "value": "140601",
          "label": "白山市",
          "children": [
            { "value": "140602", "label": "市辖区" },
            { "value": "140603", "label": "浑江区" },
            { "value": "141237", "label": "临江市" },
            { "value": "141003", "label": "靖宇县" },
            { "value": "140714", "label": "江源区" },
            { "value": "140816", "label": "抚松县" },
            { "value": "141140", "label": "长白朝鲜族自治县" }
          ]
        },
        {
          "value": "137206",
          "label": "四平市",
          "children": [
            { "value": "137973", "label": "公主岭市" },
            { "value": "137386", "label": "梨树县" },
            { "value": "138477", "label": "双辽市" },
            { "value": "137208", "label": "铁西区" },
            { "value": "137207", "label": "市辖区" },
            { "value": "137756", "label": "伊通满族自治县" },
            { "value": "137275", "label": "铁东区" }
          ]
        },
        {
          "value": "141361",
          "label": "松原市",
          "children": [
            { "value": "141362", "label": "市辖区" },
            { "value": "142157", "label": "乾安县" },
            { "value": "141363", "label": "宁江区" },
            { "value": "141870", "label": "长岭县" },
            { "value": "142353", "label": "扶余市" },
            { "value": "141560", "label": "前郭尔罗斯蒙古族自治县" }
          ]
        }
      ]
    },
    {
      "value": "262698",
      "label": "江西省",
      "children": [
        {
          "value": "266521",
          "label": "九江市",
          "children": [
            { "value": "267868", "label": "德安县" },
            { "value": "268926", "label": "共青城市" },
            { "value": "268480", "label": "彭泽县" },
            { "value": "266946", "label": "武宁县" },
            { "value": "268318", "label": "湖口县" },
            { "value": "266781", "label": "九江县" },
            { "value": "268995", "label": "庐山市" },
            { "value": "268689", "label": "瑞昌市" },
            { "value": "267613", "label": "永修县" },
            { "value": "266522", "label": "市辖区" },
            { "value": "266523", "label": "濂溪区" },
            { "value": "267982", "label": "都昌县" },
            { "value": "266665", "label": "浔阳区" },
            { "value": "267168", "label": "修水县" }
          ]
        },
        {
          "value": "269107",
          "label": "新余市",
          "children": [
            { "value": "269465", "label": "分宜县" },
            { "value": "269109", "label": "渝水区" },
            { "value": "269108", "label": "市辖区" }
          ]
        },
        {
          "value": "264862",
          "label": "景德镇市",
          "children": [
            { "value": "264863", "label": "市辖区" },
            { "value": "265083", "label": "浮梁县" },
            { "value": "264962", "label": "珠山区" },
            { "value": "265297", "label": "乐平市" },
            { "value": "264864", "label": "昌江区" }
          ]
        },
        {
          "value": "265677",
          "label": "萍乡市",
          "children": [
            { "value": "265679", "label": "安源区" },
            { "value": "266356", "label": "芦溪县" },
            { "value": "266171", "label": "上栗县" },
            { "value": "265826", "label": "湘东区" },
            { "value": "265678", "label": "市辖区" },
            { "value": "265994", "label": "莲花县" }
          ]
        },
        {
          "value": "282841",
          "label": "上饶市",
          "children": [
            { "value": "282842", "label": "市辖区" },
            { "value": "284706", "label": "鄱阳县" },
            { "value": "283769", "label": "铅山县" },
            { "value": "285488", "label": "婺源县" },
            { "value": "285711", "label": "德兴市" },
            { "value": "282978", "label": "广丰区" },
            { "value": "282843", "label": "信州区" },
            { "value": "283970", "label": "横峰县" },
            { "value": "283511", "label": "玉山县" },
            { "value": "285319", "label": "万年县" },
            { "value": "284066", "label": "弋阳县" },
            { "value": "283240", "label": "上饶县" },
            { "value": "284239", "label": "余干县" }
          ]
        },
        {
          "value": "280562",
          "label": "抚州市",
          "children": [
            { "value": "281827", "label": "乐安县" },
            { "value": "281641", "label": "崇仁县" },
            { "value": "282482", "label": "东乡县" },
            { "value": "280563", "label": "市辖区" },
            { "value": "282036", "label": "宜黄县" },
            { "value": "281098", "label": "南城县" },
            { "value": "281285", "label": "黎川县" },
            { "value": "281443", "label": "南丰县" },
            { "value": "282391", "label": "资溪县" },
            { "value": "282206", "label": "金溪县" },
            { "value": "282684", "label": "广昌县" },
            { "value": "280564", "label": "临川区" }
          ]
        },
        {
          "value": "274444",
          "label": "吉安市",
          "children": [
            { "value": "274592", "label": "青原区" },
            { "value": "275406", "label": "峡江县" },
            { "value": "275956", "label": "泰和县" },
            { "value": "275511", "label": "新干县" },
            { "value": "276331", "label": "遂川县" },
            { "value": "276874", "label": "安福县" },
            { "value": "275694", "label": "永丰县" },
            { "value": "274736", "label": "吉安县" },
            { "value": "275105", "label": "吉水县" },
            { "value": "276700", "label": "万安县" },
            { "value": "274446", "label": "吉州区" },
            { "value": "274445", "label": "市辖区" },
            { "value": "277459", "label": "井冈山市" },
            { "value": "277170", "label": "永新县" }
          ]
        },
        {
          "value": "262699",
          "label": "南昌市",
          "children": [
            { "value": "262701", "label": "东湖区" },
            { "value": "262889", "label": "西湖区" },
            { "value": "264517", "label": "进贤县" },
            { "value": "263058", "label": "青云谱区" },
            { "value": "262700", "label": "市辖区" },
            { "value": "264375", "label": "安义县" },
            { "value": "263496", "label": "新建区" },
            { "value": "263948", "label": "南昌县" },
            { "value": "263217", "label": "青山湖区" },
            { "value": "263152", "label": "湾里区" }
          ]
        },
        {
          "value": "270222",
          "label": "赣州市",
          "children": [
            { "value": "272767", "label": "于都县" },
            { "value": "273525", "label": "会昌县" },
            { "value": "273820", "label": "寻乌县" },
            { "value": "270780", "label": "赣县" },
            { "value": "274184", "label": "瑞金市" },
            { "value": "272156", "label": "定南县" },
            { "value": "271095", "label": "信丰县" },
            { "value": "274020", "label": "石城县" },
            { "value": "271410", "label": "大余县" },
            { "value": "270224", "label": "章贡区" },
            { "value": "272033", "label": "龙南县" },
            { "value": "272302", "label": "全南县" },
            { "value": "271699", "label": "崇义县" },
            { "value": "273183", "label": "兴国县" },
            { "value": "270223", "label": "市辖区" },
            { "value": "270442", "label": "南康区" },
            { "value": "271848", "label": "安远县" },
            { "value": "271541", "label": "上犹县" },
            { "value": "272408", "label": "宁都县" }
          ]
        },
        {
          "value": "277615",
          "label": "宜春市",
          "children": [
            { "value": "279220", "label": "丰城市" },
            { "value": "278249", "label": "万载县" },
            { "value": "277617", "label": "袁州区" },
            { "value": "279845", "label": "樟树市" },
            { "value": "278710", "label": "宜丰县" },
            { "value": "278975", "label": "靖安县" },
            { "value": "280166", "label": "高安市" },
            { "value": "278471", "label": "上高县" },
            { "value": "277616", "label": "市辖区" },
            { "value": "278033", "label": "奉新县" },
            { "value": "279095", "label": "铜鼓县" }
          ]
        },
        {
          "value": "269654",
          "label": "鹰潭市",
          "children": [
            { "value": "269655", "label": "市辖区" },
            { "value": "269942", "label": "贵溪市" },
            { "value": "269746", "label": "余江县" },
            { "value": "269656", "label": "月湖区" }
          ]
        }
      ]
    },
    {
      "value": "285860",
      "label": "山东省",
      "children": [
        {
          "value": "361375",
          "label": "菏泽市",
          "children": [
            { "value": "363576", "label": "单县" },
            { "value": "361988", "label": "定陶区" },
            { "value": "364606", "label": "巨野县" },
            { "value": "365498", "label": "郓城县" },
            { "value": "361377", "label": "牡丹区" },
            { "value": "366562", "label": "鄄城县" },
            { "value": "364107", "label": "成武县" },
            { "value": "362368", "label": "曹县" },
            { "value": "366971", "label": "东明县" },
            { "value": "361376", "label": "市辖区" }
          ]
        },
        {
          "value": "344133",
          "label": "德州市",
          "children": [
            { "value": "346117", "label": "庆云县" },
            { "value": "346518", "label": "临邑县" },
            { "value": "344134", "label": "市辖区" },
            { "value": "345248", "label": "宁津县" },
            { "value": "348268", "label": "武城县" },
            { "value": "344135", "label": "德城区" },
            { "value": "347749", "label": "平原县" },
            { "value": "344244", "label": "陵城区" },
            { "value": "348980", "label": "禹城市" },
            { "value": "346715", "label": "齐河县" },
            { "value": "347943", "label": "夏津县" },
            { "value": "348472", "label": "乐陵市" }
          ]
        },
        {
          "value": "320179",
          "label": "济宁市",
          "children": [
            { "value": "320181", "label": "任城区" },
            { "value": "323607", "label": "汶上县" },
            { "value": "324118", "label": "泗水县" },
            { "value": "325372", "label": "曲阜市" },
            { "value": "325791", "label": "邹城市" },
            { "value": "321804", "label": "鱼台县" },
            { "value": "321246", "label": "微山县" },
            { "value": "322209", "label": "金乡县" },
            { "value": "322882", "label": "嘉祥县" },
            { "value": "320795", "label": "兖州区" },
            { "value": "320180", "label": "市辖区" },
            { "value": "324729", "label": "梁山县" }
          ]
        },
        {
          "value": "291235",
          "label": "青岛市",
          "children": [
            { "value": "291236", "label": "市辖区" },
            { "value": "293241", "label": "胶州市" },
            { "value": "297121", "label": "莱西市" },
            { "value": "293008", "label": "城阳区" },
            { "value": "292879", "label": "李沧区" },
            { "value": "292713", "label": "崂山区" },
            { "value": "291313", "label": "市北区" },
            { "value": "291468", "label": "黄岛区" },
            { "value": "294138", "label": "即墨市" },
            { "value": "291237", "label": "市南区" },
            { "value": "295251", "label": "平度市" }
          ]
        },
        {
          "value": "337144",
          "label": "临沂市",
          "children": [
            { "value": "340490", "label": "兰陵县" },
            { "value": "342766", "label": "莒南县" },
            { "value": "338778", "label": "郯城县" },
            { "value": "342020", "label": "平邑县" },
            { "value": "337146", "label": "兰山区" },
            { "value": "341532", "label": "费县" },
            { "value": "337145", "label": "市辖区" },
            { "value": "339408", "label": "沂水县" },
            { "value": "343361", "label": "蒙阴县" },
            { "value": "337621", "label": "罗庄区" },
            { "value": "337844", "label": "河东区" },
            { "value": "343836", "label": "临沭县" },
            { "value": "338188", "label": "沂南县" }
          ]
        },
        {
          "value": "301711",
          "label": "枣庄市",
          "children": [
            { "value": "301888", "label": "薛城区" },
            { "value": "302179", "label": "峄城区" },
            { "value": "301712", "label": "市辖区" },
            { "value": "302532", "label": "台儿庄区" },
            { "value": "303037", "label": "滕州市" },
            { "value": "301713", "label": "市中区" },
            { "value": "302750", "label": "山亭区" }
          ]
        },
        {
          "value": "285861",
          "label": "济南市",
          "children": [
            { "value": "285863", "label": "历下区" },
            { "value": "286215", "label": "槐荫区" },
            { "value": "290291", "label": "章丘市" },
            { "value": "286683", "label": "历城区" },
            { "value": "288448", "label": "济阳县" },
            { "value": "285862", "label": "市辖区" },
            { "value": "285991", "label": "市中区" },
            { "value": "286404", "label": "天桥区" },
            { "value": "287424", "label": "长清区" },
            { "value": "288084", "label": "平阴县" },
            { "value": "289313", "label": "商河县" }
          ]
        },
        {
          "value": "298025",
          "label": "淄博市",
          "children": [
            { "value": "298507", "label": "张店区" },
            { "value": "301056", "label": "沂源县" },
            { "value": "299652", "label": "周村区" },
            { "value": "298821", "label": "博山区" },
            { "value": "298026", "label": "市辖区" },
            { "value": "299919", "label": "桓台县" },
            { "value": "299159", "label": "临淄区" },
            { "value": "300276", "label": "高青县" },
            { "value": "298027", "label": "淄川区" }
          ]
        },
        {
          "value": "306339",
          "label": "烟台市",
          "children": [
            { "value": "306341", "label": "芝罘区" },
            { "value": "307481", "label": "长岛县" },
            { "value": "312312", "label": "海阳市" },
            { "value": "306496", "label": "福山区" },
            { "value": "310581", "label": "招远市" },
            { "value": "307530", "label": "龙口市" },
            { "value": "308140", "label": "莱阳市" },
            { "value": "307348", "label": "莱山区" },
            { "value": "306743", "label": "牟平区" },
            { "value": "308953", "label": "莱州市" },
            { "value": "306340", "label": "市辖区" },
            { "value": "309985", "label": "蓬莱市" },
            { "value": "311342", "label": "栖霞市" }
          ]
        },
        {
          "value": "336050",
          "label": "莱芜市",
          "children": [
            { "value": "336908", "label": "钢城区" },
            { "value": "336051", "label": "市辖区" },
            { "value": "336052", "label": "莱城区" }
          ]
        },
        {
          "value": "349382",
          "label": "聊城市",
          "children": [
            { "value": "354643", "label": "高唐县" },
            { "value": "349384", "label": "东昌府区" },
            { "value": "351436", "label": "莘县" },
            { "value": "349383", "label": "市辖区" },
            { "value": "355301", "label": "临清市" },
            { "value": "350543", "label": "阳谷县" },
            { "value": "353334", "label": "东阿县" },
            { "value": "353863", "label": "冠县" },
            { "value": "352587", "label": "茌平县" }
          ]
        },
        {
          "value": "313063",
          "label": "潍坊市",
          "children": [
            { "value": "313064", "label": "市辖区" },
            { "value": "316361", "label": "诸城市" },
            { "value": "314533", "label": "临朐县" },
            { "value": "314894", "label": "昌乐县" },
            { "value": "316642", "label": "寿光市" },
            { "value": "313327", "label": "寒亭区" },
            { "value": "315273", "label": "青州市" },
            { "value": "317633", "label": "安丘市" },
            { "value": "319478", "label": "昌邑市" },
            { "value": "313065", "label": "潍城区" },
            { "value": "318512", "label": "高密市" },
            { "value": "314288", "label": "奎文区" },
            { "value": "313701", "label": "坊子区" }
          ]
        },
        {
          "value": "326706",
          "label": "泰安市",
          "children": [
            { "value": "327655", "label": "宁阳县" },
            { "value": "329897", "label": "肥城市" },
            { "value": "326707", "label": "市辖区" },
            { "value": "326926", "label": "岱岳区" },
            { "value": "328231", "label": "东平县" },
            { "value": "326708", "label": "泰山区" },
            { "value": "328962", "label": "新泰市" }
          ]
        },
        {
          "value": "333140",
          "label": "日照市",
          "children": [
            { "value": "333142", "label": "东港区" },
            { "value": "333755", "label": "岚山区" },
            { "value": "333141", "label": "市辖区" },
            { "value": "334188", "label": "五莲县" },
            { "value": "334833", "label": "莒县" }
          ]
        },
        {
          "value": "355972",
          "label": "滨州市",
          "children": [
            { "value": "360507", "label": "邹平县" },
            { "value": "360038", "label": "博兴县" },
            { "value": "356960", "label": "沾化区" },
            { "value": "355973", "label": "市辖区" },
            { "value": "355974", "label": "滨城区" },
            { "value": "358556", "label": "阳信县" },
            { "value": "357420", "label": "惠民县" },
            { "value": "359424", "label": "无棣县" }
          ]
        },
        {
          "value": "330547",
          "label": "威海市",
          "children": [
            { "value": "332496", "label": "乳山市" },
            { "value": "330549", "label": "环翠区" },
            { "value": "330548", "label": "市辖区" },
            { "value": "331529", "label": "荣成市" },
            { "value": "330748", "label": "文登区" }
          ]
        },
        {
          "value": "304309",
          "label": "东营市",
          "children": [
            { "value": "304311", "label": "东营区" },
            { "value": "305208", "label": "利津县" },
            { "value": "304310", "label": "市辖区" },
            { "value": "304845", "label": "垦利区" },
            { "value": "304647", "label": "河口区" },
            { "value": "305747", "label": "广饶县" }
          ]
        }
      ]
    },
    {
      "value": "646578",
      "label": "陕西省",
      "children": [
        {
          "value": "646579",
          "label": "西安市",
          "children": [
            { "value": "649123", "label": "周至县" },
            { "value": "646800", "label": "莲湖区" },
            { "value": "648645", "label": "高陵区" },
            { "value": "648756", "label": "蓝田县" },
            { "value": "647710", "label": "阎良区" },
            { "value": "646694", "label": "碑林区" },
            { "value": "647214", "label": "未央区" },
            { "value": "646580", "label": "市辖区" },
            { "value": "647821", "label": "临潼区" },
            { "value": "646944", "label": "灞桥区" },
            { "value": "647486", "label": "雁塔区" },
            { "value": "649424", "label": "户县" },
            { "value": "646581", "label": "新城区" },
            { "value": "648113", "label": "长安区" }
          ]
        },
        {
          "value": "657848",
          "label": "延安市",
          "children": [
            { "value": "658462", "label": "延长县" },
            { "value": "660375", "label": "黄龙县" },
            { "value": "659777", "label": "洛川县" },
            { "value": "658765", "label": "延川县" },
            { "value": "659327", "label": "志丹县" },
            { "value": "659450", "label": "吴起县" },
            { "value": "657850", "label": "宝塔区" },
            { "value": "659632", "label": "富县" },
            { "value": "660433", "label": "黄陵县" },
            { "value": "658951", "label": "子长县" },
            { "value": "659554", "label": "甘泉县" },
            { "value": "657849", "label": "市辖区" },
            { "value": "658235", "label": "安塞区" },
            { "value": "660160", "label": "宜川县" }
          ]
        },
        {
          "value": "649979",
          "label": "铜川市",
          "children": [
            { "value": "650189", "label": "耀州区" },
            { "value": "650386", "label": "宜君县" },
            { "value": "649980", "label": "市辖区" },
            { "value": "649981", "label": "王益区" },
            { "value": "650048", "label": "印台区" }
          ]
        },
        {
          "value": "650575",
          "label": "宝鸡市",
          "children": [
            { "value": "652294", "label": "麟游县" },
            { "value": "651528", "label": "岐山县" },
            { "value": "652186", "label": "千阳县" },
            { "value": "651697", "label": "扶风县" },
            { "value": "652505", "label": "太白县" },
            { "value": "651872", "label": "眉县" },
            { "value": "650576", "label": "市辖区" },
            { "value": "650747", "label": "金台区" },
            { "value": "650577", "label": "渭滨区" },
            { "value": "650913", "label": "陈仓区" },
            { "value": "651278", "label": "凤翔县" },
            { "value": "652387", "label": "凤县" },
            { "value": "652014", "label": "陇县" }
          ]
        },
        {
          "value": "660643",
          "label": "汉中市",
          "children": [
            { "value": "662993", "label": "佛坪县" },
            { "value": "662521", "label": "略阳县" },
            { "value": "662072", "label": "勉县" },
            { "value": "661839", "label": "西乡县" },
            { "value": "660644", "label": "市辖区" },
            { "value": "662704", "label": "镇巴县" },
            { "value": "662289", "label": "宁强县" },
            { "value": "661535", "label": "洋县" },
            { "value": "660909", "label": "南郑县" },
            { "value": "661244", "label": "城固县" },
            { "value": "662908", "label": "留坝县" },
            { "value": "660645", "label": "汉台区" }
          ]
        },
        {
          "value": "663046",
          "label": "榆林市",
          "children": [
            { "value": "664441", "label": "靖边县" },
            { "value": "664184", "label": "府谷县" },
            { "value": "663827", "label": "神木县" },
            { "value": "667106", "label": "子洲县" },
            { "value": "663048", "label": "榆阳区" },
            { "value": "664686", "label": "定边县" },
            { "value": "665047", "label": "绥德县" },
            { "value": "663445", "label": "横山区" },
            { "value": "663047", "label": "市辖区" },
            { "value": "666627", "label": "吴堡县" },
            { "value": "665953", "label": "佳县" },
            { "value": "666741", "label": "清涧县" },
            { "value": "665732", "label": "米脂县" }
          ]
        },
        {
          "value": "652581",
          "label": "咸阳市",
          "children": [
            { "value": "652582", "label": "市辖区" },
            { "value": "653234", "label": "泾阳县" },
            { "value": "653042", "label": "三原县" },
            { "value": "654369", "label": "长武县" },
            { "value": "654876", "label": "武功县" },
            { "value": "653679", "label": "礼泉县" },
            { "value": "653918", "label": "永寿县" },
            { "value": "653470", "label": "乾县" },
            { "value": "654661", "label": "淳化县" },
            { "value": "654525", "label": "旬邑县" },
            { "value": "652863", "label": "渭城区" },
            { "value": "652779", "label": "杨陵区" },
            { "value": "654101", "label": "彬县" },
            { "value": "652583", "label": "秦都区" },
            { "value": "655081", "label": "兴平市" }
          ]
        },
        {
          "value": "667681",
          "label": "安康市",
          "children": [
            { "value": "669026", "label": "平利县" },
            { "value": "668877", "label": "岚皋县" },
            { "value": "668397", "label": "石泉县" },
            { "value": "669251", "label": "旬阳县" },
            { "value": "669181", "label": "镇坪县" },
            { "value": "668237", "label": "汉阴县" },
            { "value": "667683", "label": "汉滨区" },
            { "value": "667682", "label": "市辖区" },
            { "value": "668570", "label": "宁陕县" },
            { "value": "669578", "label": "白河县" },
            { "value": "668662", "label": "紫阳县" }
          ]
        },
        {
          "value": "655295",
          "label": "渭南市",
          "children": [
            { "value": "656260", "label": "合阳县" },
            { "value": "655296", "label": "市辖区" },
            { "value": "655885", "label": "潼关县" },
            { "value": "657706", "label": "华阴市" },
            { "value": "655297", "label": "临渭区" },
            { "value": "657425", "label": "韩城市" },
            { "value": "655724", "label": "华州区" },
            { "value": "656494", "label": "澄城县" },
            { "value": "656682", "label": "蒲城县" },
            { "value": "655919", "label": "大荔县" },
            { "value": "657132", "label": "富平县" },
            { "value": "656988", "label": "白水县" }
          ]
        },
        {
          "value": "669707",
          "label": "商洛市",
          "children": [
            { "value": "670594", "label": "山阳县" },
            { "value": "669708", "label": "市辖区" },
            { "value": "670459", "label": "商南县" },
            { "value": "670030", "label": "洛南县" },
            { "value": "671028", "label": "柞水县" },
            { "value": "670291", "label": "丹凤县" },
            { "value": "669709", "label": "商州区" },
            { "value": "670852", "label": "镇安县" }
          ]
        }
      ]
    },
    {
      "value": "7362",
      "label": "天津市",
      "children": [
        {
          "value": "7363",
          "label": "直辖区",
          "children": [
            { "value": "8079", "label": "红桥区" },
            { "value": "10143", "label": "宝坻区" },
            { "value": "9378", "label": "武清区" },
            { "value": "7434", "label": "河东区" },
            { "value": "8835", "label": "津南区" },
            { "value": "7607", "label": "河西区" },
            { "value": "11454", "label": "宁河区" },
            { "value": "7954", "label": "河北区" },
            { "value": "8563", "label": "西青区" },
            { "value": "10983", "label": "滨海新区" },
            { "value": "8298", "label": "东丽区" },
            { "value": "9102", "label": "北辰区" },
            { "value": "11795", "label": "静海区" },
            { "value": "7763", "label": "南开区" },
            { "value": "12251", "label": "蓟州区" },
            { "value": "7364", "label": "和平区" }
          ]
        }
      ]
    },
    {
      "value": "604131",
      "label": "贵州省",
      "children": [
        {
          "value": "622734",
          "label": "黔南布依族苗族自治州",
          "children": [
            { "value": "623723", "label": "长顺县" },
            { "value": "623082", "label": "贵定县" },
            { "value": "622973", "label": "荔波县" },
            { "value": "623530", "label": "罗甸县" },
            { "value": "624122", "label": "三都水族自治县" },
            { "value": "623813", "label": "龙里县" },
            { "value": "623391", "label": "平塘县" },
            { "value": "622735", "label": "都匀市" },
            { "value": "623205", "label": "瓮安县" },
            { "value": "623318", "label": "独山县" },
            { "value": "623901", "label": "惠水县" },
            { "value": "622888", "label": "福泉市" }
          ]
        },
        {
          "value": "604132",
          "label": "贵阳市",
          "children": [
            { "value": "605593", "label": "清镇市" },
            { "value": "604769", "label": "乌当区" },
            { "value": "605264", "label": "息烽县" },
            { "value": "604883", "label": "白云区" },
            { "value": "604133", "label": "市辖区" },
            { "value": "604134", "label": "南明区" },
            { "value": "604989", "label": "观山湖区" },
            { "value": "604530", "label": "花溪区" },
            { "value": "604342", "label": "云岩区" },
            { "value": "605453", "label": "修文县" },
            { "value": "605114", "label": "开阳县" }
          ]
        },
        {
          "value": "605834",
          "label": "六盘水市",
          "children": [
            { "value": "606258", "label": "水城县" },
            { "value": "606493", "label": "盘县" },
            { "value": "605994", "label": "六枝特区" },
            { "value": "605835", "label": "钟山区" }
          ]
        },
        {
          "value": "617670",
          "label": "黔西南布依族苗族自治州",
          "children": [
            { "value": "618205", "label": "晴隆县" },
            { "value": "618102", "label": "普安县" },
            { "value": "617671", "label": "兴义市" },
            { "value": "618671", "label": "册亨县" },
            { "value": "617923", "label": "兴仁县" },
            { "value": "618316", "label": "贞丰县" },
            { "value": "618816", "label": "安龙县" },
            { "value": "618489", "label": "望谟县" }
          ]
        },
        {
          "value": "609309",
          "label": "安顺市",
          "children": [
            { "value": "609311", "label": "西秀区" },
            { "value": "610426", "label": "紫云苗族布依族自治县" },
            { "value": "610256", "label": "关岭布依族苗族自治县" },
            { "value": "609845", "label": "普定县" },
            { "value": "609310", "label": "市辖区" },
            { "value": "609686", "label": "平坝区" },
            { "value": "610030", "label": "镇宁布依族苗族自治县" }
          ]
        },
        {
          "value": "619017",
          "label": "黔东南苗族侗族自治州",
          "children": [
            { "value": "619657", "label": "三穗县" },
            { "value": "619308", "label": "黄平县" },
            { "value": "620996", "label": "台江县" },
            { "value": "620673", "label": "剑河县" },
            { "value": "619018", "label": "凯里市" },
            { "value": "622312", "label": "雷山县" },
            { "value": "619970", "label": "岑巩县" },
            { "value": "621166", "label": "黎平县" },
            { "value": "619831", "label": "镇远县" },
            { "value": "621906", "label": "从江县" },
            { "value": "621616", "label": "榕江县" },
            { "value": "620122", "label": "天柱县" },
            { "value": "622561", "label": "丹寨县" },
            { "value": "619574", "label": "施秉县" },
            { "value": "622484", "label": "麻江县" },
            { "value": "620465", "label": "锦屏县" }
          ]
        },
        {
          "value": "607029",
          "label": "遵义市",
          "children": [
            { "value": "608236", "label": "务川仡佬族苗族自治县" },
            { "value": "608620", "label": "余庆县" },
            { "value": "607584", "label": "桐梓县" },
            { "value": "608370", "label": "凤冈县" },
            { "value": "608471", "label": "湄潭县" },
            { "value": "607833", "label": "绥阳县" },
            { "value": "607235", "label": "汇川区" },
            { "value": "609114", "label": "仁怀市" },
            { "value": "608974", "label": "赤水市" },
            { "value": "607030", "label": "市辖区" },
            { "value": "607966", "label": "正安县" },
            { "value": "608138", "label": "道真仡佬族苗族自治县" },
            { "value": "607385", "label": "播州区" },
            { "value": "607031", "label": "红花岗区" },
            { "value": "608701", "label": "习水县" }
          ]
        },
        {
          "value": "610607",
          "label": "毕节市",
          "children": [
            { "value": "610609", "label": "七星关区" },
            { "value": "610608", "label": "市辖区" },
            { "value": "614035", "label": "赫章县" },
            { "value": "611627", "label": "黔西县" },
            { "value": "612043", "label": "金沙县" },
            { "value": "613369", "label": "威宁彝族回族苗族自治县" },
            { "value": "612308", "label": "织金县" },
            { "value": "612915", "label": "纳雍县" },
            { "value": "611207", "label": "大方县" }
          ]
        },
        {
          "value": "614530",
          "label": "铜仁市",
          "children": [
            { "value": "614636", "label": "万山区" },
            { "value": "614531", "label": "市辖区" },
            { "value": "617116", "label": "松桃苗族自治县" },
            { "value": "614733", "label": "江口县" },
            { "value": "616644", "label": "沿河土家族自治县" },
            { "value": "614532", "label": "碧江区" },
            { "value": "615886", "label": "印江土家族苗族自治县" },
            { "value": "615001", "label": "石阡县" },
            { "value": "616278", "label": "德江县" },
            { "value": "614900", "label": "玉屏侗族自治县" },
            { "value": "615331", "label": "思南县" }
          ]
        }
      ]
    },
    {
      "value": "244377",
      "label": "福建省",
      "children": [
        {
          "value": "258160",
          "label": "龙岩市",
          "children": [
            { "value": "259749", "label": "连城县" },
            { "value": "259512", "label": "武平县" },
            { "value": "258520", "label": "永定区" },
            { "value": "260014", "label": "漳平市" },
            { "value": "259144", "label": "上杭县" },
            { "value": "258161", "label": "市辖区" },
            { "value": "258825", "label": "长汀县" },
            { "value": "258162", "label": "新罗区" }
          ]
        },
        {
          "value": "260230",
          "label": "宁德市",
          "children": [
            { "value": "260575", "label": "霞浦县" },
            { "value": "260232", "label": "蕉城区" },
            { "value": "260905", "label": "古田县" },
            { "value": "261885", "label": "福安市" },
            { "value": "261211", "label": "屏南县" },
            { "value": "261759", "label": "柘荣县" },
            { "value": "261382", "label": "寿宁县" },
            { "value": "261602", "label": "周宁县" },
            { "value": "262395", "label": "福鼎市" },
            { "value": "260231", "label": "市辖区" }
          ]
        },
        {
          "value": "247478",
          "label": "厦门市",
          "children": [
            { "value": "247590", "label": "海沧区" },
            { "value": "247933", "label": "翔安区" },
            { "value": "247480", "label": "思明区" },
            { "value": "247782", "label": "同安区" },
            { "value": "247479", "label": "市辖区" },
            { "value": "247704", "label": "集美区" },
            { "value": "247641", "label": "湖里区" }
          ]
        },
        {
          "value": "253905",
          "label": "漳州市",
          "children": [
            { "value": "255680", "label": "华安县" },
            { "value": "255190", "label": "南靖县" },
            { "value": "255105", "label": "东山县" },
            { "value": "254170", "label": "云霄县" },
            { "value": "254747", "label": "诏安县" },
            { "value": "254093", "label": "龙文区" },
            { "value": "254394", "label": "漳浦县" },
            { "value": "255011", "label": "长泰县" },
            { "value": "255789", "label": "龙海市" },
            { "value": "255405", "label": "平和县" },
            { "value": "253907", "label": "芗城区" },
            { "value": "253906", "label": "市辖区" }
          ]
        },
        {
          "value": "249098",
          "label": "三明市",
          "children": [
            { "value": "249351", "label": "清流县" },
            { "value": "249099", "label": "市辖区" },
            { "value": "249245", "label": "明溪县" },
            { "value": "249100", "label": "梅列区" },
            { "value": "250920", "label": "永安市" },
            { "value": "249484", "label": "宁化县" },
            { "value": "250680", "label": "泰宁县" },
            { "value": "249728", "label": "大田县" },
            { "value": "249157", "label": "三元区" },
            { "value": "250026", "label": "尤溪县" },
            { "value": "250512", "label": "将乐县" },
            { "value": "250809", "label": "建宁县" },
            { "value": "250307", "label": "沙县" }
          ]
        },
        {
          "value": "256112",
          "label": "南平市",
          "children": [
            { "value": "256687", "label": "顺昌县" },
            { "value": "257886", "label": "建瓯市" },
            { "value": "257172", "label": "光泽县" },
            { "value": "257276", "label": "松溪县" },
            { "value": "257733", "label": "武夷山市" },
            { "value": "256849", "label": "浦城县" },
            { "value": "256113", "label": "市辖区" },
            { "value": "256114", "label": "延平区" },
            { "value": "256452", "label": "建阳区" },
            { "value": "257539", "label": "邵武市" },
            { "value": "257395", "label": "政和县" }
          ]
        },
        {
          "value": "244378",
          "label": "福州市",
          "children": [
            { "value": "247200", "label": "长乐市" },
            { "value": "246443", "label": "平潭县" },
            { "value": "245329", "label": "连江县" },
            { "value": "244793", "label": "晋安区" },
            { "value": "244460", "label": "台江区" },
            { "value": "244523", "label": "仓山区" },
            { "value": "244380", "label": "鼓楼区" },
            { "value": "244987", "label": "闽侯县" },
            { "value": "245633", "label": "罗源县" },
            { "value": "246154", "label": "永泰县" },
            { "value": "244713", "label": "马尾区" },
            { "value": "244379", "label": "市辖区" },
            { "value": "245845", "label": "闽清县" },
            { "value": "246667", "label": "福清市" }
          ]
        },
        {
          "value": "248059",
          "label": "莆田市",
          "children": [
            { "value": "248060", "label": "市辖区" },
            { "value": "248189", "label": "涵江区" },
            { "value": "248403", "label": "荔城区" },
            { "value": "248061", "label": "城厢区" },
            { "value": "248540", "label": "秀屿区" },
            { "value": "248753", "label": "仙游县" }
          ]
        },
        {
          "value": "251197",
          "label": "泉州市",
          "children": [
            { "value": "251470", "label": "泉港区" },
            { "value": "252406", "label": "永春县" },
            { "value": "253032", "label": "晋江市" },
            { "value": "251289", "label": "丰泽区" },
            { "value": "251199", "label": "鲤城区" },
            { "value": "252665", "label": "德化县" },
            { "value": "253454", "label": "南安市" },
            { "value": "251198", "label": "市辖区" },
            { "value": "251580", "label": "惠安县" },
            { "value": "251897", "label": "安溪县" },
            { "value": "252895", "label": "石狮市" },
            { "value": "252894", "label": "金门县" },
            { "value": "251376", "label": "洛江区" }
          ]
        }
      ]
    },
    {
      "value": "545532",
      "label": "四川省",
      "children": [
        {
          "value": "552358",
          "label": "泸州市",
          "children": [
            { "value": "553181", "label": "合江县" },
            { "value": "552360", "label": "江阳区" },
            { "value": "553823", "label": "古蔺县" },
            { "value": "552536", "label": "纳溪区" },
            { "value": "553536", "label": "叙永县" },
            { "value": "552755", "label": "龙马潭区" },
            { "value": "552359", "label": "市辖区" },
            { "value": "552858", "label": "泸县" }
          ]
        },
        {
          "value": "576992",
          "label": "眉山市",
          "children": [
            { "value": "578124", "label": "洪雅县" },
            { "value": "577323", "label": "彭山区" },
            { "value": "578299", "label": "丹棱县" },
            { "value": "578385", "label": "青神县" },
            { "value": "576993", "label": "市辖区" },
            { "value": "577445", "label": "仁寿县" },
            { "value": "576994", "label": "东坡区" }
          ]
        },
        {
          "value": "550275",
          "label": "自贡市",
          "children": [
            { "value": "550816", "label": "沿滩区" },
            { "value": "550609", "label": "大安区" },
            { "value": "550277", "label": "自流井区" },
            { "value": "550413", "label": "贡井区" },
            { "value": "551398", "label": "富顺县" },
            { "value": "551023", "label": "荣县" },
            { "value": "550276", "label": "市辖区" }
          ]
        },
        {
          "value": "592598",
          "label": "资阳市",
          "children": [
            { "value": "594205", "label": "乐至县" },
            { "value": "592599", "label": "市辖区" },
            { "value": "593157", "label": "安岳县" },
            { "value": "592600", "label": "雁江区" }
          ]
        },
        {
          "value": "545533",
          "label": "成都市",
          "children": [
            { "value": "545535", "label": "锦江区" },
            { "value": "547144", "label": "金堂县" },
            { "value": "548137", "label": "都江堰市" },
            { "value": "549363", "label": "简阳市" },
            { "value": "545534", "label": "市辖区" },
            { "value": "546035", "label": "成华区" },
            { "value": "546441", "label": "新都区" },
            { "value": "546710", "label": "温江区" },
            { "value": "548788", "label": "邛崃市" },
            { "value": "548412", "label": "彭州市" },
            { "value": "547634", "label": "大邑县" },
            { "value": "547398", "label": "郫县" },
            { "value": "548018", "label": "新津县" },
            { "value": "546306", "label": "青白江区" },
            { "value": "545763", "label": "金牛区" },
            { "value": "547873", "label": "蒲江县" },
            { "value": "546835", "label": "双流区" },
            { "value": "545669", "label": "青羊区" },
            { "value": "549084", "label": "崇州市" },
            { "value": "546151", "label": "龙泉驿区" },
            { "value": "545889", "label": "武侯区" }
          ]
        },
        {
          "value": "563242",
          "label": "遂宁市",
          "children": [
            { "value": "563569", "label": "安居区" },
            { "value": "563244", "label": "船山区" },
            { "value": "563243", "label": "市辖区" },
            { "value": "564699", "label": "射洪县" },
            { "value": "564115", "label": "蓬溪县" },
            { "value": "565387", "label": "大英县" }
          ]
        },
        {
          "value": "585049",
          "label": "达州市",
          "children": [
            { "value": "585347", "label": "达川区" },
            { "value": "585050", "label": "市辖区" },
            { "value": "588071", "label": "万源市" },
            { "value": "586709", "label": "开江县" },
            { "value": "586957", "label": "大竹县" },
            { "value": "585051", "label": "通川区" },
            { "value": "586085", "label": "宣汉县" },
            { "value": "587452", "label": "渠县" }
          ]
        },
        {
          "value": "589779",
          "label": "巴中市",
          "children": [
            { "value": "589780", "label": "市辖区" },
            { "value": "590246", "label": "恩阳区" },
            { "value": "590712", "label": "通江县" },
            { "value": "592003", "label": "平昌县" },
            { "value": "591330", "label": "南江县" },
            { "value": "589781", "label": "巴州区" }
          ]
        },
        {
          "value": "568202",
          "label": "乐山市",
          "children": [
            { "value": "569627", "label": "夹江县" },
            { "value": "569368", "label": "井研县" },
            { "value": "568549", "label": "沙湾区" },
            { "value": "568897", "label": "金口河区" },
            { "value": "569905", "label": "沐川县" },
            { "value": "568204", "label": "市中区" },
            { "value": "568203", "label": "市辖区" },
            { "value": "568949", "label": "犍为县" },
            { "value": "570138", "label": "峨边彝族自治县" },
            { "value": "570296", "label": "马边彝族自治县" },
            { "value": "568712", "label": "五通桥区" },
            { "value": "570440", "label": "峨眉山市" }
          ]
        },
        {
          "value": "556077",
          "label": "绵阳市",
          "children": [
            { "value": "558091", "label": "盐亭县" },
            { "value": "558648", "label": "梓潼县" },
            { "value": "556078", "label": "市辖区" },
            { "value": "556985", "label": "三台县" },
            { "value": "556079", "label": "涪城区" },
            { "value": "559397", "label": "平武县" },
            { "value": "559687", "label": "江油市" },
            { "value": "556705", "label": "安州区" },
            { "value": "559030", "label": "北川羌族自治县" },
            { "value": "556357", "label": "游仙区" }
          ]
        },
        {
          "value": "554142",
          "label": "德阳市",
          "children": [
            { "value": "554143", "label": "市辖区" },
            { "value": "554397", "label": "中江县" },
            { "value": "554144", "label": "旌阳区" },
            { "value": "555418", "label": "广汉市" },
            { "value": "555660", "label": "什邡市" },
            { "value": "555848", "label": "绵竹市" },
            { "value": "555280", "label": "罗江县" }
          ]
        },
        {
          "value": "599659",
          "label": "凉山彝族自治州",
          "children": [
            { "value": "600415", "label": "德昌县" },
            { "value": "601640", "label": "布拖县" },
            { "value": "600579", "label": "会理县" },
            { "value": "600938", "label": "会东县" },
            { "value": "603201", "label": "甘洛县" },
            { "value": "602865", "label": "越西县" },
            { "value": "601444", "label": "普格县" },
            { "value": "601284", "label": "宁南县" },
            { "value": "602076", "label": "昭觉县" },
            { "value": "599660", "label": "西昌市" },
            { "value": "602396", "label": "喜德县" },
            { "value": "599974", "label": "木里藏族自治县" },
            { "value": "601863", "label": "金阳县" },
            { "value": "602594", "label": "冕宁县" },
            { "value": "600127", "label": "盐源县" },
            { "value": "603792", "label": "雷波县" },
            { "value": "603460", "label": "美姑县" }
          ]
        },
        {
          "value": "570733",
          "label": "南充市",
          "children": [
            { "value": "574080", "label": "蓬安县" },
            { "value": "573339", "label": "营山县" },
            { "value": "576427", "label": "阆中市" },
            { "value": "570734", "label": "市辖区" },
            { "value": "570735", "label": "顺庆区" },
            { "value": "574767", "label": "仪陇县" },
            { "value": "571078", "label": "高坪区" },
            { "value": "572146", "label": "南部县" },
            { "value": "571510", "label": "嘉陵区" },
            { "value": "575761", "label": "西充县" }
          ]
        },
        {
          "value": "581870",
          "label": "广安市",
          "children": [
            { "value": "581871", "label": "市辖区" },
            { "value": "584332", "label": "邻水县" },
            { "value": "581872", "label": "广安区" },
            { "value": "583751", "label": "武胜县" },
            { "value": "584900", "label": "华蓥市" },
            { "value": "582526", "label": "前锋区" },
            { "value": "582827", "label": "岳池县" }
          ]
        },
        {
          "value": "578485",
          "label": "宜宾市",
          "children": [
            { "value": "578487", "label": "翠屏区" },
            { "value": "578857", "label": "南溪区" },
            { "value": "580725", "label": "珙县" },
            { "value": "581579", "label": "屏山县" },
            { "value": "580388", "label": "高县" },
            { "value": "578486", "label": "市辖区" },
            { "value": "581025", "label": "筠连县" },
            { "value": "579727", "label": "江安县" },
            { "value": "579114", "label": "宜宾县" },
            { "value": "581304", "label": "兴文县" },
            { "value": "580070", "label": "长宁县" }
          ]
        },
        {
          "value": "551809",
          "label": "攀枝花市",
          "children": [
            { "value": "551891", "label": "西区" },
            { "value": "551941", "label": "仁和区" },
            { "value": "551811", "label": "东区" },
            { "value": "552169", "label": "盐边县" },
            { "value": "552057", "label": "米易县" },
            { "value": "551810", "label": "市辖区" }
          ]
        },
        {
          "value": "560191",
          "label": "广元市",
          "children": [
            { "value": "560982", "label": "旺苍县" },
            { "value": "560469", "label": "昭化区" },
            { "value": "561756", "label": "剑阁县" },
            { "value": "560192", "label": "市辖区" },
            { "value": "561413", "label": "青川县" },
            { "value": "562393", "label": "苍溪县" },
            { "value": "560735", "label": "朝天区" },
            { "value": "560193", "label": "利州区" }
          ]
        },
        {
          "value": "596525",
          "label": "甘孜藏族自治州",
          "children": [
            { "value": "598363", "label": "白玉县" },
            { "value": "597374", "label": "道孚县" },
            { "value": "599516", "label": "得荣县" },
            { "value": "599272", "label": "乡城县" },
            { "value": "596957", "label": "丹巴县" },
            { "value": "596792", "label": "泸定县" },
            { "value": "598733", "label": "色达县" },
            { "value": "597992", "label": "新龙县" },
            { "value": "597242", "label": "雅江县" },
            { "value": "596526", "label": "康定市" },
            { "value": "598889", "label": "理塘县" },
            { "value": "597557", "label": "炉霍县" },
            { "value": "599377", "label": "稻城县" },
            { "value": "597158", "label": "九龙县" },
            { "value": "598540", "label": "石渠县" },
            { "value": "598162", "label": "德格县" },
            { "value": "597749", "label": "甘孜县" },
            { "value": "599129", "label": "巴塘县" }
          ]
        },
        {
          "value": "565735",
          "label": "内江市",
          "children": [
            { "value": "567769", "label": "隆昌县" },
            { "value": "565956", "label": "东兴区" },
            { "value": "566895", "label": "资中县" },
            { "value": "565737", "label": "市中区" },
            { "value": "566504", "label": "威远县" },
            { "value": "565736", "label": "市辖区" }
          ]
        },
        {
          "value": "588538",
          "label": "雅安市",
          "children": [
            { "value": "588771", "label": "名山区" },
            { "value": "588540", "label": "雨城区" },
            { "value": "589711", "label": "宝兴县" },
            { "value": "588539", "label": "市辖区" },
            { "value": "589001", "label": "荥经县" },
            { "value": "589376", "label": "石棉县" },
            { "value": "589654", "label": "芦山县" },
            { "value": "589494", "label": "天全县" },
            { "value": "589135", "label": "汉源县" }
          ]
        },
        {
          "value": "594864",
          "label": "阿坝藏族羌族自治州",
          "children": [
            { "value": "595579", "label": "九寨沟县" },
            { "value": "596022", "label": "黑水县" },
            { "value": "595404", "label": "松潘县" },
            { "value": "596475", "label": "红原县" },
            { "value": "595230", "label": "茂县" },
            { "value": "595864", "label": "小金县" },
            { "value": "595731", "label": "金川县" },
            { "value": "594988", "label": "汶川县" },
            { "value": "595128", "label": "理县" },
            { "value": "596243", "label": "阿坝县" },
            { "value": "596354", "label": "若尔盖县" },
            { "value": "596167", "label": "壤塘县" },
            { "value": "594865", "label": "马尔康市" }
          ]
        }
      ]
    },
    {
      "value": "367395",
      "label": "河南省",
      "children": [
        {
          "value": "387707",
          "label": "焦作市",
          "children": [
            { "value": "387922", "label": "山阳区" },
            { "value": "389418", "label": "孟州市" },
            { "value": "387708", "label": "市辖区" },
            { "value": "388187", "label": "博爱县" },
            { "value": "389064", "label": "沁阳市" },
            { "value": "387836", "label": "马村区" },
            { "value": "388415", "label": "武陟县" },
            { "value": "387985", "label": "修武县" },
            { "value": "388783", "label": "温县" },
            { "value": "387709", "label": "解放区" },
            { "value": "387776", "label": "中站区" }
          ]
        },
        {
          "value": "398169",
          "label": "南阳市",
          "children": [
            { "value": "402141", "label": "新野县" },
            { "value": "401579", "label": "唐河县" },
            { "value": "402668", "label": "邓州市" },
            { "value": "400474", "label": "内乡县" },
            { "value": "399117", "label": "方城县" },
            { "value": "398747", "label": "南召县" },
            { "value": "400787", "label": "淅川县" },
            { "value": "401305", "label": "社旗县" },
            { "value": "398170", "label": "市辖区" },
            { "value": "398437", "label": "卧龙区" },
            { "value": "398171", "label": "宛城区" },
            { "value": "399702", "label": "西峡县" },
            { "value": "400021", "label": "镇平县" },
            { "value": "402429", "label": "桐柏县" }
          ]
        },
        {
          "value": "408287",
          "label": "信阳市",
          "children": [
            { "value": "410916", "label": "潢川县" },
            { "value": "408289", "label": "浉河区" },
            { "value": "409621", "label": "新县" },
            { "value": "411226", "label": "淮滨县" },
            { "value": "411541", "label": "息县" },
            { "value": "409844", "label": "商城县" },
            { "value": "410235", "label": "固始县" },
            { "value": "408566", "label": "平桥区" },
            { "value": "409241", "label": "光山县" },
            { "value": "408914", "label": "罗山县" },
            { "value": "408288", "label": "市辖区" }
          ]
        },
        {
          "value": "379245",
          "label": "安阳市",
          "children": [
            { "value": "381731", "label": "内黄县" },
            { "value": "379584", "label": "龙安区" },
            { "value": "379246", "label": "市辖区" },
            { "value": "382281", "label": "林州市" },
            { "value": "379400", "label": "北关区" },
            { "value": "380371", "label": "汤阴县" },
            { "value": "379742", "label": "安阳县" },
            { "value": "379247", "label": "文峰区" },
            { "value": "379488", "label": "殷都区" },
            { "value": "380688", "label": "滑县" }
          ]
        },
        {
          "value": "411925",
          "label": "周口市",
          "children": [
            { "value": "413644", "label": "沈丘县" },
            { "value": "411926", "label": "市辖区" },
            { "value": "414239", "label": "郸城县" },
            { "value": "412526", "label": "西华县" },
            { "value": "412097", "label": "扶沟县" },
            { "value": "414779", "label": "淮阳县" },
            { "value": "411927", "label": "川汇区" },
            { "value": "415271", "label": "太康县" },
            { "value": "416651", "label": "项城市" },
            { "value": "413030", "label": "商水县" },
            { "value": "416070", "label": "鹿邑县" }
          ]
        },
        {
          "value": "367396",
          "label": "郑州市",
          "children": [
            { "value": "368635", "label": "巩义市" },
            { "value": "367397", "label": "市辖区" },
            { "value": "368217", "label": "惠济区" },
            { "value": "367741", "label": "管城回族区" },
            { "value": "367877", "label": "金水区" },
            { "value": "369293", "label": "新密市" },
            { "value": "368291", "label": "中牟县" },
            { "value": "369663", "label": "新郑市" },
            { "value": "368975", "label": "荥阳市" },
            { "value": "367563", "label": "二七区" },
            { "value": "369963", "label": "登封市" },
            { "value": "367398", "label": "中原区" },
            { "value": "368151", "label": "上街区" }
          ]
        },
        {
          "value": "420258",
          "label": "直辖县",
          "children": [{ "value": "420259", "label": "济源市" }]
        },
        {
          "value": "392737",
          "label": "许昌市",
          "children": [
            { "value": "393306", "label": "鄢陵县" },
            { "value": "393705", "label": "襄城县" },
            { "value": "392850", "label": "许昌县" },
            { "value": "392738", "label": "市辖区" },
            { "value": "394863", "label": "长葛市" },
            { "value": "394170", "label": "禹州市" },
            { "value": "392739", "label": "魏都区" }
          ]
        },
        {
          "value": "383890",
          "label": "新乡市",
          "children": [
            { "value": "383978", "label": "卫滨区" },
            { "value": "384041", "label": "凤泉区" },
            { "value": "385122", "label": "延津县" },
            { "value": "384622", "label": "原阳县" },
            { "value": "387125", "label": "辉县市" },
            { "value": "383891", "label": "市辖区" },
            { "value": "384093", "label": "牧野区" },
            { "value": "386744", "label": "卫辉市" },
            { "value": "386113", "label": "长垣县" },
            { "value": "383892", "label": "红旗区" },
            { "value": "385485", "label": "封丘县" },
            { "value": "384193", "label": "新乡县" },
            { "value": "384380", "label": "获嘉县" }
          ]
        },
        {
          "value": "403311",
          "label": "商丘市",
          "children": [
            { "value": "403312", "label": "市辖区" },
            { "value": "405530", "label": "柘城县" },
            { "value": "404572", "label": "睢县" },
            { "value": "406070", "label": "虞城县" },
            { "value": "405150", "label": "宁陵县" },
            { "value": "403313", "label": "梁园区" },
            { "value": "403577", "label": "睢阳区" },
            { "value": "403992", "label": "民权县" },
            { "value": "407487", "label": "永城市" },
            { "value": "406717", "label": "夏邑县" }
          ]
        },
        {
          "value": "389719",
          "label": "濮阳市",
          "children": [
            { "value": "389720", "label": "市辖区" },
            { "value": "389721", "label": "华龙区" },
            { "value": "391730", "label": "濮阳县" },
            { "value": "391345", "label": "台前县" },
            { "value": "390411", "label": "南乐县" },
            { "value": "389884", "label": "清丰县" },
            { "value": "390751", "label": "范县" }
          ]
        },
        {
          "value": "396611",
          "label": "三门峡市",
          "children": [
            { "value": "396613", "label": "湖滨区" },
            { "value": "396710", "label": "陕州区" },
            { "value": "397687", "label": "灵宝市" },
            { "value": "397257", "label": "卢氏县" },
            { "value": "396612", "label": "市辖区" },
            { "value": "396996", "label": "渑池县" },
            { "value": "397644", "label": "义马市" }
          ]
        },
        {
          "value": "372971",
          "label": "洛阳市",
          "children": [
            { "value": "375717", "label": "伊川县" },
            { "value": "373554", "label": "孟津县" },
            { "value": "374349", "label": "嵩县" },
            { "value": "373105", "label": "瀍河回族区" },
            { "value": "372972", "label": "市辖区" },
            { "value": "373268", "label": "洛龙区" },
            { "value": "373799", "label": "新安县" },
            { "value": "373143", "label": "涧西区" },
            { "value": "374694", "label": "汝阳县" },
            { "value": "374929", "label": "宜阳县" },
            { "value": "373227", "label": "吉利区" },
            { "value": "376105", "label": "偃师市" },
            { "value": "373030", "label": "西工区" },
            { "value": "375308", "label": "洛宁县" },
            { "value": "374121", "label": "栾川县" },
            { "value": "372973", "label": "老城区" }
          ]
        },
        {
          "value": "370304",
          "label": "开封市",
          "children": [
            { "value": "370305", "label": "市辖区" },
            { "value": "372481", "label": "兰考县" },
            { "value": "371942", "label": "尉氏县" },
            { "value": "370995", "label": "杞县" },
            { "value": "371618", "label": "通许县" },
            { "value": "370306", "label": "龙亭区" },
            { "value": "370450", "label": "鼓楼区" },
            { "value": "370634", "label": "祥符区" },
            { "value": "370557", "label": "金明区" },
            { "value": "370373", "label": "顺河回族区" },
            { "value": "370501", "label": "禹王台区" }
          ]
        },
        {
          "value": "417174",
          "label": "驻马店市",
          "children": [
            { "value": "419654", "label": "遂平县" },
            { "value": "417175", "label": "市辖区" },
            { "value": "419354", "label": "汝南县" },
            { "value": "418973", "label": "泌阳县" },
            { "value": "418199", "label": "平舆县" },
            { "value": "417176", "label": "驿城区" },
            { "value": "419874", "label": "新蔡县" },
            { "value": "418443", "label": "正阳县" },
            { "value": "417405", "label": "西平县" },
            { "value": "417712", "label": "上蔡县" },
            { "value": "418759", "label": "确山县" }
          ]
        },
        {
          "value": "395254",
          "label": "漯河市",
          "children": [
            { "value": "395392", "label": "郾城区" },
            { "value": "395255", "label": "市辖区" },
            { "value": "395256", "label": "源汇区" },
            { "value": "395816", "label": "舞阳县" },
            { "value": "395601", "label": "召陵区" },
            { "value": "396228", "label": "临颍县" }
          ]
        },
        {
          "value": "376365",
          "label": "平顶山市",
          "children": [
            { "value": "378764", "label": "汝州市" },
            { "value": "376366", "label": "市辖区" },
            { "value": "376513", "label": "石龙区" },
            { "value": "376984", "label": "叶县" },
            { "value": "376532", "label": "湛河区" },
            { "value": "376442", "label": "卫东区" },
            { "value": "376643", "label": "宝丰县" },
            { "value": "377555", "label": "鲁山县" },
            { "value": "376367", "label": "新华区" },
            { "value": "378139", "label": "郏县" },
            { "value": "378532", "label": "舞钢市" }
          ]
        },
        {
          "value": "382878",
          "label": "鹤壁市",
          "children": [
            { "value": "382879", "label": "市辖区" },
            { "value": "382880", "label": "鹤山区" },
            { "value": "383228", "label": "浚县" },
            { "value": "383079", "label": "淇滨区" },
            { "value": "382969", "label": "山城区" },
            { "value": "383704", "label": "淇县" }
          ]
        }
      ]
    },
    {
      "value": "714401",
      "label": "台湾",
      "children": [
        {
          "value": "742636",
          "label": "新竹县",
          "children": [
            { "value": "742674", "label": "关西镇" },
            { "value": "743414", "label": "新埔镇" },
            { "value": "743565", "label": "竹东镇" },
            { "value": "743888", "label": "北埔乡" },
            { "value": "743201", "label": "尖石乡" },
            { "value": "743527", "label": "竹北市" },
            { "value": "743319", "label": "新丰乡" },
            { "value": "743725", "label": "宝山乡" },
            { "value": "743246", "label": "芎林乡" },
            { "value": "742797", "label": "横山乡" },
            { "value": "743298", "label": "五峰乡" },
            { "value": "742637", "label": "峨眉乡" },
            { "value": "742852", "label": "湖口乡" }
          ]
        },
        {
          "value": "729928",
          "label": "臺东县",
          "children": [
            { "value": "730384", "label": "海端乡" },
            { "value": "730565", "label": "臺东市" },
            { "value": "729994", "label": "长滨乡" },
            { "value": "730423", "label": "绿岛乡" },
            { "value": "730409", "label": "金峰乡" },
            { "value": "730219", "label": "大武乡" },
            { "value": "730510", "label": "太麻里乡" },
            { "value": "730832", "label": "延平乡" },
            { "value": "730308", "label": "关山镇" },
            { "value": "730268", "label": "东河乡" },
            { "value": "730196", "label": "达仁乡" },
            { "value": "730438", "label": "鹿野乡" },
            { "value": "729929", "label": "卑南乡" },
            { "value": "730416", "label": "兰屿乡" },
            { "value": "730033", "label": "成功镇" },
            { "value": "730107", "label": "池上乡" }
          ]
        },
        {
          "value": "720118",
          "label": "屏东县",
          "children": [
            { "value": "721344", "label": "东港镇" },
            { "value": "720142", "label": "狮子乡" },
            { "value": "720748", "label": "竹田乡" },
            { "value": "721293", "label": "车城乡" },
            { "value": "721638", "label": "高树乡" },
            { "value": "720835", "label": "长治乡" },
            { "value": "720480", "label": "雾臺乡" },
            { "value": "721930", "label": "佳冬乡" },
            { "value": "720975", "label": "潮州镇" },
            { "value": "720415", "label": "万峦乡" },
            { "value": "720119", "label": "三地门乡" },
            { "value": "721805", "label": "恆春镇" },
            { "value": "720553", "label": "新园乡" },
            { "value": "721335", "label": "春日乡" },
            { "value": "720649", "label": "盐埔乡" },
            { "value": "721617", "label": "枋山乡" },
            { "value": "720163", "label": "泰武乡" },
            { "value": "721490", "label": "枋寮乡" },
            { "value": "720186", "label": "万丹乡" },
            { "value": "720502", "label": "新埤乡" }
          ]
        },
        {
          "value": "725488",
          "label": "臺南市",
          "children": [
            { "value": "725938", "label": "楠西区" },
            { "value": "726338", "label": "仁德区" },
            { "value": "727041", "label": "中西区" },
            { "value": "725841", "label": "麻豆区" },
            { "value": "726691", "label": "南区" },
            { "value": "725795", "label": "龙崎区" },
            { "value": "725489", "label": "佳里区" },
            { "value": "726539", "label": "善化区" },
            { "value": "725679", "label": "柳营区" },
            { "value": "727251", "label": "下营区" },
            { "value": "727339", "label": "西港区" },
            { "value": "726300", "label": "七股区" },
            { "value": "725620", "label": "六甲区" },
            { "value": "725588", "label": "将军区" },
            { "value": "727375", "label": "新化区" },
            { "value": "725927", "label": "南化区" },
            { "value": "725973", "label": "北区" },
            { "value": "727529", "label": "新营区" },
            { "value": "727425", "label": "新市区" },
            { "value": "726675", "label": "山上区" }
          ]
        },
        {
          "value": "749930",
          "label": "连江县",
          "children": [
            { "value": "749931", "label": "北竿乡" },
            { "value": "749947", "label": "南竿乡" },
            { "value": "749938", "label": "东引乡" },
            { "value": "749941", "label": "莒光乡" }
          ]
        },
        {
          "value": "749571",
          "label": "金门县",
          "children": [
            { "value": "749647", "label": "金湖镇" },
            { "value": "749572", "label": "金城镇" },
            { "value": "749752", "label": "金宁乡" },
            { "value": "749894", "label": "烈屿乡" },
            { "value": "749810", "label": "金沙镇" },
            { "value": "749928", "label": "乌坵乡" }
          ]
        },
        {
          "value": "745674",
          "label": "高雄市",
          "children": [
            { "value": "747902", "label": "楠梓区" },
            { "value": "745715", "label": "大寮区" },
            { "value": "746906", "label": "鼓山区" },
            { "value": "746083", "label": "大社区" },
            { "value": "747894", "label": "那玛夏区" },
            { "value": "747764", "label": "弥陀区" },
            { "value": "747108", "label": "甲仙区" },
            { "value": "748344", "label": "鸟松区" },
            { "value": "746294", "label": "凤山区" },
            { "value": "747342", "label": "林园区" },
            { "value": "747150", "label": "苓雅区" },
            { "value": "747053", "label": "湖内区" },
            { "value": "747647", "label": "美浓区" },
            { "value": "747481", "label": "六龟区" },
            { "value": "745675", "label": "阿莲区" },
            { "value": "747643", "label": "茂林区" },
            { "value": "746199", "label": "大树区" },
            { "value": "748258", "label": "内门区" },
            { "value": "746624", "label": "冈山区" },
            { "value": "747536", "label": "路竹区" }
          ]
        },
        {
          "value": "734179",
          "label": "宜兰县",
          "children": [
            { "value": "735970", "label": "壮围乡" },
            { "value": "734248", "label": "冬山乡" },
            { "value": "734842", "label": "南澳乡" },
            { "value": "734579", "label": "礁溪乡" },
            { "value": "734246", "label": "钓鱼臺" },
            { "value": "735319", "label": "头城镇" },
            { "value": "735851", "label": "员山乡" },
            { "value": "735104", "label": "苏澳镇" },
            { "value": "734865", "label": "三星乡" },
            { "value": "734180", "label": "大同乡" },
            { "value": "734681", "label": "罗东镇" },
            { "value": "735620", "label": "宜兰市" },
            { "value": "735419", "label": "五结乡" }
          ]
        },
        {
          "value": "737861",
          "label": "苗栗县",
          "children": [
            { "value": "737948", "label": "苑里镇" },
            { "value": "739487", "label": "铜锣乡" },
            { "value": "738050", "label": "造桥乡" },
            { "value": "739250", "label": "南庄乡" },
            { "value": "737862", "label": "头屋乡" },
            { "value": "739642", "label": "头份市" },
            { "value": "739369", "label": "三义乡" },
            { "value": "738454", "label": "卓兰镇" },
            { "value": "739564", "label": "通霄镇" },
            { "value": "738158", "label": "竹南镇" },
            { "value": "739465", "label": "泰安乡" },
            { "value": "738619", "label": "公馆乡" },
            { "value": "738695", "label": "后龙镇" },
            { "value": "738882", "label": "苗栗市" },
            { "value": "737894", "label": "西湖乡" },
            { "value": "739302", "label": "三湾乡" },
            { "value": "738528", "label": "大湖乡" },
            { "value": "739419", "label": "狮潭乡" }
          ]
        },
        {
          "value": "743938",
          "label": "花莲县",
          "children": [
            { "value": "745486", "label": "秀林乡" },
            { "value": "744128", "label": "富里乡" },
            { "value": "745354", "label": "万荣乡" },
            { "value": "744625", "label": "吉安乡" },
            { "value": "743993", "label": "凤林镇" },
            { "value": "745532", "label": "玉里镇" },
            { "value": "744246", "label": "花莲市" },
            { "value": "745196", "label": "寿丰乡" },
            { "value": "743956", "label": "丰滨乡" },
            { "value": "745363", "label": "新城乡" },
            { "value": "743939", "label": "卓溪乡" },
            { "value": "745050", "label": "瑞穗乡" },
            { "value": "744185", "label": "光復乡" }
          ]
        },
        {
          "value": "742126",
          "label": "新竹市",
          "children": [
            { "value": "742127", "label": "东区" },
            { "value": "742309", "label": "北区" }
          ]
        },
        {
          "value": "730843",
          "label": "桃园市",
          "children": [
            { "value": "732469", "label": "龙潭区" },
            { "value": "733179", "label": "桃园区" },
            { "value": "731471", "label": "大园区" },
            { "value": "733144", "label": "平镇区" },
            { "value": "733876", "label": "中坜区" },
            { "value": "733390", "label": "新屋区" },
            { "value": "731212", "label": "大溪区" },
            { "value": "731767", "label": "復兴区" },
            { "value": "730844", "label": "八德区" },
            { "value": "731835", "label": "观音区" },
            { "value": "732800", "label": "芦竹区" },
            { "value": "733537", "label": "杨梅区" },
            { "value": "732079", "label": "龟山区" }
          ]
        },
        {
          "value": "714402",
          "label": "彰化县",
          "children": [
            { "value": "716105", "label": "田尾乡" },
            { "value": "716341", "label": "线西乡" },
            { "value": "715745", "label": "埔盐乡" },
            { "value": "714777", "label": "和美镇" },
            { "value": "715602", "label": "埔心乡" },
            { "value": "714632", "label": "芬园乡" },
            { "value": "715055", "label": "花坛乡" },
            { "value": "715172", "label": "鹿港镇" },
            { "value": "715795", "label": "伸港乡" },
            { "value": "717238", "label": "员林市" },
            { "value": "716202", "label": "田中镇" },
            { "value": "714403", "label": "芳苑乡" },
            { "value": "716421", "label": "溪湖镇" },
            { "value": "715490", "label": "埤头乡" },
            { "value": "714701", "label": "福兴乡" },
            { "value": "715960", "label": "社头乡" },
            { "value": "716874", "label": "溪州乡" },
            { "value": "716750", "label": "秀水乡" },
            { "value": "717107", "label": "永靖乡" },
            { "value": "717447", "label": "竹塘乡" }
          ]
        },
        {
          "value": "736051",
          "label": "南投县",
          "children": [
            { "value": "736622", "label": "南投市" },
            { "value": "737337", "label": "水里乡" },
            { "value": "737533", "label": "鱼池乡" },
            { "value": "737625", "label": "竹山镇" },
            { "value": "736356", "label": "集集镇" },
            { "value": "737496", "label": "信义乡" },
            { "value": "736305", "label": "国姓乡" },
            { "value": "736887", "label": "埔里镇" },
            { "value": "736449", "label": "鹿谷乡" },
            { "value": "736522", "label": "名间乡" },
            { "value": "737591", "label": "中寮乡" },
            { "value": "737266", "label": "仁爱乡" },
            { "value": "736052", "label": "草屯镇" }
          ]
        },
        {
          "value": "727730",
          "label": "臺北市",
          "children": [
            { "value": "728220", "label": "内湖区" },
            { "value": "729277", "label": "中山区" },
            { "value": "729583", "label": "中正区" },
            { "value": "728070", "label": "大安区" },
            { "value": "727731", "label": "北投区" },
            { "value": "729073", "label": "信义区" },
            { "value": "728920", "label": "文山区" },
            { "value": "728340", "label": "士林区" },
            { "value": "727897", "label": "大同区" },
            { "value": "728116", "label": "南港区" },
            { "value": "728550", "label": "松山区" },
            { "value": "728713", "label": "万华区" }
          ]
        },
        {
          "value": "739957",
          "label": "嘉义市",
          "children": [
            { "value": "739958", "label": "东区" },
            { "value": "740140", "label": "西区" }
          ]
        },
        {
          "value": "737856",
          "label": "南海岛",
          "children": [
            { "value": "737859", "label": "南沙群岛" },
            { "value": "737857", "label": "东沙群岛" }
          ]
        },
        {
          "value": "740510",
          "label": "嘉义县",
          "children": [
            { "value": "741451", "label": "水上乡" },
            { "value": "741010", "label": "梅山乡" },
            { "value": "740746", "label": "大埔乡" },
            { "value": "741688", "label": "新港乡" },
            { "value": "741646", "label": "溪口乡" },
            { "value": "740536", "label": "布袋镇" },
            { "value": "741936", "label": "竹崎乡" },
            { "value": "741785", "label": "中埔乡" },
            { "value": "740975", "label": "鹿草乡" },
            { "value": "740792", "label": "东石乡" },
            { "value": "741550", "label": "太保市" },
            { "value": "740943", "label": "六脚乡" },
            { "value": "740625", "label": "大林镇" },
            { "value": "740845", "label": "番路乡" },
            { "value": "741750", "label": "义竹乡" },
            { "value": "741312", "label": "朴子市" },
            { "value": "741137", "label": "民雄乡" },
            { "value": "740511", "label": "阿里山乡" }
          ]
        },
        {
          "value": "722024",
          "label": "臺中市",
          "children": [
            { "value": "724656", "label": "龙井区" },
            { "value": "722923", "label": "大肚区" },
            { "value": "723021", "label": "大甲区" },
            { "value": "725199", "label": "清水区" },
            { "value": "723592", "label": "大雅区" },
            { "value": "724872", "label": "北区" },
            { "value": "722025", "label": "梧栖区" },
            { "value": "722474", "label": "西屯区" },
            { "value": "724504", "label": "后里区" },
            { "value": "723966", "label": "东区" },
            { "value": "723211", "label": "大里区" },
            { "value": "722879", "label": "中区" },
            { "value": "724424", "label": "和平区" },
            { "value": "723756", "label": "大安区" },
            { "value": "722212", "label": "乌日区" },
            { "value": "724148", "label": "丰原区" },
            { "value": "722699", "label": "北屯区" },
            { "value": "723802", "label": "东势区" },
            { "value": "722402", "label": "新社区" },
            { "value": "724797", "label": "南屯区" }
          ]
        },
        {
          "value": "749957",
          "label": "云林县",
          "children": [
            { "value": "751907", "label": "臺西乡" },
            { "value": "751832", "label": "四湖乡" },
            { "value": "752149", "label": "元长乡" },
            { "value": "750363", "label": "斗六市" },
            { "value": "751674", "label": "麦寮乡" },
            { "value": "751071", "label": "古坑乡" },
            { "value": "750218", "label": "大埤乡" },
            { "value": "751493", "label": "林内乡" },
            { "value": "751147", "label": "虎尾镇" },
            { "value": "750170", "label": "莿桐乡" },
            { "value": "750795", "label": "斗南镇" },
            { "value": "749991", "label": "北港镇" },
            { "value": "750291", "label": "东势乡" },
            { "value": "751956", "label": "土库镇" },
            { "value": "749958", "label": "褒忠乡" },
            { "value": "751009", "label": "二崙乡" },
            { "value": "751764", "label": "水林乡" },
            { "value": "751555", "label": "崙背乡" },
            { "value": "751400", "label": "口湖乡" },
            { "value": "752034", "label": "西螺镇" }
          ]
        },
        {
          "value": "717531",
          "label": "新北市",
          "children": [
            { "value": "718786", "label": "三峡区" },
            { "value": "718980", "label": "深坑区" },
            { "value": "719023", "label": "石碇区" },
            { "value": "717532", "label": "八里区" },
            { "value": "718327", "label": "平溪区" },
            { "value": "717645", "label": "板桥区" },
            { "value": "719155", "label": "双溪区" },
            { "value": "719382", "label": "泰山区" },
            { "value": "718036", "label": "林口区" },
            { "value": "718490", "label": "三重区" },
            { "value": "719115", "label": "石门区" },
            { "value": "718195", "label": "芦洲区" },
            { "value": "718375", "label": "瑞芳区" },
            { "value": "718879", "label": "三芝区" },
            { "value": "718266", "label": "坪林区" },
            { "value": "717902", "label": "贡寮区" },
            { "value": "717955", "label": "金山区" },
            { "value": "719243", "label": "树林区" },
            { "value": "719731", "label": "土城区" },
            { "value": "719498", "label": "淡水区" }
          ]
        },
        {
          "value": "719868",
          "label": "澎湖县",
          "children": [
            { "value": "719916", "label": "马公市" },
            { "value": "720102", "label": "西屿乡" },
            { "value": "719869", "label": "白沙乡" },
            { "value": "720090", "label": "望安乡" },
            { "value": "719890", "label": "湖西乡" },
            { "value": "720065", "label": "七美乡" }
          ]
        },
        {
          "value": "748553",
          "label": "基隆市",
          "children": [
            { "value": "748920", "label": "中山区" },
            { "value": "748716", "label": "信义区" },
            { "value": "749226", "label": "中正区" },
            { "value": "748581", "label": "暖暖区" },
            { "value": "748599", "label": "七堵区" },
            { "value": "748554", "label": "安乐区" },
            { "value": "748670", "label": "仁爱区" }
          ]
        }
      ]
    }
  ]


  export default cityArea;