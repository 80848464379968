<template>
  <div class="col-lg-12 col-md-12 r-body">
    <div class="card" ref="fullTab">
      <div class="card-header d-flex justify-content-between">
        <div class="header-title">
          <i class="el-icon-rank" @click="onFullScreen"></i>
          <h4 class="card-title">商品榜前十</h4>
        </div>
        <div class="card-header-toolbar d-flex align-items-center">
          <div class="header-title">
            <div
              class="form-group mb-0"
              style="margin-right: 20px; width: 200px"
            >
              <el-select
                :popper-append-to-body="false"
                v-model="sc_good_province"
                clearable
                placeholder="请选择"
                @change="onGetGoodTop()"
              >
                <el-option
                  v-for="item in getSelectAreaData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="header-title">
            <div
              class="form-group mb-0"
              style="margin-right: 20px; width: 200px"
            >
              <input
                style="margin-left: 20px"
                type="text"
                class="form-control"
                placeholder="请输入销售人员"
                v-model="sc_good_sales"
              />
            </div>
          </div>
          <div class="header-title">
            <div
              class="form-group mb-0"
              style="margin-right: 20px; width: 200px"
            >
              <input
                style="margin-left: 20px"
                type="text"
                class="form-control"
                placeholder="请输入商品"
                v-model="sc_good_name"
              />
            </div>
          </div>
          <div class="header-title">
            <div
              class="form-group mb-0"
              style="margin-right: 20px; width: 200px"
            >
              <input
                style="margin-left: 20px"
                type="text"
                class="form-control"
                placeholder="请输入店铺名"
                v-model="sc_good_store"
              />
            </div>
          </div>
          <el-date-picker
            style="margin-left: 20px"
            v-model="sc_good_time"
            type="date"
            align="right"
            :append-to-body="false"
            placeholder="选择发起日期"
            value-format="timestamp"
            @change="onGetGoodTop()"
          ></el-date-picker>
        </div>
        <div
          class="modal-product-search d-flex justify-content-between align-items-center"
        >
          <div
            class="form-group mb-0 vanila-daterangepicker d-flex flex-row"
          ></div>
          <button
            type="button"
            class="btn btn-outline-primary rounded-pill ml-4"
            style="line-height: 1rem"
            @click="
              onGoodReset();
              onGetGoodTop();
            "
          >
            重置
          </button>
          <button
            type="button"
            class="btn btn-outline-primary rounded-pill ml-4"
            style="line-height: 1rem"
            @click="onGetGoodTop()"
          >
            查询
          </button>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive">
          <table class="table mb-0">
            <thead class="table-color-heading">
              <tr class="text-secondary">
                <th scope="col">排名</th>
                <th scope="col">商品</th>
                <th scope="col">商品名称</th>
                <th
                  scope="col"
                  class="text-center"
                  style="transform: translateY(10px)"
                >
                  补货数量
                  <div class="r-head">
                    <i
                      class="el-icon-caret-top"
                      ref="rV1tp"
                      @click="onSortGoodsCount(1)"
                    ></i>
                    <i
                      class="el-icon-caret-bottom"
                      ref="rV1bt"
                      @click="onSortGoodsCount(2)"
                    ></i>
                  </div>
                </th>
                <th
                  scope="col"
                  class="text-right"
                  style="transform: translateY(10px)"
                >
                  金额
                  <div class="r-head">
                    <i
                      class="el-icon-caret-top"
                      ref="rV2tp"
                      @click="onSortMoneyCount(1)"
                    ></i>
                    <i
                      class="el-icon-caret-bottom"
                      ref="rV2bt"
                      @click="onSortMoneyCount(2)"
                    ></i>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="white-space-no-wrap"
                v-for="(item, i) in goodArr.slice(0, 10)"
                :key="i"
              >
                <td>{{ i + 1 }}</td>
                <td>
                  <!-- <div class="avatar">
                    <img
                      class="avatar avatar-img avatar-30 rounded"
                      src="static/picture/4.jpg"
                      alt="1.jpg"
                    />
                  </div> -->
                </td>
                <td>{{ item.product_name }}</td>
                <td class="text-center">{{ item.num }}</td>
                <td class="text-right">￥{{ item.total_price }}</td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="goodArr.length > 10"
            class="d-flex justify-content-end align-items-center border-top-table p-3"
          >
            <button
              class="btn btn-secondary btn-sm"
              @click="onShowTop10('商品榜')"
            >
              查看所有排名
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx";
import { initTable } from "@/services/utils";
import api from "@/services/api";
import cityArea from "@/services/CityArea.js";
export default {
  name: "Top10GoodsRank",
  data() {
    return {
      selectArea: cityArea,
      sc_door_time: "",
      doorArr: [],

      sc_good_time: "",
      sc_good_province: "",
      sc_good_sales: "",
      sc_good_name: "",
      sc_good_store: "",
      goodArr: [],
      oldGoodArr: [],
      sc_province: "",
      sc_province_time: "",
      provinceArr: [],

      sc_charge_time: "",
      chargeArr: [],

      sc_sell_time: "",
      sellArr: [],

      dialogTableVisible: false,
      dialogType: 1,
      dialogTitle: "",
      excelData: [],
    };
  },
  computed:{
    getSelectAreaData() {
      //省级代理，只保留一级
      return [
        ...[{ value: "-1", label: "全国" }],
        ...this.selectArea.map((s) => {
          let item = { ...s };
          if (item.children) {
            delete item.children;
          }
          return item;
        }),
      ];
    },
  },
  mounted() {
    this.onGetGoodTop();
  },
  methods: {
    async exportTable(){
      if (!this.excelData || this.excelData.length <= 0) {
        this.$notify.error({
          title: "失败",
          message: "暂时没有需要导出的数据",
        });
        return;
      }
      const header = [
        "排名",
        "商品",
        "商品名称",
        "补货数量",
        "金额"
      ];
      // 将表头和数据组合在一起
      const worksheetData = [
        header,
        ...this.excelData.map((item,i) => [
          i+1,
          "",
          item.product_name,
          item.num,
          item.total_price]),
      ];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "商品榜.xlsx");
    },
    onFullScreen(event){
      const element = this.$refs.fullTab;
      initTable(element);
    },
    onGoodReset() {
      this.sc_good_time = "";
      this.sc_good_province = "";
      this.sc_good_sales = "";
      this.sc_good_name = "";
      this.sc_good_store = "";
    },
    onSortGoodsCount(v) {
      this.$refs.rV2bt.classList.remove("blue");
      this.$refs.rV2tp.classList.remove("blue");
      if (v == 1) {
        if (!this.$refs.rV1tp.classList.contains("blue")) {
          this.$refs.rV1tp.classList.add("blue");
          this.$refs.rV1bt.classList.remove("blue");
          //升序
          this.goodArr.sort((a, b) => b.num - a.num);
        } else {
          this.$refs.rV1tp.classList.remove("blue");
          this.goodArr = this.oldGoodArr.slice();
        }
      } else {
        if (!this.$refs.rV1bt.classList.contains("blue")) {
          this.$refs.rV1bt.classList.add("blue");
          this.$refs.rV1tp.classList.remove("blue");
          //升序
          this.goodArr.sort((a, b) => a.num - b.num);
        } else {
          this.$refs.rV1bt.classList.remove("blue");
          this.goodArr = this.oldGoodArr.slice();
        }
      }
    },
    onSortMoneyCount(v) {
      this.$refs.rV1bt.classList.remove("blue");
      this.$refs.rV1tp.classList.remove("blue");
      if (v == 1) {
        if (!this.$refs.rV2tp.classList.contains("blue")) {
          this.$refs.rV2tp.classList.add("blue");
          this.$refs.rV2bt.classList.remove("blue");
          //升序
          this.goodArr.sort((a, b) => b.total_price - a.total_price);
        } else {
          this.$refs.rV2tp.classList.remove("blue");
          this.goodArr = this.oldGoodArr.slice();
        }
      } else {
        if (!this.$refs.rV2bt.classList.contains("blue")) {
          this.$refs.rV2bt.classList.add("blue");
          this.$refs.rV2tp.classList.remove("blue");
          //升序
          this.goodArr.sort((a, b) => a.total_price - b.total_price);
        } else {
          this.$refs.rV2bt.classList.remove("blue");
          this.goodArr = this.oldGoodArr.slice();
        }
      }
    },
    async onGetGoodTop() {
      try {
        var _that = this;
        var str_query = "";
        if (this.sc_good_time) {
          str_query = `?create_time=${this.sc_good_time / 1000}`;
        }
        if (this.sc_good_province && this.sc_good_province != "全国") {
          str_query =
            str_query == ""
              ? `?province=${this.sc_good_province}`
              : str_query + `&province=${this.sc_good_province}`;
        }
        /*if (this.sc_good_province) {
          str_query =
            str_query == ""
              ? `?province=${this.sc_good_province}`
              : str_query + `&province=${this.sc_good_province}`;
        }*/
        if (this.sc_good_sales) {
          str_query =
            str_query == ""
              ? `?sales_name=${this.sc_good_sales}`
              : str_query + `&sales_name=${this.sc_good_sales}`;
        }
        if (this.sc_good_name) {
          str_query =
            str_query == ""
              ? `?query=${this.sc_good_name}`
              : str_query + `&query=${this.sc_good_name}`;
        }
        if (this.sc_good_store) {
          str_query =
            str_query == ""
              ? `?store_name=${this.sc_good_store}`
              : str_query + `&store_name=${this.sc_good_store}`;
        }

        str_query = str_query == "" ? "" : str_query;
        const response = await api.Top10.GoodTop10(str_query);
        if (response.status) {
          _that.goodArr = response.data.result;
          _that.excelData = response.data.result;
          _that.oldGoodArr = _that.goodArr.slice();
        }
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "失败",
          message: "接口调用失败",
        });
      }
    },
  },
};
</script>

<style scoped></style>
