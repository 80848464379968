<template>
  <div class="container">
    <!-- loader Start -->
    <!-- <div id="loading">
      <div id="loading-center"></div>
    </div> -->
    <div class="wrapper">
      <section class="login-content">
        <div class="container h-100">
          <div class="row align-items-center justify-content-center h-100">
            <div class="col-md-5">
              <div class="card p-3">
                <div class="card-body">
                  <div class="auth-logo">
                    <img
                      src="https://static.appoint.icu/Battle/shop/static/picture/logo.jpg"
                      class="img-fluid rounded-normal darkmode-logo"
                      alt="logo"
                      v-if="darkMode"

                    />
                    <img
                      v-else
                     src="https://static.appoint.icu/Battle/shop/static/picture/logo.jpg"
                      class="img-fluid rounded-normal light-logo"
                      alt="logo"
                    />
                  </div>
                  <h3 class="mb-3 font-weight-bold text-center">苗姿商务云</h3>
                  <p class="text-center text-secondary mb-4">
                    请登录您的管理员账户
                  </p>
                  <div class="mb-5">
                    <p class="line-around text-secondary mb-0">
                      <span class="line-around-1">登录</span>
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="text-secondary txt-uname">账户名</label>
                        <input
                          class="form-control"
                          v-model="uname"
                          placeholder="请输入登录账户"
                          autocomplete
                        />
                        <span v-show="showUnameError" class="error-txt">请输入账号</span>
                      </div>
                    </div>
                    <div class="col-lg-12 mt-0">
                      <div class="form-group">
                        <div
                          class="d-flex justify-content-between align-items-center"
                        >
                          <label class="text-secondary">密码</label>
                          <!-- <label><a href="auth-recover-pwd.html">忘记密码？</a></label> -->
                        </div>
                        <input
                          v-model="pwd"
                          class="form-control"
                          type="password"
                          placeholder="请输入密码"
                        />
                        <span v-show="showPwdError" class="error-txt">{{ errMsg }}</span>
                      </div>
                    </div>
                  </div>
                  <button
                    @click="onLogin"
                    type="button"
                    class="btn btn-primary btn-block mt-3"
                  >
                    确认登录
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  
  </div>
</template>


<script>
import api from "@/services/api";
import * as THREE from "three";
export default {
  name: "Login",
  data() {
    return {
      uname: "",
      pwd: "",
      showUnameError: false,
      showPwdError: false,
      errMsg: "账号或密码错误，请仔细检查",
      darkMode:false,
    };
  },
  mounted() {
    if (this.$store.state.isLogin) {
      this.$router.push({ name: "main" });
    }
    this.darkMode = this.$store.state.darkMode;
    if(this.darkMode) document.body.classList.add('dark');
    else document.body.classList.remove('dark');
    var webglelement= document.getElementsByClassName("cloud-class");
    if(webglelement.length==0) window.location.reload(0);
  },
  methods: {
    loadScripts(scripts) {
      scripts.forEach((src) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
      });
    },
    async onGetUser() {
      try {
        var _that = this;
        const response = await api.url_login.currentUser();
        if (response.status && response.data.status != "error") {
          _that.$store.commit("segLogin");
          _that.$store.commit("setUinfo", response.data);
          _that.$router.push({ name: "main" });
         
        } else {
          this.$notify.error({
            title: "失败",
            message: `获取用户信息接口错误:${response.data.message}`,
          });
        }
      } catch (error) {
        console.log(error);
        this.$notify.error({
          title: "失败",
          message: "获取用户信息接口错误",
        });
      }
    },
    async onLogin() {
      if (!this.uname) {
        this.showUnameError = true;
        return;
      }
      if (!this.pwd) {
        this.showUnameError = false;
        this.showPwdError = true;
        this.errMsg = "请输入密码";
        return;
      }
      this.showPwdError = false;
      try {
        var _that = this;
        const response = await api.url_login.login({
          mobile: this.uname,
          password: this.pwd,
        });
        if (response.status) {
          if (response.data.status == "success") {
            _that.onGetUser();
          } else {
            _that.showPwdError = true;
            _that.errMsg = response.data.message;
          }
        }
      } catch (error) {
        _that.showPwdError = true;
        _that.errMsg = "服务器异常！";
      }
    },
  },
};
</script>

<style scoped>
.txt-uname {
  display: flex;
}
.error-txt {
  position: absolute;
  display: block;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  margin-top: 1px;
  color: red;
}
</style>